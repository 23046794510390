import { SET_UTILS } from "../actionTypes";

const utils = (state = null, action) => {
    switch (action.type) {
        case "utils/" + SET_UTILS: {
            const { isLoading, error, success } = action.utils;
            return {
                isLoading,
                error,
                success
            };
        }
        default: {
            return state;
        }
    }
};

export default utils;