import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

class RedirectPage extends Component {
    render() {
        return (
            <Typography variant="h6">Redirecting...</Typography>
        );
    }
}

export default RedirectPage;