import "date-fns";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import NewCustomerForm from "./NewCustomerForm";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import fetchClient from "../services/axios";
import { isCustomerFormFilled } from "../services/customer";
import { format } from "date-fns";
import { validateEmail } from "../services/validator";
import { customerNew } from "../api/customer.js";
import { validateWeb } from "../services/customer";

const styles = (theme) => ({
  root: {
    // width: '90%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

class NewCustomerWrapperFrom extends React.Component {
  state = {
    newCustomer: {
      firmName: "",
      contactName: "",
      contactEmail: "",
      street: "",
      city: "",
      zip: "",
      country: "",
      industry: "",
      web: "",
      tin: "",
      reseller: "",
    },
    countries: [],
    isLoading: false,
    error: false,
  };

  handleSubmit = (e) => {
    if (this.handleValidate()) {
      // reset generated licence
      this.setState({
        isLoading: true,
      });
      let additionalState = {
        createIn: "PARTNER_PORTAL",
        //YYYY-DD-MM HH:MM:SS
        createAt: format(new Date(new Date().toUTCString()), "yyyy-MM-dd kk:mm:ss"),
        createReason: "OTHER",
      };

      // call API
      fetchClient()
        .post(process.env.REACT_APP_API_URL + customerNew, {
          customer: { ...this.state.newCustomer, ...additionalState },
        })
        .then((response) => {
          // assign generated and returned licence
          this.setState({
            isLoading: false,
          });

          // redirect to customer detail
          this.props.history.push("/customer/detail/" + response.data.customerId);
        })
        .catch((error) => {
          console.log("Error response", error.response);
          this.setState({
            isLoading: false,
            error: error.response ? error.response.data.errors.message : error,
          });
        });
    }
  };

  handleChangeNewCustomer = (e) => {
    this.setState({
      newCustomer: {
        ...this.state.newCustomer,
        [e.target.name]: e.target.value,
      },
      error: false,
    });
  };

  handleValidate = () => {
    if (isCustomerFormFilled(this.state.newCustomer) === false) {
      this.setState({
        error:
          this.state.newCustomer?.contactEmail &&
          !validateEmail(this.state.newCustomer.contactEmail)
            ? "Email is in incorrect format"
            : this.state.newCustomer?.web && !validateWeb(this.state.newCustomer.web)
              ? "Website is in incorrect format"
              : "You must set all required customers data.",
      });

      return false;
    }

    this.setState({ error: "" });

    return true;
  };

  render() {
    const { classes } = this.props;
    const { newCustomer, error, isLoading } = this.state;

    return (
      <React.Fragment>
        {error ? (
          <CustomizedSnackbars
            message={JSON.stringify(error)}
            variant="error"
            open={error ? true : false}
          />
        ) : (
          ""
        )}
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.root} style={{ padding: "30px 30px 50px" }}>
              <Typography variant="h4" gutterBottom component="h3">
                Create new customer
              </Typography>
              <NewCustomerForm
                {...this.props}
                newCustomer={newCustomer}
                handleChangeNewCustomer={this.handleChangeNewCustomer}
              />
              <Button
                style={{ marginTop: "30px" }}
                disabled={error || isLoading}
                variant="contained"
                color="primary"
                onClick={(e) => this.handleSubmit(e)}
                className={classes.button}
              >
                {isLoading ? "Loading..." : "Create customer"}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

NewCustomerWrapperFrom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewCustomerWrapperFrom);
