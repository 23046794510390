import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { compose } from "redux";
import { isUserAllowed } from "../../services/acl";
import OrderNew from "../Basket/Order/OrderNew";
import TrialNew from "../Basket/Trial/TrialNew";
import CustomerDetail from "../CustomerDetail";
import Customers from "../Customers";
import CustomersTabs from "../CustomersTabs";
import DealDetailHubspot from "../Deals/DealDetailHubspot";
import EditCustomerWrapperForm from "../EditCustomerWrapperForm";
import EditResellerWrapperForm from "../EditResellerWrapperForm";
import LicenceDetail from "../LicenceDetail";
import Licences from "../Licences";
import NewCustomerWrapperFrom from "../NewCustomerWrapperFrom";
import NewResellerWrapperFrom from "../NewResellerWrapperFrom";
import NfrLicences from "../Nfr";
import NotFound from "../NotFound";
import OrderDetail from "../Orders/OrderDetail";
import Orders from "../Orders/Orders";
import ProductAdoptions from "../ProductAdoption";
import ResellerDetail from "../ResellerDetail";
import Resellers from "../Resellers";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import { styles } from "./styles";
import DealNew from "../Basket/Deal/DealNew";
import DealsHubspot from "../Deals/DealsHubspot";

class Outlet extends React.Component {
  render() {
    const { classes, isDrawerOpened, user } = this.props;

    const RouteAuth = ({ component: Component, resource, ...other }) => (
      <Route
        {...other}
        render={(props) => (isUserAllowed(user, resource) ? <Component {...props} /> : <Redirect to="/" />)}
      />
    );

    return (
      <main
        className={classes.content}
        style={{
          paddingLeft: isDrawerOpened ? "265px" : "89px",
          // width: isDrawerOpened ? "calc(100% - 240px)" : "calc(100% - 64px)",
          width: "100%",
        }}
      >
        <div style={{ width: "100%", minHeight: "60px" }} />

        {user && (
          <>
            {/* These two routes are the tabs. It must be here (outside the switch). */}
            <RouteAuth path="/customers" resource="Customers" component={CustomersTabs} />
            <Switch>
              <Route path="/" exact component={Dashboard} />
              <Route path="/account" component={Settings} />

              <RouteAuth path="/nfr" exact resource="NFRs" component={NfrLicences} />

              <RouteAuth path="/licences" resource="Trials" component={Licences} />
              <RouteAuth path="/licence/detail/:id" resource="Trials" component={LicenceDetail} />

              <RouteAuth path="/licence/create/:customerId?" resource="Trials_create" component={TrialNew} />

              <RouteAuth path="/basket/create" resource="Orders_basket" component={OrderNew} />

              <RouteAuth path="/orders" resource="Orders" component={Orders} />
              <RouteAuth path="/order/detail/:id" resource="Orders" component={OrderDetail} />

              {/* <RouteAuth path="/customers" resource="Customers" component={CustomersTabs} /> */}
              <RouteAuth exact path="/customers" resource="Customers" component={Customers} />
              <RouteAuth path="/customers/productadoption" resource="Customers" component={ProductAdoptions} />

              <RouteAuth path="/customer/create" resource="Customers" component={NewCustomerWrapperFrom} />
              <RouteAuth path="/customer/detail/:id" resource="Customers" component={CustomerDetail} />
              <RouteAuth path="/customer/edit/:id" resource="Customers" component={EditCustomerWrapperForm} />

              <RouteAuth path="/resellers" resource="Resellers" component={Resellers} />

              <RouteAuth path="/reseller/create" resource="Resellers_create" component={NewResellerWrapperFrom} />
              <RouteAuth path="/reseller/edit/:id" resource="Resellers_create" component={EditResellerWrapperForm} />
              <RouteAuth path="/reseller/detail/:id" resource="Resellers_create" component={ResellerDetail} />

              <RouteAuth exact path="/deals-hubspot" resource="HubspotDeals" component={DealsHubspot} />

              <RouteAuth path="/deal-hubspot/detail/:id" resource="HubspotDeals" component={DealDetailHubspot} />

              <RouteAuth path="/deal-hubspot/create" resource="HubspotDeals_create" component={DealNew} />

              <Route path="*" component={NotFound} />
            </Switch>
          </>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default compose(withStyles(styles), connect(mapStateToProps), withRouter)(Outlet);
