import TextField from '@material-ui/core/TextField';
import React from 'react';

const replaceComma = (n) => n.replace(",", ".")

export default function Decimal(props) {
    const { value, onChange, ...other } = props;

    return <TextField
        value={value}
        type="number"
        onChange={(e) => onChange(replaceComma(e.target.value))}
        {...other}
    />
};
