import DateFnsUtils from "@date-io/date-fns";
import { DialogContentText, FormHelperText } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { customerDetail } from "../../api/customer";
import { newDeal } from "../../api/deal";
import { dealDetail, dealStages, dealUseCases } from "../../api/deal";
import { resellersList } from "../../api/partner";
import fetchClient from "../../services/axios";
import { Select } from "../../ui/Input";
import CustomizedSnackbars from "../CustomizedSnackbars";
import StagesInfoPopup from "../Deals/StagesInfoPopup";
import { stages } from "../Deals/utils.js";
import { createOrder } from "../Orders/utils";
import { createTrial } from "../Trials/utils";
import Can from "../../services/acl";
import Box from "@material-ui/core/Box";
import DealClosedStageInfo from "./DealClosedStageInfo.js";

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0),
    padding: 0,
    fontSize: 10,
    height: 20,
  },
  margin: {
    margin: theme.spacing(2),
  },
  blueAvatar: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
});

const statusesCloseDeal = ["Closed Won", "Closed Lost"];

const sources = ["Web/Chat", "Sales manager", "Partner", "Event", "Webinar", "Marketing", "Other"];

class DealDetailHubspot extends React.Component {
  state = {
    dealStageId: null,
    isClosed: false,
    currency: "",
    deal: {},
    licence: {},
    isLoading: false,
    error: false,
    success: false,
    status: false,
    products: 0,
    isLoadingInvoice: false,
    isLoadingEngagements: false,
    isLoadingCert: false,
    dialogStatus: false,
    dialogCloseDeal: false,
    showMoreHistory: false,
    note: "",
    resellers: [],
    dealStages: [],
    useCases: [],
    dealDetails: {
      name: "",
      date_closed: "",
      deal_stage: "",
      deal_stage_id: null,
      status: "",
      source: "",
      sales_owner: "",
      technical_owner: "",
      description: "",
      resellerId: "",
      is_renewal: false,
      intended_use_case: "",
    },
  };

  componentWillReceiveProps = (props) => {
    if (this.props.match.params.id !== props.match.params.id) {
      this.handleLoadDeal(props.match.params.id);
    }
  };

  componentDidMount = () => {
    this.handleLoadDealStages();
    this.handleLoadDeal(this.props.match.params.id);

    fetchClient()
      .get(process.env.REACT_APP_API_URL + dealUseCases)
      .then((response) => {
        this.setState({
          useCases: response.data.useCases,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  handleLoadDealStages = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + dealStages)
      .then((response) => {
        this.setState({
          dealStages: response.data.stages,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleLoadDeal = (id) => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + dealDetail + "/" + id)
      .then((response) => {
        if (response.data.deal.state === "WAITING") {
          this.props.history.push("/deals-hubspot");
        } else {
          const stageId = response.data.deal.deal_stage_id;
          const approvalStatus = response.data.deal.approval_status;
          this.setState({
            currency: response.data.currency,
            deal: response.data.deal,
            dealStageId: stageId,
            isClosed:
              response.data.deal.hs_is_closed === "Closed" ||
              process.env.REACT_APP_DEAL_STAGE_CANCELED_ID === stageId ||
              approvalStatus === "Waiting for confirmation" ||
              approvalStatus === "Refused"
                ? true
                : false,
            dealDetails: {
              ...response.data.deal,
            },
            isLoading: false,
            //status: response.status
          });
        }
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });

    fetchClient()
      .get(process.env.REACT_APP_API_URL + resellersList)
      .then((response) => {
        this.setState({
          resellers: response.data.resellers,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    this.handleLoadEngagements();
  };

  handleDialogStatusOpen = (e) => {
    this.setState({
      dialogStatus: true,
      success: false,
    });
    e.preventDefault();
  };

  handleDialogStatusClose = () => {
    this.setState({
      dialogStatus: false,
    });
  };

  handleDialogEditOpen = () => {
    this.setState({
      dialogEdit: true,
      success: false,
    });
  };

  handleDialogEditClose = () => {
    this.setState({
      dialogEdit: false,
    });
  };

  handleDialogCloseDealOpen = () => {
    this.setState({
      dialogCloseDeal: true,
      success: false,
    });
  };

  handleDialogCloseDealClose = () => {
    this.setState({
      dialogCloseDeal: false,
    });
  };

  handleDialogEditSave = (e) => {
    this.setState({
      isLoading: true,
      success: false,
    });

    fetchClient()
      .put(process.env.REACT_APP_API_URL + newDeal + "/" + this.props.match.params.id, {
        deal: {
          ...this.state.dealDetails,
        },
      })
      .then((response) => {
        this.setState({
          deal: response.data.deal,
          dealDetails: {
            ...response.data.deal,
          },
          isLoading: false,
          success: "Deal has been changed.",
        });

        if (
          process.env.REACT_APP_DEAL_STAGE_CLOSED_WON_ID === this.state.dealDetails.deal_stage_id ||
          process.env.REACT_APP_DEAL_STAGE_CLOSED_LOST_ID === this.state.dealDetails.deal_stage_id
        ) {
          this.setState({ isClosed: true, dealStageId: this.state.dealDetails.deal_stage_id });
        }

        this.handleDialogEditClose();
        this.handleLoadEngagements();
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
    e.preventDefault();
  };

  handleDialogCloseDealSave = (e) => {
    this.handleDialogEditSave(e);
    this.handleDialogCloseDealClose();
    e.preventDefault();
  };

  handleDialogStatusSave = (e) => {
    this.handleDialogEditSave(e);
    this.handleDialogStatusClose();
    e.preventDefault();
  };

  handleChangeDealDetails = (e, name) => {
    this.setState({
      dealDetails: {
        ...this.state.dealDetails,
        [name !== undefined ? name : e.target.name]: name !== undefined ? e : e.target.value,
      },
      error: false,
    });
  };

  showMoreHistory = () => {
    this.setState({
      showMoreHistory: !this.state.showMoreHistory,
      success: false,
    });
  };

  handleLoadEngagements = () => {
    this.setState({
      isLoadingEngagements: true,
    });
    fetchClient()
      .get(
        process.env.REACT_APP_API_URL + newDeal + "/" + this.props.match.params.id + "/engagements"
      )
      .then((response) => {
        this.setState({
          engagements: response.data.engagements,
          isLoadingNote: false,
          isLoadingEngagements: false,
          note: "",
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingNote: false,
          isLoadingEngagements: false,
        });
      });
  };

  handleSaveNote = (e) => {
    this.setState({ isLoadingNote: true });
    if (this.state.note.length < 1) {
      this.setState({
        isLoadingNote: false,
      });
    } else {
      fetchClient()
        .post(
          process.env.REACT_APP_API_URL + newDeal + "/" + this.props.match.params.id + "/note",
          {
            note: this.state.note,
          }
        )
        .then((response) => {
          this.handleLoadDeal(this.state.deal.id);
        })
        .catch((error) => {
          this.setState({ error, isLoadingNote: false });
        });
    }
    e.preventDefault();
  };

  setNote = (e) => {
    this.setState({ note: e.target.value });
  };

  filterEngagementBody = (text) => {
    //const regex = /\s*\n*<span[^>]*>\@?Portal HubSpot<\/span>\s*/i;
    return text
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/@?Portal HubSpot/g, "")
      .trim();
  };

  isNoteOwnedByPortalUser = (text) => {
    return text.indexOf("Portal HubSpot") === -1;
  };

  renderEngagementPreview = (eng) => {
    return this.filterEngagementBody(`
            ${eng.engagement.type}: 
            ${typeof eng.metadata.title !== "undefined" ? eng.metadata.title + ", " : ""}
            ${typeof eng.engagement.bodyPreview !== "undefined" ? eng.engagement.bodyPreview : ""}
        `);
  };

  handleCreateOrder = () => {
    const deal = this.state.deal;
    this.props.setUtils({ isLoading: true, error: false, success: true });
    fetchClient()
      .get(process.env.REACT_APP_API_URL + customerDetail + "/" + deal.customerId)
      .then((response) => {
        const customer = response.data.customer;
        if (customer) {
          deal && createOrder({ customer, _this: this });
        } else {
          this.setState({ notFound: true, isLoadingStats: false });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.response ? error.response.data.errors : error,
          isLoading: false,
        });
      });
  };

  handleCreateTrial = () => {
    const deal = this.state.deal;
    fetchClient()
      .get(process.env.REACT_APP_API_URL + customerDetail + "/" + deal.customerId)
      .then((response) => {
        const customer = response.data.customer;
        if (customer) {
          deal && createTrial({ customer, _this: this });
        } else {
          this.setState({ notFound: true, isLoadingStats: false });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.response ? error.response.data.errors : error,
          isLoading: false,
        });
      });
  };

  render() {
    const { classes } = this.props;
    const {
      error,
      deal,
      isLoading,
      dialogStatus,
      dialogEdit,
      dialogCloseDeal,
      dealDetails,
      success,
      isLoadingNote,
      isLoadingEngagements,
      note,
      resellers,
      currency,
      engagements,
      dealStages,
      useCases,
      isClosed,
      dealStageId,
    } = this.state;

    let notes = [];
    if (engagements && engagements.length > 0) {
      notes = engagements.filter((e) => e.engagement.type === "NOTE");
    }

    return (
      <div>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}
        {success && <CustomizedSnackbars message={success} open={success} variant="success" />}
        <Grid container spacing={3}>
          <Grid item md={4} lg={4} xl={3}>
            <Card className={classes.card} style={{ marginBottom: "20px" }}>
              <CardContent>
                <Typography variant="h5" gutterBottom={true} component="h1">
                  Deal: {deal.name && deal.name}
                </Typography>
                <Typography color="textSecondary">
                  {deal.approval_status
                    ? deal.approval_status.startsWith("Confirmed")
                      ? "Confirmed"
                      : deal.approval_status
                    : null}
                  <br />
                  Deal stage:{" "}
                  {deal.deal_stage ? (
                    <Tooltip title={stages[deal.deal_stage]} style={{ cursor: "pointer" }}>
                      <span>{deal.deal_stage}</span>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                  <br />
                  {deal.is_renewal === false && (
                    <>
                      Reserved until:{" "}
                      {deal.reserved_until ? moment(deal.reserved_until).format("DD.MM.YYYY") : ""}
                      <br />
                    </>
                  )}
                  Expected close date: {moment(deal.date_closed).format("DD.MM.YYYY")}
                </Typography>
                <Typography color="textSecondary" gutterBottom={true}>
                  {isClosed ? null : (
                    <Link to={""} onClick={this.handleDialogStatusOpen} color="primary">
                      Update Deal Stage or Expected Close Date
                    </Link>
                  )}
                </Typography>
              </CardContent>
            </Card>
            <Card className={classes.card} style={{ marginBottom: "20px" }}>
              <CardContent>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6" gutterBottom={true} component="h2">
                    Deal details
                  </Typography>
                  {isClosed ? <DealClosedStageInfo /> : null}
                </Box>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <Typography color="primary" variant="caption">
                      Customer:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom={true}>
                      {deal.customerId ? (
                        <Link to={`/customer/detail/${deal.customerId}`}>
                          {deal.customer ? deal.customer : deal.customer_name}
                        </Link>
                      ) : deal.customer ? (
                        deal.customer
                      ) : (
                        deal.customer_name
                      )}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography color="primary" variant="caption">
                      Created:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom={true}>
                      {moment(deal.date_entered).format("DD.MM.YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography color="primary" variant="caption">
                      Reseller:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom={true}>
                      {deal.resellerDetail ? deal.resellerDetail.company : deal.reseller}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography color="primary" variant="caption">
                      Users:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom={true}>
                      {deal.seats}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <Typography color="primary" variant="caption">
                      End User Price:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom={true}>
                      {deal.end_user_price && parseFloat(deal.end_user_price).toFixed(2)} {currency}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography color="primary" variant="caption">
                      Source:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom={true}>
                      {deal.source}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography color="primary" variant="caption">
                      Use Cases:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom={true}>
                      {deal.intended_use_case}
                    </Typography>
                  </Grid>
                </Grid>

                <br />

                <Grid justify="space-between" container>
                  <Grid item>
                    <Button
                      disabled={isClosed}
                      color="primary"
                      variant="contained"
                      onClick={this.handleDialogCloseDealOpen}
                      style={{ marginBottom: 15, marginRight: 10 }}
                      size="small"
                    >
                      Close deal
                    </Button>{" "}
                    <Button
                      disabled={isClosed}
                      color="default"
                      variant="contained"
                      onClick={this.handleDialogEditOpen}
                      style={{ marginBottom: 15 }}
                      size="small"
                    >
                      Edit
                    </Button>
                    <br />
                    <Can resource="Orders_basket">
                      <Button
                        disabled={
                          dealStageId === process.env.REACT_APP_DEAL_STAGE_CLOSED_LOST_ID ||
                          deal.approval_status === "Refused" ||
                          deal.approval_status === "Waiting for confirmation"
                        }
                        component={Link}
                        color="default"
                        variant="contained"
                        onClick={() => this.handleCreateOrder()}
                        to={`/basket/create`}
                        style={{ marginBottom: 15, marginRight: 10 }}
                        size="small"
                      >
                        Create order
                      </Button>
                    </Can>
                    <Can resource="Trials_create">
                      <Button
                        disabled={isClosed}
                        component={Link}
                        variant="contained"
                        color="default"
                        to={`/licence/create/${deal.customerId ? deal.customerId : ""}`}
                        onClick={() => this.handleCreateTrial()}
                        style={{ marginBottom: 15 }}
                        size="small"
                      >
                        Create trial
                      </Button>
                    </Can>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={8} lg={8} xl={9}>
            <Card className={classes.card} style={{ marginBottom: "20px", marginTop: "0px" }}>
              <CardContent>
                <Typography variant="h6" gutterBottom={true} component="h2">
                  Notes
                </Typography>

                <TextField
                  variant={"outlined"}
                  id="description"
                  label="Add a note here..."
                  name="description"
                  multiline
                  rows="3"
                  fullWidth
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  onChange={this.setNote}
                  value={note}
                />

                <Button
                  type={"submit"}
                  variant="contained"
                  color="default"
                  size={"small"}
                  className={classes.button}
                  onClick={this.handleSaveNote}
                  style={{ marginBottom: "20px" }}
                  disabled={isLoadingNote}
                >
                  {isLoadingNote ? "Saving..." : "Add note"}
                </Button>

                <Divider />

                <ReactPlaceholder
                  type="text"
                  ready={!isLoadingEngagements}
                  rows={10}
                  color="#E0E0E0"
                  showLoadingAnimation={true}
                >
                  {notes && notes.length > 0 ? (
                    <List>
                      {notes.map((n) => (
                        <React.Fragment key={n.engagement.id}>
                          <ListItem>
                            <ListItemAvatar>
                              {(this.isNoteOwnedByPortalUser(n.engagement.bodyPreview) && (
                                <Avatar />
                              )) || <Avatar className={classes.blueAvatar}>S</Avatar>}
                            </ListItemAvatar>
                            <ListItemText
                              primary={React.createElement("div", {
                                dangerouslySetInnerHTML: {
                                  __html: this.filterEngagementBody(n.metadata.body),
                                },
                              })}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="body5"
                                    className={classes.inline}
                                  >
                                    {this.isNoteOwnedByPortalUser(n.engagement.bodyPreview)
                                      ? "You"
                                      : "Safetica"}
                                    {` — ${moment(n.engagement.createdAt).format("DD. MM. YYYY, hh:mm")}`}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </List>
                  ) : (
                    <Typography>...</Typography>
                  )}
                </ReactPlaceholder>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Dialog
          open={dialogStatus}
          onClose={this.handleDialogStatusClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change deal stage or expected close date</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Select
                  id="deal_stage_id"
                  name="deal_stage_id"
                  label="Deal stage"
                  required
                  fullWidth
                  value={dealDetails.deal_stage_id}
                  onChange={(e) => this.handleChangeDealDetails(e)}
                  options={Object.entries(dealStages)
                    .filter(([key, value]) => value !== "9. Canceled")
                    .sort((a, b) => {
                      return a[1].localeCompare(b[1]);
                    })
                    .map(([key, value]) => ({ value: value, key: key }))}
                />
                <FormHelperText style={{ display: "flex", color: "#003f67" }}>
                  Deal stages info <StagesInfoPopup />
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      margin=""
                      label="Expected close date"
                      fullWidth
                      value={dealDetails.date_closed}
                      onChange={(val) => this.handleChangeDealDetails(val, "date_closed")}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogStatusClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDialogStatusSave} color="primary">
              {isLoading && isLoading ? "Saving changes..." : "Save changes"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogCloseDeal}
          onClose={this.handleDialogCloseDealClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Close deal</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            {/*<DialogContentText>You are about to close the deal. Be aware this action can lock further changes in the deal.</DialogContentText>*/}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Select
                  name="deal_stage_id"
                  label="Deal stage"
                  required
                  value={dealDetails.deal_stage_id}
                  onChange={(e) => this.handleChangeDealDetails(e)}
                  options={Object.entries(dealStages)
                    .filter(([key, value]) => value.includes("Closed"))
                    .map(([key, val]) => ({ value: val, key: key }))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="description"
                  label="Description"
                  name="description"
                  multiline
                  rows="5"
                  placeholder="Add some description here..."
                  fullWidth
                  value={dealDetails.description}
                  onChange={(e) => this.handleChangeDealDetails(e)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogCloseDealClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDialogCloseDealSave} color="primary">
              {isLoading && isLoading ? "Saving changes..." : "Save changes"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogEdit}
          onClose={this.handleDialogEditClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change deal</DialogTitle>
          <DialogContent style={{ overflowX: "hidden" }}>
            <DialogContentText>{dealDetails.name}</DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Select
                  required
                  id="source"
                  name="source"
                  label="Source"
                  fullWidth
                  autoComplete="source"
                  value={dealDetails.source}
                  onChange={(e) => this.handleChangeDealDetails(e)}
                  options={sources.map((item) => ({ value: item, key: item }))}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  required
                  id="seats"
                  label="Users"
                  value={dealDetails.seats}
                  onChange={(e) => this.handleChangeDealDetails(e)}
                  name="seats"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  id="end_user_price"
                  label="End User Price"
                  value={dealDetails.end_user_price}
                  onChange={(e) => this.handleChangeDealDetails(e)}
                  name="end_user_price"
                  type="text"
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {resellers && resellers.length > 0 && (
                  <Select
                    id="resellerId"
                    name="resellerId"
                    label="Reseller"
                    fullWidth
                    autoComplete="resellerId"
                    value={dealDetails.resellerId}
                    onChange={(e) => this.handleChangeDealDetails(e)}
                    options={resellers.map((item) => ({
                      value: item.company,
                      key: item.id,
                    }))}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Select
                  id="intended_use_case"
                  name="intended_use_case"
                  fullWidth
                  label="Use Cases"
                  autoComplete="off"
                  value={dealDetails.intended_use_case}
                  displayEmpty
                  onChange={(e) => this.handleChangeDealDetails(e)}
                  options={useCases.map((item) => ({
                    value: item.key,
                    key: item.key,
                  }))}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="description"
                  label="Description"
                  name="description"
                  multiline
                  rows="5"
                  placeholder="Type some details about the deal..."
                  fullWidth
                  value={dealDetails.description}
                  onChange={(e) => this.handleChangeDealDetails(e)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDialogEditSave} color="primary">
              {isLoading && isLoading ? "Saving changes..." : "Save changes"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DealDetailHubspot.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUtils: (utils) => dispatch({ type: "utils/SET_UTILS", utils }),
    setCustomer: (customer) => dispatch({ type: "order/SET_CUSTOMER", customer }),
    setReseller: (reseller) => dispatch({ type: "order/SET_RESELLER", reseller }),
    validateOrderCustomer: (customer) =>
      dispatch({ type: "order/VALIDATE_ORDER_CUSTOMER", customer }),
    setTrialCustomer: (customer) => dispatch({ type: "trial/SET_CUSTOMER_TRIAL", customer }),
    setTrialReseller: (reseller) => dispatch({ type: "trial/SET_RESELLER_TRIAL", reseller }),
    validateTrialCustomer: (customer) =>
      dispatch({ type: "trial/VALIDATE_TRIAL_CUSTOMER", customer }),
    setNewCustomer: (customer) => dispatch({ type: "customer/SET_NEW_CUSTOMER", customer }),
  };
};

const mapStateToProps = (state) => ({
  // user: state.user,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DealDetailHubspot);
