import { Box, Button, Step, StepContent, StepLabel, Stepper } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import React, { Component } from "react";
import { connect } from "react-redux";
import { customerNew } from "../../../api/customer";
import { orderBasket } from "../../../api/order";
import fetchClient from "../../../services/axios";
import { Decimal8 } from "../../../ui/Input";
import CustomizedSnackbars from "../../CustomizedSnackbars";
import OrderNewCustomerForm from "./OrderNewCustomerForm";
import OrderNewProductForm from "./OrderNewProductForm";
import OrderNewSummary from "./OrderNewSummary";

class OrderNew extends Component {
  state = {
    activeStep: 0,
    showCustomerForm: false,
    error: false,
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });

    if (this.props.customer && this.props.customer.contactEmail?.value) {
      this.setState({ showCustomerForm: true });
    } else {
      this.setState({ showCustomerForm: false });
    }
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  render() {
    const { validateBasket, validateOrderCustomer, validateCustomer, customer, order } = this.props;
    const { error } = this.state;

    const steps = [
      {
        label: "Select or create customer and reseller",
        component: <OrderNewCustomerForm {...{ showCustomerForm: this.state.showCustomerForm }} />,
      },
      {
        label: "Product details",
        component: <OrderNewProductForm />,
      },
      {
        label: "Order summary",
        component: <OrderNewSummary />,
      },
    ];

    const handleNext = () => {
      if (this.state.activeStep === 0) {
        if (customer) {
          validateCustomer(customer);
          const error = Object.values(customer).find((customer) => customer.error);
          if (!error) {
            this.setState({ activeStep: this.state.activeStep + 1 });
          }
        } else {
          if (order.isOrderCustomerValid) {
            this.setState({ activeStep: this.state.activeStep + 1 });
          } else {
            validateOrderCustomer(null);
          }
        }
      } else if (this.state.activeStep === 1) {
        validateBasket(order.basket);
        const errorBasket = Object.values(order?.basket).find((item) =>
          Object.values(item).find((item) => item?.error)
        );
        const errorAssociatedDeal = () => {
          if (
            order?.orderDealTreshold &&
            Object.values(order.basket).reduce(
              (acc, item) => (acc + item.count?.value ? parseInt(item.count.value) : 0),
              0
            ) >= order?.orderDealTreshold &&
            !order.associatedDeal
          ) {
            return true;
          } else {
            return false;
          }
        };

        if (!errorBasket && !errorAssociatedDeal()) {
          if (!order.isNoteRequired || !(order.isNoteRequired && !order?.note)) {
            this.setState({ activeStep: this.state.activeStep + 1 });
          }
        }
      } else if (this.state.activeStep === 2) {
        const saveNewCustomer = async () => {
          this.setState({
            isLoading: true,
          });
          let resCustomer = null;
          if (customer) {
            const formatCustomer = Object.keys(customer).reduce((acc, key) => {
              if (customer[key].value) {
                acc[key] = customer[key].value;
              }
              return acc;
            }, {});

            let additionalState = {
              createIn: "PARTNER_PORTAL",
              createAt: format(new Date(new Date().toUTCString()), "yyyy-MM-dd kk:mm:ss"),
              createReason: "ORDER",
            };

            // call api to create a new customer
            resCustomer = await fetchClient()
              .post(process.env.REACT_APP_API_URL + customerNew, {
                customer: { ...formatCustomer, ...additionalState },
              })
              .catch((error) => {
                this.setState({
                  isLoading: false,
                  error: error.response ? error.response.data.errors.message : error,
                });
              });
          }

          if (resCustomer || order.customer?.id) {
            const customerId = resCustomer ? resCustomer?.data?.customerId : order.customer.id;
            const resellerId = resCustomer?.data?.resellerDetail?.id
              ? resCustomer.data.resellerDetail?.id
              : order?.reseller?.id || null;
            const products = Object.values(order.basket).map((item) => {
              return Object.keys(item).reduce((acc, key) => {
                if (item[key].value) {
                  acc[key] = item[key].value;
                }
                return acc;
              }, {});
            });

            // call api to create a new order
            fetchClient()
              .post(process.env.REACT_APP_API_URL + orderBasket, {
                products: products,
                customer: customerId,
                resellerId: resellerId,
                note: order?.note,
                hubspotDealId: order?.associatedDeal,
              })
              .then((response) => {
                if (response.data.error) {
                  this.setState({
                    generatedLicence: {},
                    orderId: false,
                    isLoading: false,
                    error: response.data.error,
                  });
                } else {
                  this.props.history.push("/orders");
                }
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  isLoading: false,
                  error: error === "Internal server error" ? error : "Saving Error",
                });
              });
          }
        };
        saveNewCustomer();
      }
    };

    return (
      <Card
        style={{
          margin: "20px",
        }}
      >
        <CustomizedSnackbars
          open={Boolean(error)}
          message={error ?? "Saving Error"}
          variant="error"
        />
        <CardHeader title="Create new order" />
        <CardContent>
          <Stepper activeStep={this.state.activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel
                  optional={
                    index === 0 ? (
                      <>
                        {order?.customer ? (
                          <Typography variant="body2">{order?.customer?.firm}</Typography>
                        ) : customer?.firmName ? (
                          <Typography variant="body2">{customer.firmName.value}</Typography>
                        ) : null}
                        {order?.reseller ? (
                          <Typography variant="body2">{`Reseller: ${order.reseller.company}`}</Typography>
                        ) : customer?.reseller?.value ? (
                          <Typography variant="body2">{`Reseller: ${customer.reseller.company}`}</Typography>
                        ) : null}
                      </>
                    ) : null
                  }
                >
                  <>{step.label}</>
                </StepLabel>
                <StepContent>
                  {step.component}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        disabled={this.props.utils.isLoading || this.state.isLoading}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1
                          ? this.state.isLoading
                            ? "Saving..."
                            : "Save"
                          : "Continue"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={this.handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    validateBasket: (basket) => dispatch({ type: "order/VALIDATE_BASKET", basket }),
    validateCustomer: (customer) => dispatch({ type: "customer/VALIDATE_CUSTOMER", customer }),
    validateOrderCustomer: (customer) =>
      dispatch({ type: "order/VALIDATE_ORDER_CUSTOMER", customer }),
  };
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  order: state.order,
  customer: state.customer,
  utils: state.utils,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderNew);
