import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatFullDate } from '../../../services/utils';

class TrialNewLicenceInfo extends Component {
    render() {
        const licence = this.props.trial.generatedLicence;;
        return (
            <Card style={{ marginBottom: 20 }}>
                <CardContent>
                    <Typography variant="body2">Licence serial key</Typography>
                    <Typography style={{ fontWeight: "bold" }} gutterBottom>
                        {licence.serial === "isCloud" ?
                            <a href="https://hub.safetica.com">Safetica HUB</a>
                            : licence.serial}
                    </Typography>
                    <Typography variant="body2">Count</Typography>
                    <Typography gutterBottom>{licence.count}</Typography>
                    <Typography variant="body2">Validity</Typography>
                    <Typography>{`${formatFullDate(licence.startDate)} - ${formatFullDate(licence.endDate)}`}</Typography>
                </CardContent>
            </Card>
        );
    }
};


const mapDispatchToProps = (dispatch) => {
    return {}
}

const mapStateToProps = state => ({
    isLoading: state.settings.isLoading,
    trial: state.trial,
    customer: state.customer,
    utils: state.utils,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrialNewLicenceInfo);
