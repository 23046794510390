import axios from "axios";
import { msal } from "../components/App/App";

export const logoutUser = () => {
  [
    "apiToken",
    "userEmail",
    "userId",
    // "maxCount",
    "minOrderCount",
    "maxMonths",
    "trialProducts",
    "orderProducts",
    "maxDiscount",
    "maxProductCount",
    "trialCountLimit",
    "allowedValidities",
    // "allowedAclResources",
    "reseller",
    "companyId",
  ].forEach((i) => localStorage.removeItem(i));

  sessionStorage.clear();
  localStorage.clear();
};

//TODO - check co vůbec potřebuju na klientovi
export const loginUser = (user, idToken) => {
  localStorage.setItem("apiToken", idToken);
  // localStorage.setItem("userEmail", user.email);
  localStorage.setItem("userId", user.id);
  // localStorage.setItem("allowedAclResources", [user.allowed_acl_resources]);
  localStorage.setItem("companyId", [user.company]);
  localStorage.setItem("trialProducts", user.trial_products);
  // localStorage.setItem("maxCount", user.max_count)
  localStorage.setItem("minOrderCount", user.min_order_count);
  localStorage.setItem("maxMonths", user.max_months);
  localStorage.setItem("orderProducts", user.order_products);
  localStorage.setItem("maxDiscount", user.max_discount);
  localStorage.setItem("maxProductCount", user.max_product_count);
  localStorage.setItem("trialCountLimit", user.trial_count_limit);
  localStorage.setItem("allowedValidities", user.allowed_validities);
  localStorage.setItem("reseller", user.reseller);

  // [
  // "maxCount",
  // "minOrderCount",
  // "maxMonths",
  // "trialProducts",
  // "orderProducts",
  // "maxDiscount",
  // "maxProductCount",
  // "trialCountLimit",
  // "allowedValidities",
  // "allowedAclResources",
  // "reseller",
  // "companyId",
  // ].forEach((i) => localStorage.setItem(i, user[i]));
};

// export const getLoggedUserEmail = () => localStorage.getItem("userEmail");
export const getLoggedUserCompanyId = () => parseInt(localStorage.getItem("companyId"));

async function logoutRequest() {
  try {
    const accounts = msal.getAllAccounts();
    await msal.logoutRedirect({
      account: accounts[0],
    });
  } catch (error) {
    console.error("Logout error: ", error);
  }
}

export const handleLogin = async (idToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + "/userinit", config);

    if (response && idToken && response.data.user.email && response.data.user.id) {
      loginUser(response.data.user, idToken);
      return response;
    } else {
      logoutUser();
      return null;
    }
  } catch (e) {
    console.log(e);
    logoutRequest();
    logoutUser();
    return null;
  }
};
