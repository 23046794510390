import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatFullDate } from '../../../services/utils';

class DealNewInfo extends Component {
    render() {
        const { deal } = this.props;
        return (
            <Card style={{ marginBottom: 20 }}>
                <CardContent>
                    <Typography
                        color="textSecondary"
                        gutterBottom={true}
                    >
                        Thank you for creating a new deal.
                        <br />
                        Your Account Manager has been notified and will
                        <br />
                        review and approve the deal as soon as possible.
                        <br />
                    </Typography>
                    <Typography
                        color="textSecondary"
                    >
                        Deal: {deal.basket.name.value}
                        <br />
                        End user price: {deal.basket.end_user_price.value}{" "}
                        {deal.basket.currency}
                        <br />
                        Users: {deal.basket.seats.value}
                        <br />
                        Expected close date:{" "}
                        {formatFullDate(deal.basket.date_closed.value)}
                        <br />
                    </Typography>
                </CardContent>
            </Card>
        );
    }
};


const mapDispatchToProps = (dispatch) => {
    return {}
}

const mapStateToProps = state => ({
    deal: state.deal,
    customer: state.customer,
    utils: state.utils,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DealNewInfo);
