import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUser } from "../../redux/actions/user";
import Can from "../../services/acl";
import fetchClient from "../../services/axios";
import { getLocalValue } from "../../services/storage";
import CustomizedSnackbars from "../CustomizedSnackbars";
import DashboardInfo from "./DashboardInfo";


const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0),
    padding: 0,
    fontSize: 10,
    height: 20,
  },
  cardHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  margin: {
    margin: theme.spacing(2),
  },
});

class Dashboard extends React.Component {
  state = {
    stats: {
      licencesCount: 0,
      ordersCount: 0,
      customersCount: 0,
      trialsCount: 0,
    },
    developmentInfo: "",
    isLoading: true,
    isLoadingDevelopmentInfo: true,
    error: false,
  };

  handleStats = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_STATS)
      .then((response) => {
        this.setState({
          stats: response.data.stats.counts,
          isLoading: false,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  handleDevelopmentInfo = () => {
    fetchClient(getLocalValue("apiToken"))
      .get(
        process.env.REACT_APP_API_URL +
        process.env.REACT_APP_PAGES +
        "?strip=development-info"
      )
      .then((response) => {
        this.setState({
          developmentInfo: response.data.en_content,
          isLoadingDevelopmentInfo: false,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoadingDevelopmentInfo: false });
      });
  };

  componentDidMount = () => {
    this.handleStats();
    this.handleDevelopmentInfo();
  };

  render() {
    const { classes } = this.props;
    const {
      stats,
      isLoading,
      error,
    } = this.state;

    const infoTitles = ["Customers", "Trials", "Orders"]

    const Widget = ({ title, value }) => (
      <Can resource={title}>
        <Paper elevation={3} style={{
          margin: 5, padding: 10, minWidth: 120, display: "inline-block"
        }}>
          <Typography color="textSecondary">
            {title}
          </Typography>
          <ReactPlaceholder
            type="text"
            ready={!isLoading}
            rows={3}
            color="#E0E0E0"
            showLoadingAnimation={true}
          >
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h2" color="primary">
                {value}
              </Typography>
            </Box>
          </ReactPlaceholder>
        </Paper>
      </Can>
    )

    return (
      <div>
        {error && (
          <CustomizedSnackbars
            message={error.message}
            open={error ? true : false}
            variant="error"
          />
        )}
        <Box style={{ marginBottom: 15 }}>
          {infoTitles.map((title, index) => {
            const value = stats[title.toLowerCase() + "Count"]
            return (<Widget key={index} title={title} value={value} />)
          })}
        </Box>

        <Card style={{ maxWidth: 1000 }}>
          <CardHeader
            title={
              <Typography variant="h5" className={classes.title} color="textSecondary">
                News from Safetica
              </Typography>
            }
          />
          <CardContent>
            {/* <div
                dangerouslySetInnerHTML={{ __html: developmentInfo }}
              /> */}
            <DashboardInfo />
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setUser,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
