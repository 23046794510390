import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { Typography } from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
// import classnames from "classnames";
import fetchClient, { postClient } from "../services/axios";
import { getLocalValue, setLocalValue } from "../services/storage";
import { subscription } from "../api/subscription.js";

const columnsDefault = [
  {
    name: "created",
    label: "Created",
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: "resellerName",
    label: "Reseller",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "product",
    label: "Product",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "count",
    label: "Users",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "licence",
    label: "License",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "validFrom",
    label: "Valid from",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "validUntil",
    label: "Valid until",
    options: {
      filter: true,
      sort: true,
    },
  },
];

const columnsForReseller = [
  {
    name: "created",
    label: "Created",
    options: {
      display: false,
    },
  },
  {
    name: "product",
    label: "Product",
  },
  {
    name: "count",
    label: "Users",
  },
  {
    name: "licence",
    label: "License",
  },
  {
    name: "validFrom",
    label: "Valid from",
  },
  {
    name: "validUntil",
    label: "Valid until",
  },
];

const styles = (theme) => ({
  tableContainer: {
    height: 320,
  },
  ExpiredRow: {
    "& td": {
      textDecoration: "line-through",
      opacity: "0.3",
    },
  },
});

class NfrLicences extends React.Component {
  state = {
    licences: [],
    error: false,
    isLoading: true,
    status: false,
  };

  options = {
    filter: true,
    filterType: "dropdown",
    responsive: "stacked",
    print: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
    // setRowProps: (row) => {
    //   return {
    //     className: classnames({
    //       [this.props.classes.ExpiredRow]:
    //         moment(row[4].substr(13), "DD.MM.YYYY") < moment(),
    //     }),
    //     //style: { textDecoration: 'line-through' }
    //   };
    // },
    onChangeRowsPerPage: (rowsPerPage) => {
      this.handleStoreState({
        rowsPerPage,
      });
    },
    onChangePage: (page) => {
      this.handleStoreState({
        page,
      });
    },
    onSearchChange: (searchText) => {
      this.handleStoreState({
        searchText,
      });
    },
    onColumnSortChange: (column, direction) => {
      this.handleStoreState({
        columnSort: {
          column,
          direction,
        },
      });
    },
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        let res = 1;
        if (
          (this.props.resellerId && (colIndex === 0 || colIndex === 4 || colIndex === 5)) ||
          (!this.props.resellerId && (colIndex === 0 || colIndex === 5 || colIndex === 6))
        ) {
          // created, licence until
          if (a.data[colIndex] && b.data[colIndex]) {
            let format = "DD.MM.YYYY";
            if (colIndex === 0) format = "DD.MM.YYYY hh:mm";
            res = moment(a.data[colIndex], format) < moment(b.data[colIndex], format) ? -1 : 1;
          } else if (a.data[colIndex]) {
            res = 1;
          } else {
            res = -1;
          }
          /*} else if (colIndex === 3) {
          // seats, renewed
          res = parseInt(a.data[colIndex]) > parseInt(b.data[colIndex]) ? -1 : 1;*/
        } else {
          // others
          try {
            res = a.data[colIndex].localeCompare(b.data[colIndex]) === -1 ? -1 : 1;
          } catch (e) {
            res = parseInt(a.data[colIndex]) > parseInt(b.data[colIndex]) ? -1 : 1;
          }
        }
        return res * (order === "desc" ? 1 : -1);
      });
    },
  };

  optionsForReseller = {
    elevation: 1,
    pagination: false,
    filterType: "dropdown",
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: true,
    responsive: "stacked",
    selectableRows: "none",
  };

  handleStoredTableOptions = (defaultOptions) => {
    return {
      ...defaultOptions,
      ...JSON.parse(getLocalValue("NfrLicences")),
    };
  };

  handleStoredColsOptions = (columnsDefault) => {
    var options = JSON.parse(getLocalValue("NfrLicences"));
    if (options && options.columnSort) {
      columnsDefault.forEach((i) => {
        if (i.name === options.columnSort.column) {
          if (options.columnSort.direction === "ascending") {
            i.options.sortDirection = "asc";
          }
          if (options.columnSort.direction === "descending") {
            i.options.sortDirection = "desc";
          }
        }
      });
    }
    return columnsDefault;
  };

  handleStoreState = (data) => {
    setLocalValue(
      "NfrLicences",
      JSON.stringify({
        ...JSON.parse(getLocalValue("NfrLicences")),
        ...data,
      })
    );
  };

  componentDidMount = () => {
    postClient(process.env.REACT_APP_API_URL + subscription, {
      where: { type: "nfr" },
    })
      .then((response) => {
        return response.data.map((licence) => {
          let l = {};
          const getLicences = () => licence.licences.map((l) => l.serial).join(", ");
          try {
            l = {
              id: licence.id,
              created: `${moment(licence.created).format("DD.MM.YYYY hh:mm")}`,
              resellerName: licence.customerDetail.firm || " - ",
              product: licence.title || " - ",
              count: licence.count,
              licence: getLicences(),
              validFrom: moment(licence.valid_from).format("DD.MM.YYYY"),
              validUntil: moment(licence.valid_to).format("DD.MM.YYYY"),
            };
          } catch (e) {
            console.log(e);
          }
          return l;
        });
      })
      .then((licences) => {
        this.setState({
          licences,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  render() {
    const { classes, resellerId } = this.props;
    const { error, licences } = this.state;

    return (
      <div>
        {/* <Typography variant="h4" gutterBottom>
          This page is under construction.
        </Typography> */}
        {error && <CustomizedSnackbars message={error.message} variant="error" />}

        <div className={classes.tableContainer}>
          <ReactPlaceholder
            type="text"
            ready={!this.state.isLoading}
            rows={resellerId ? 5 : 20}
            color="#E0E0E0"
            showLoadingAnimation={true}
          >
            <MUIDataTable
              title={
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <StoreIcon />
                  </Grid>
                  <Grid item>
                    <Typography>NFR Licences</Typography>
                  </Grid>
                </Grid>
              }
              data={licences}
              options={resellerId ? this.optionsForReseller : this.handleStoredTableOptions(this.options)}
              columns={resellerId ? columnsForReseller : this.handleStoredColsOptions(columnsDefault)}
            />
          </ReactPlaceholder>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

NfrLicences.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NfrLicences);
