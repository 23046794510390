import React from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Tabs from "./Tabs";
import Table from "./Table";
import { SET_DEALS, SET_UTILS, SET_DEAL_CURRENCY } from "../../redux/actionTypes";
import moment from "moment";
import { deals } from "../../api/deal";
import fetchClient from "../../services/axios";

class DealsHubspot extends React.Component {
    state = {
    };

    componentDidMount() {
        this.props.setUtils({ isLoading: true, error: false, success: false });
        const params = new URLSearchParams(this.props.location.search);
        const section = params.get('f');

        fetchClient()
            .get(
                `${process.env.REACT_APP_API_URL}${deals}${section ? "?f=" + section : ""}`
            )
            .then((response) => {
                let deals = response.data.deals.map((d) => {
                    try {
                        const deal = {
                            ...d,
                            reseller: d.resellerDetail
                                ? d.resellerDetail.company
                                : d.reseller,
                            date_closed: `${moment(d.date_closed).format("DD.MM.YYYY")}`,
                            date_modified: `${moment(d.date_modified).format("DD.MM.YYYY")}`,
                            reserved_until: d.reserved_until
                                ? moment(d.reserved_until).format("DD.MM.YYYY")
                                : "",
                            edit: d.id,
                        };
                        return deal;
                    } catch (e) {
                        console.log(e);
                    }
                });
                deals = deals.sort((a, b) => moment(a.date_closed, "DD.MM.YYYY").toDate() - moment(b.date_closed, "DD.MM.YYYY").toDate());
                this.props.setDeals(deals);
                this.props.setDealCurrency(response.data.currency);
                this.props.setUtils({ isLoading: false, error: false, success: true });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ error, isLoading: false });
                this.props.setUtils({ isLoading: false, error: error, success: false });

            });

    }

    render() {

        return (
            <>
                <Tabs />
                <Table />
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDeals: (deals) => dispatch({ type: "deal/" + SET_DEALS, deals }),
        setUtils: (utils) => dispatch({ type: "utils/" + SET_UTILS, utils }),
        setDealCurrency: (currency) => dispatch({ type: "deal/" + SET_DEAL_CURRENCY, currency }),
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(DealsHubspot);
