
import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import React, { Component } from "react";
import { connect } from 'react-redux';
import { getLocalValue } from "../../../services/storage";
import { getRoundedPrice } from "../../../services/utils";
import { Select } from "../../../ui/Input";

class OrderNewItemFormSummary extends Component {

    componentDidMount() {
        const { itemIdx, setBasket, order } = this.props;
        setBasket({
            ...order.basket, ...{
                [itemIdx]: {
                    ...order.basket[itemIdx],
                    count: {
                        ...order.basket[itemIdx].count, value: order.basket[itemIdx].count?.value || order.orderSettings.minSeats
                    },
                    price: order.basket[itemIdx]?.price || 0
                }
            }
        });
    }

    render() {
        const {
            itemIdx,
            validities,
            possibleProducts,
            order
        } = this.props;

        const basket = order.basket;

        const usedProducts = Object.values(basket).map((item) => item.product.value);

        const filteredList = possibleProducts.filter(
            (item) => !usedProducts.includes(item.id) || item.id === basket[itemIdx]?.product.value
        );

        const handleDetail = (val, prop) => {

            if (prop === "product") {
                this.props.setBasket({
                    ...basket, ...{
                        [itemIdx]: {
                            ...basket[itemIdx],
                            [prop]: { ...basket[itemIdx][prop], value: val, error: false },
                            ["validity"]: { ...basket[itemIdx]["validity"], value: null, error: false },
                            price: 0,
                            currency: null
                        }
                    }
                })
            } else {
                this.props.setBasket({
                    ...basket, ...{
                        [itemIdx]: {
                            ...basket[itemIdx],
                            [prop]: { ...basket[itemIdx][prop], value: val, error: false }
                        }
                    }
                })
            }
        }

        const validity = possibleProducts.find((item) => item.id === basket[itemIdx]?.product?.value)?.allowedValidities || null;

        const filteredValidities = validities ? validities.filter((item) => validity?.includes(parseInt(item.id))) : [];

        return (
            <Paper elevation={3} style={{ marginBottom: 20, padding: 20 }}>
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Select
                                disabled
                                error={basket[itemIdx]?.product?.error}
                                label="Product"
                                required
                                fullWidth
                                options={filteredList}
                                value={basket[itemIdx]?.product?.value || ""}
                                onChange={(e) =>
                                    handleDetail(e.target.value, "product")
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3} style={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                                disabled

                                error={basket[itemIdx]?.count?.error}
                                helperText={basket[itemIdx]?.count?.error}
                                variant={"outlined"}
                                value={basket[itemIdx]?.count?.value}
                                label="Users"
                                type="number"
                                fullWidth
                                style={{ width: 120 }}
                                inputProps={{
                                    min: order?.orderSettings?.minSeats || 0,
                                    max: getLocalValue("maxProductCount") || null,
                                    step: order?.orderSettings?.step || 1,
                                    style: { textAlign: "center" },
                                }}
                                onChange={(e) =>
                                    handleDetail(e.target.value, "count")
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                                <Typography>
                                    End user price
                                </Typography>
                                <Box style={{ display: "flex", alignItems: "end" }}>
                                    <Typography style={{ fontWeight: "bold" }}>
                                        {basket[itemIdx]?.price ?
                                            getRoundedPrice(basket[itemIdx]?.price)
                                            : "-"}
                                    </Typography>
                                    {basket[itemIdx]?.price ?
                                        <Typography style={{ marginLeft: 10, fontSize: 12, marginBottom: 2 }}>
                                            {basket[itemIdx]?.currency}
                                        </Typography> : null}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    disabled
                                    error={basket[itemIdx]?.dateStart?.error}
                                    helperText={basket[itemIdx]?.dateStart?.error}
                                    required
                                    margin=""
                                    label="Start date"
                                    value={basket[itemIdx]?.dateStart?.value}
                                    fullWidth
                                    onChange={(val) =>
                                        handleDetail(val, "dateStart")
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                disabled
                                error={basket[itemIdx]?.validity?.error}
                                required
                                label="Validity"
                                fullWidth
                                autoComplete="validity"
                                value={basket[itemIdx]?.validity?.value || ""}
                                options={filteredValidities}
                                onChange={(e) =>
                                    handleDetail(e.target.value, "validity")
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                disabled
                                error={basket[itemIdx]?.discount?.error}
                                helperText={basket[itemIdx]?.discount?.error}
                                label={`Discount (max ${parseInt(getLocalValue("maxDiscount")) || 0
                                    } %)`}
                                value={basket[itemIdx]?.discount?.value || 0}
                                type="number"
                                fullWidth
                                inputProps={{
                                    min: "0",
                                    max: parseInt(getLocalValue("maxDiscount")) || 0,
                                    step: "1",
                                }}
                                onChange={(e) =>
                                    handleDetail(e.target.value, "discount")
                                }
                            />
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBasket: basket => dispatch({ type: 'order/SET_BASKET', basket }),
        setCurrency: currency => dispatch({ type: 'order/SET_CURRENCY', currency }),
    }
}

const mapStateToProps = state => ({
    isLoading: state.settings.isLoading,
    order: state.order,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderNewItemFormSummary);
