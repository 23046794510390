import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export default function DashboardInfo() {
  return (
    <Box>
      <Typography color="textSecondary" variant="h6">
        Safetica Partner Portal Video Tutorial
      </Typography>
      <Typography variant="body2" gutterBottom>
        Click here to learn all about Safetica's Partner Portal:
        <a
          target="_blank"
          style={{ marginLeft: 5 }}
          href="https://www.youtube.com/watch?v=D9ZbQNuDJYY"
        >
          https://www.youtube.com/watch?v=D9ZbQNuDJYY
        </a>
      </Typography>
      <Typography color="textSecondary" variant="h6" gutterBottom>
        FAQ
      </Typography>
      <Typography variant="body2" gutterBottom>
        Learn the answers to the most pressing questions regarding installation, maintenance,
        features, licenses, releases and future plans for unified Safetica:
        <a
          style={{ marginLeft: 5 }}
          href="https://support.safetica.com/en/knowledge-base/safetica-faq"
          target="_blank"
        >
          https://support.safetica.com/en/knowledge-base/safetica-faq
        </a>
      </Typography>
      <Typography color="textSecondary" variant="h6" gutterBottom>
        Safetica HUB
      </Typography>

      <Typography gutterBottom variant="body2">
        Safetica HUB (
        <a target="_blank" style={{ marginLeft: 5 }} href="https://hub.safetica.com">
          https://hub.safetica.com
        </a>{" "}
        ) is a web application for management of cloud-hosted Safetica customers. Without Safetica
        HUB it is not possible to provide cloud products. To get a Safetica HUB, please contact your
        Safetica account manager or submit this form:{" "}
        <a
          target="_blank"
          style={{ marginLeft: 5 }}
          href="https://share.hsforms.com/1XwBfX2FwQXaOjE6pqvwAEA484s9"
        >
          https://share.hsforms.com/1XwBfX2FwQXaOjE6pqvwAEA484s9
        </a>
      </Typography>
      <Typography color="textSecondary" variant="h6" gutterBottom>
        Safetica installation
      </Typography>
      <Typography gutterBottom variant="body2">
        Safetica installer:{" "}
        <a target="_blank" style={{ marginLeft: 5 }} href="https://go.safetica.com/safeticaone">
          https://go.safetica.com/safeticaone
        </a>
        <br />
        Safetica 10 installer:{" "}
        <a target="_blank" style={{ marginLeft: 5 }} href="https://go.safetica.com/safetica10">
          https://go.safetica.com/safetica10
        </a>{" "}
        <br />
        Safetica maintenance console installer:{" "}
        <a
          target="_blank"
          style={{ marginLeft: 5 }}
          href="https://go.safetica.com/maintenanceconsole"
        >
          https://go.safetica.com/maintenanceconsole
        </a>
        <br />
        Safetica documentation:{" "}
        <a target="_blank" style={{ marginLeft: 5 }} href="https://go.safetica.com/kb/0771664">
          https://go.safetica.com/kb/0771664
        </a>{" "}
        <br />
        Installation manual:{" "}
        <a target="_blank" style={{ marginLeft: 5 }} href="https://go.safetica.com/kb/3537175">
          https://go.safetica.com/kb/3537175
        </a>
      </Typography>
    </Box>
  );
}
