import React from 'react';
import { Select as MuiSelect, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';

export default function Select(props) {
    const { options, required, value = "", helperText, ...other } = props;

    return (
        <FormControl required={required} fullWidth>
            <InputLabel>{`${other.label}`}</InputLabel>
            <MuiSelect
                MenuProps={{ disableScrollLock: true }}
                required={required}
                value={value || ""}
                fullWidth
                {...other}
            >
                {!required && <MenuItem value="">None</MenuItem>}
                {options &&
                    options.map((item) => (
                        <MenuItem key={item.key || item.id} value={item.key || item.id}>
                            {item.value || item.name || item.title || item.label || item.text || item.code || item.company || item || null}
                        </MenuItem>
                    ))}
            </MuiSelect>
            {(helperText || other.error) && <FormHelperText style={{ color: 'red' }}>{helperText || other.error}</FormHelperText>}
        </FormControl>
    )

};
