import { validateWeb } from "../../services/customer";
import { SET_NEW_CUSTOMER, VALIDATE_CUSTOMER, CLEAN_CUSTOMER } from "../actionTypes";

const validate = (obj) =>
  obj
    ? Object.keys(obj).reduce(
        (acc, key) => {
          if (acc[key].value === "" && acc[key].required) {
            acc[key].error = "Required field";
          } else {
            acc[key].error = false;
          }

          if (key.toLocaleLowerCase().includes("email") && acc[key].value !== "") {
            const isValidEmail = /\S+@\S+\.\S+/.test(acc[key].value);
            if (!isValidEmail) {
              acc[key].error = "Invalid e-mail address";
            }
          }
          if (key.toLocaleLowerCase().includes("web") && acc[key].value !== "") {
            if (!validateWeb(acc[key].value)) {
              acc[key].error = "Incorrect web address format";
            }
          }

          return acc;
        },
        { ...obj }
      )
    : null;

const customer = (state = null, action) => {
  switch (action.type) {
    case "customer/" + CLEAN_CUSTOMER: {
      return null;
    }
    case "customer/" + SET_NEW_CUSTOMER: {
      if (!action.customer) return null;
      return {
        ...state,
        ...action.customer,
      };
    }
    case "customer/" + VALIDATE_CUSTOMER: {
      return {
        ...validate(action.customer),
      };
    }
    default: {
      return state;
    }
  }
};

export default customer;
