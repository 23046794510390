import { combineReducers } from 'redux';
import settings from './settings';
import user from './user';
import profiles from './profiles';
import order from './order';
import customer from './customer';
import trial from './trial';
import deal from './deal';
import utils from './utils';

export default combineReducers({
    settings,
    user,
    profiles,
    order,
    customer,
    trial,
    deal,
    utils
})