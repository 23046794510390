import { SET_USER } from "../actionTypes";

const user = (state = null, action) => {
    switch (action.type) {
        case "user/" + SET_USER: {
            return action.user;
        }
        default: {
            return state;
        }
    }
};

export default user;
