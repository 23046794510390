import { Box, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { connect } from "react-redux";
import Can from "../../../services/acl";
import fetchClient from "../../../services/axios";
import { Select } from "../../../ui/Input";
import CustomerNew from "../CustomerNew";
import { deals } from "../../../api/deal";
import { customersList } from "../../../api/customer";
import { resellersList } from "../../../api/partner";
import { customerDetail } from "../../../api/customer";

class OrderNewCustomerForm extends Component {
  state = {
    isLoading: false,
    error: false,
    showCustomerForm: this.props.showCustomerForm,
    // showCustomerForm: false,
    resellers: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.order?.customer?.id !== prevProps.order?.customer?.id) {
      fetchClient()
        .get(process.env.REACT_APP_API_URL + deals + "/?customer=" + this.props.order?.customer?.id)
        .then((response) => {
          const deals = response.data.deals.filter(
            (d) => d.approval_status && d.approval_status.toLowerCase().includes("confirmed")
          );
          this.props.setAssociatedDeals(deals || []);
          this.props.setUtils({ isLoading: false, error: false, success: true });
        })
        .catch((error) => {
          this.setState({ error });
          this.props.setUtils({ isLoading: false, error, success: false });
        });
    }
  }

  componentDidMount = () => {
    console.log("Custidddd ", this.props.order);
    if (this.props.order?.customer?.id) {
      console.log("Custidddd 2222 ", this.props.order?.customer);

      this.props.setUtils({ isLoading: true, error: false, success: true });
      fetchClient()
        .get(process.env.REACT_APP_API_URL + deals + "/?customer=" + this.props.order?.customer?.id)
        .then((response) => {
          const deals = response.data.deals.filter(
            (d) => d.approval_status && d.approval_status.toLowerCase().includes("confirmed")
          );
          this.props.setAssociatedDeals(deals || []);
          this.props.setUtils({ isLoading: false, error: false, success: true });
        })
        .catch((error) => {
          this.setState({ error });
          this.props.setUtils({ isLoading: false, error, success: false });
        });
    }
    if (!this.props.customers) {
      fetchClient()
        .get(process.env.REACT_APP_API_URL + customersList)
        .then((response) => {
          this.setState({
            customers: response.data.customers,
          });
        })
        .catch((error) => {
          this.setState({ error, isLoading: false });
        });
    } else {
      this.setState({ customers: this.props.customers });
    }

    if (!this.props.resellers) {
      fetchClient()
        .get(process.env.REACT_APP_API_URL + resellersList)
        .then((response) => {
          this.setState({
            resellers: response.data.resellers,
          });
        })
        .catch((error) => {
          this.setState({ error, isLoading: false });
        });
    } else {
      this.setState({
        resellers: this.props.resellers,
      });
    }
  };

  render() {
    const { error, customers = [], resellers, showCustomerForm } = this.state;
    const {
      setCustomer,
      setNewCustomer,
      customer,
      setReseller,
      order,
      validateOrderCustomer,
      setAssociatedDeal,
      setAssociatedDeals,
      setUtils,
    } = this.props;

    const handleCustomer = async (e, val) => {
      setCustomer(val);
      validateOrderCustomer(val);
      setNewCustomer(null);
      !val && setReseller({ reseller: null });
      this.setState({ showCustomerForm: false });
      setAssociatedDeal(null);
      val && setUtils({ isLoading: true });

      val?.id &&
        fetchClient()
          .get(process.env.REACT_APP_API_URL + deals + "/?customer=" + val.id)
          .then((response) => {
            const deals = response.data.deals.filter(
              (d) => d.approval_status && d.approval_status.toLowerCase().includes("confirmed")
            );
            setAssociatedDeals(deals || []);
            setUtils({ isLoading: false, error: false, success: true });
          })
          .catch((error) => {
            this.setState({ error });
            setUtils({ isLoading: false, error, success: false });
          });

      val?.id &&
        fetchClient()
          .get(process.env.REACT_APP_API_URL + customerDetail + "/" + val.id)
          .then((response) => {
            const customer = response.data.customer;

            if (customer) {
              setReseller({
                reseller:
                  {
                    id: customer?.resellerDetail.id,
                    company: customer?.resellerDetail.company_name,
                  } || null,
              });
            }
          })
          .catch((error) => {
            this.setState({
              error: error.response ? error.response.data.errors : error,
              isLoading: false,
            });
          });
    };

    return (
      <Box style={{ flexDirection: "culumn", paddingBottom: 30 }}>
        <FormControl fullWidth style={{ paddingBottom: 20 }}>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ maxWidth: "600px" }}
                error={order?.isOrderCustomerValid === false}
                helperText={
                  order?.isOrderCustomerValid === false &&
                  "Please select a customer or create new one."
                }
                label="Select customer"
                placeholder="Choose from an existing customers"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                }}
              />
            )}
            autoSelect={true}
            autoHighlight={true}
            id="customer"
            name="customer"
            value={order?.customer ?? ""}
            options={customers}
            getOptionLabel={(option) => option.firm ?? ""}
            onChange={(e, val) => handleCustomer(e, val)}
          />
        </FormControl>
        <Can resource="Customers_create">
          <Button
            style={{ marginBottom: 20 }}
            variant="contained"
            color="primary"
            onClick={(e) => {
              this.setState({ showCustomerForm: !this.state.showCustomerForm });
              setCustomer(null);
              //remove errors
              const newCustomer = customer
                ? Object.keys(customer).reduce((acc, key) => {
                    acc[key] = { ...customer[key], error: false };
                    return acc;
                  }, {})
                : null;
              setNewCustomer(newCustomer ?? null);

              setReseller({ reseller: null });
              validateOrderCustomer("setNull");
            }}
          >
            Create new customer
          </Button>
          {this.state.showCustomerForm && <CustomerNew />}
          {/* TODO: Check v.props.children */}
          {!showCustomerForm && resellers && resellers.length > 0 && (
            <Select
              disabled={order?.customer ? false : true}
              style={{ maxWidth: "600px" }}
              label="Select reseller"
              value={order?.reseller?.id ?? ""}
              onChange={(e, v) =>
                setReseller({
                  reseller: {
                    id: e.target.value,
                    company:
                      v.props.children && v.props.children !== "None" ? v.props.children : "",
                  },
                })
              }
              options={resellers}
            />
          )}
        </Can>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUtils: (utils) => dispatch({ type: "utils/SET_UTILS", utils }),
    setAssociatedDeals: (deals) => dispatch({ type: "order/SET_ASSOCIATED_DEALS", deals }),
    setAssociatedDeal: (deal) => dispatch({ type: "order/SET_ASSOCIATED_DEAL", deal }),
    setNewCustomer: (customer) => dispatch({ type: "customer/SET_NEW_CUSTOMER", customer }),
    setCustomer: (customer) => dispatch({ type: "order/SET_CUSTOMER", customer }),
    setReseller: (reseller) => dispatch({ type: "order/SET_RESELLER", reseller }),
    validateOrderCustomer: (customer) =>
      dispatch({ type: "order/VALIDATE_ORDER_CUSTOMER", customer }),
  };
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  order: state.order,
  customer: state.customer,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderNewCustomerForm);
