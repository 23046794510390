import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { getRoundedPrice, toDecimal } from "../../services/utils";

export default function OrderCard(props) {
  const { product, users, price, currency, startDate, endDate, discount } = props;
  return (
    <Paper elevation={3} style={{ marginBottom: 20, padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField disabled label="Product" required fullWidth value={product || ""} />
        </Grid>
        <Grid item xs={12} md={3} style={{ display: "flex", justifyContent: "center" }}>
          <TextField
            disabled
            variant={"outlined"}
            value={users}
            label="Users"
            type="number"
            fullWidth
            style={{ width: 120 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
            <Typography>End User Price</Typography>
            <Box style={{ display: "flex", alignItems: "end" }}>
              <Typography style={{ fontWeight: "bold" }}>{getRoundedPrice(price)}</Typography>

              <Typography style={{ marginLeft: 10, fontSize: 12, marginBottom: 2 }}>{currency}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextField disabled required margin="" label="Start Date" value={startDate || ""} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField disabled required label="End Date" fullWidth value={endDate || ""} />
        </Grid>
        {/* <Grid item xs={12} md={4}>
            <TextField disabled label={"Discount"} value={(discount && toDecimal(discount, 8)) || ""} />
          </Grid> */}
      </Grid>
    </Paper>
  );
}
