import "date-fns";
import React from "react";
import PropTypes from "prop-types";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import NewResellerForm from "./NewResellerForm";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import fetchClient from "../services/axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { isResellerFormFilled } from "../services/reseller";
import { validateEmail } from "../services/validator";
import { validateWeb } from "../services/customer";

/*const requiredFields = [
    'name',
    'surname',
    'address',
    'city',
    'country_state',
    'id_country',
    'email',
    'phone',
    'fax',
    'website',
    'ico',
    'dico',
    'zip',
    'company',
    'note'
];*/

class EditResellerDialog extends React.Component {
  state = {
    reseller: {
      name: "",
      surname: "",
      address: "",
      city: "",
      country_state: "",
      id_country: "",
      email: "",
      phone: "",
      fax: "",
      website: "",
      ico: "",
      dico: "",
      zip: "",
      company: "",
      note: "",
      password: "",
    },
    countries: [],
    isLoading: false,
    error: false,
  };

  componentDidMount = () => {
    this.setState({
      reseller: {
        ...this.props.reseller,
      },
      isLoading: false,
    });
  };

  handleSubmit = (e) => {
    if (this.handleValidate()) {
      // reset generated licence
      this.setState({
        isLoading: true,
      });

      // call API
      fetchClient()
        .put(
          process.env.REACT_APP_API_URL +
            process.env.REACT_APP_USER_EDIT_RESELLER +
            "/" +
            this.props.reseller.id,
          {
            reseller: this.state.reseller,
          }
        )
        .then((response) => {
          // assign generated and returned licence
          this.setState({
            isLoading: false,
          });

          // redirect to reseller detail
          this.props.handleLoadReseller(this.props.reseller.id);
          this.props.handleDialogClose();
          this.props.handleEditSuccess("Changes have been saved.");
        })
        .catch((error, response) => {
          this.setState({
            isLoading: false,
            error: error.message,
          });
        });
    }
  };

  handleChangeNewReseller = (e) => {
    this.setState({
      reseller: {
        ...this.state.reseller,
        [e.target.name]: e.target.value,
      },
      error: false,
    });
  };

  handleValidate = () => {
    if (isResellerFormFilled(this.state.reseller) === false) {
      this.setState({
        error:
          this.state.reseller?.contactEmail && !validateEmail(this.state.reseller.contactEmail)
            ? "Email is in incorrect format"
            : this.state.reseller?.web && !validateWeb(this.state.reseller.web)
              ? "Website is in incorrect format"
              : "You must set all required resellers data.",
      });
      return false;
    } else {
      console.log("Ffffff ", this.state.reseller.web);
      console.log("Vaaaaa ", validateWeb(this.state.reseller.web));

      if (this.state.reseller?.web && !validateWeb(this.state.reseller.web)) {
        this.setState({ error: "Website is in incorrect format" });
        return false;
      } else {
        this.setState({ error: "" });
        return true;
      }
    }
  };

  render() {
    const { dialogOpen, handleDialogClose, handleLoadReseller } = this.props;
    const { reseller, error, isLoading } = this.state;

    return (
      <React.Fragment>
        {error ? (
          <CustomizedSnackbars
            message={JSON.stringify(error)}
            variant="error"
            open={error ? true : false}
          />
        ) : (
          ""
        )}
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          scroll="paper"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit reseller</DialogTitle>
          <DialogContent>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <NewResellerForm
                  {...this.props}
                  newReseller={reseller}
                  handleLoadReseller={handleLoadReseller}
                  handleChangeNewReseller={this.handleChangeNewReseller}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button disabled={error} onClick={this.handleSubmit} color="primary">
              {isLoading && isLoading ? "Saving changes..." : "Save changes"}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

EditResellerDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default EditResellerDialog;
