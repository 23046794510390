import {
    InteractionRequiredAuthError
} from '@azure/msal-browser';
import { handleLogin } from "../../services/auth";
import { getLocalValue } from '../../services/storage.js';

// import { TokenCredentialAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/azureTokenCredentials/index.js";
// import { ClientSecretCredential } from "@azure/identity";


let idToken = null;
let accessExpires;
// let graphToken = "";
let interval;
// const SCOPE = "access_as_user";
// const scope = `api://${process.env.REACT_APP_AAD_CLIENT_ID}/${SCOPE}`;

// const msalInstance = new PublicClientApplication(msalConfig);
let msalInstance = null;

const login = async () => {
    // await msalInstance.loginRedirect({
    //     scopes,
    // });
    if (document.location.pathname !== "/login") {
        window.location.href = '/login';
    }
    localStorage.clear()
};

const redirect = async () => {
    msalInstance
        .handleRedirectPromise()
        .then(async (response) => {
            if (response === null) {
                login();
            } else {
                setup(response);
            }
        })
        .catch(() => {
            login();
        });
};

// const graphScope = "https://graph.microsoft.com/User.Read.All";

const setup = async (response) => {
    msalInstance.setActiveAccount(response.account);
    idToken = response.idToken;

    // console.log("Access resppp ", JSON.stringify(response, null, 2));

    // const graphqlResponse = await msalInstance.acquireTokenSilent({
    //     account: response.account,
    //     forceRefresh: true,
    //     scopes: [graphScope],
    // });
    // graphToken = graphqlResponse.accessToken;

    if (idToken) {
        await handleLogin(idToken);
        if (window.location.pathname === "/login") {
            window.location.href = '/';
        }
    } else {
        window.location.href = '/login';
    }

    if (window.location.pathname === "/oauth-redirect") {
        window.location.href = '/';
    }

    const MS_PER_HOUR = 3600000;
    accessExpires = response.expiresOn ?? new Date(Date.now() + MS_PER_HOUR / 30);
    interval = setInterval(() => refresh(response.account), 1000);
}

const refresh = async (account) => {
    if (new Date() > accessExpires) {
        clearInterval(interval);
        load(account);
    }
};

const load = async (account) => {
    const request = {
        account,
        forceRefresh: true,
        // scopes: [scope],
    };
    msalInstance
        .acquireTokenSilent(request)
        .then(setup)
        .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance
                    .acquireTokenPopup(request)
                    .then(setup)
                    .catch(() => {
                        redirect();
                    });
            } else {
                redirect();
            }
        });
};

//NEMAZAT!!! msal 2
// msalInstance.addEventCallback(async (event) => {
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//         const account = event.payload.account;
//         const idToken = event.payload.idToken

//         msalInstance.setActiveAccount(account);
//         await handleLogin(account.username, encrypt(account), true, idToken);

//         if (window.location.pathname === "/oauth-redirect") {
//             console.log("Itemmm ", localStorage.getItem("apiToken"));
//             window.location.href = '/';
//         }
//     }
// });

export const initAuth = async (msal) => {
    // await msalInstance.initialize(); //msal 2 - Nesmí být !!!
    msalInstance = msal;
    const accounts = msalInstance.getAllAccounts();
    const apiToken = getLocalValue("apiToken")
    if (accounts.length === 1 && apiToken) {
        load(accounts[0]);
    } else {
        redirect();
    }
};
