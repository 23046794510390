import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { resellerDetail } from "../api/partner.js";
import Can from "../services/acl";
import fetchClient from "../services/axios";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import EditResellerDialog from "./EditResellerDialog";
import ResellerDetailSidePanel from "./ResellerDetailSidePanel";

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0),
    padding: 0,
    fontSize: 10,
    height: 20,
  },
  margin: {
    margin: theme.spacing(2),
  },
});

class ResellerDetail extends React.Component {
  state = {
    reseller: {},
    stats: [],
    isLoading: true,
    isLoadingForensics: false,
    isLoadingStats: true,
    error: false,
    status: false,
    tab: 0,
    notFound: false,
    showResellerEdit: false,
    dialogOpen: false,
    success: false,
  };

  componentWillReceiveProps = (props) => {
    if (this.props.match.params.id !== props.match.params.id) {
      this.handleLoadReseller(props.match.params.id);
    }
  };

  componentDidMount = () => {
    this.handleLoadReseller(this.props.match.params.id);
  };

  handleLoadReseller = (id) => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + resellerDetail + "/" + id)
      .then((response) => {
        if (response.data.reseller) {
          this.setState({
            reseller: response.data.reseller,
            isLoading: false,
            status: response.status,
          });
        } else {
          this.setState({ notFound: true, isLoadingStats: false });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.response ? error.response.data.errors : error,
          isLoading: false,
        });
      });
  };

  handleRemoveLogin = () => {
    fetchClient()
      .delete(
        process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_RESELLER_REMOVE_LOGIN + "/" + this.state.reseller.id
      )
      .then((response) => {
        if (response.data.error) {
          this.setState({
            error: response.data.error,
          });
        } else if (response.data.feedback) {
          this.setState({
            success: response.data.feedback,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.response ? error.response?.data?.errors?.message : error,
        });
      });
  };

  /*handleTabCHange = (e, newValue) => {
        this.setState({ tab: newValue });
    };*/

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true,
      success: false,
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleEditSuccess = (msg) => {
    this.setState({
      success: msg,
    });
  };

  render() {
    const { classes } = this.props;
    //const { id } = this.props.match.params;
    const { error, reseller, notFound, success, dialogOpen } = this.state;

    return (
      <div>
        <CustomizedSnackbars open={!!error} message={error?.message} variant="error" />

        {success && <CustomizedSnackbars message={success} open={success} variant="success" />}
        <Grid container spacing={3}>
          {notFound ? (
            <Grid item md={4} lg={4} xl={3}>
              <Card className={classes.card} style={{ marginBottom: "20px" }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom={true} component="h1">
                    Reseller not found
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item md={4} lg={4} xl={3}>
                <Card className={classes.card} style={{ marginBottom: "20px" }}>
                  <CardContent>
                    <Typography variant="h5" gutterBottom={true} component="h1">
                      {reseller.company_name}
                    </Typography>
                    {/*<Chip size="small"
                                          label={reseller.state}
                                          className={classes.chip}
                                          color={"secondary"}
                                          square
                                    />*/}

                    <Typography color="primary" variant="caption">
                      Contact:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {reseller.name} {reseller.surname}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      Address:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {reseller.address}
                      {reseller.city && `, ${reseller.city}`}
                      {reseller.zip && `, ${reseller.zip}`}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      Firm ID:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {reseller.identification_number}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      VAT ID:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {reseller.tax_identification_number}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      GUID:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {reseller.guid}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      Contacts:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {reseller.email}
                      <br />
                      {reseller.phone}
                      <br />
                      <a href={`//${reseller.website}`} target="_blank" rel="noopener noreferrer">
                        {reseller.website}
                      </a>
                    </Typography>
                    <Typography color="primary" variant="caption">
                      Note:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {reseller?.note && !reseller?.note.includes("<HS>") ? reseller.note : ""}
                    </Typography>
                    <br />
                    <br />
                    <Grid justify="space-between" container>
                      <Grid item>
                        <Can resource="Resellers_login">
                          <Button color="secondary" variant="contained" onClick={this.handleRemoveLogin} size="small">
                            Remove access
                          </Button>
                        </Can>
                      </Grid>
                      <Grid item>
                        <Button color="primary" variant="contained" onClick={this.handleDialogOpen} size="small">
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item md={8} lg={8} xl={9}>
                {reseller && reseller.id && <ResellerDetailSidePanel reseller={reseller} resellerId={reseller.id} />}
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        {reseller && reseller.id && (
          <EditResellerDialog
            reseller={reseller}
            handleLoadReseller={(id) => this.handleLoadReseller(id)}
            handleDialogClose={this.handleDialogClose}
            handleEditSuccess={this.handleEditSuccess}
            dialogOpen={dialogOpen}
          />
        )}
      </div>
    );
  }
}

ResellerDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResellerDetail);
