const requiredResellerAttribs = ["company", "name", "email", "city", "id_country", "zip", "address"];

export const isResellerFormFilled = (reseller) => {
  for (let key in reseller) {
    if (
      requiredResellerAttribs.indexOf(key) !== -1 &&
      (reseller[key] === null || reseller[key] === "" || reseller[key] === false)
    ) {
      return false;
    }
  }
  return true;
};
