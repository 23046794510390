import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloudIcon from '@material-ui/icons/Cloud';
import SupportIcon from '@material-ui/icons/ContactSupport';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DomainIcon from '@material-ui/icons/Domain';
import EmailIcon from '@material-ui/icons/Email';
import LibraryIcon from '@material-ui/icons/LibraryBooks';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import YouTubeIcon from '@material-ui/icons/Subscriptions';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React from 'react';
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Can from "../../services/acl";

const items = [{
    to: '/',
    icon: <DashboardIcon />,
    text: 'Dashboard',
    allowed: true //no check
}, {
    to: '/customers',
    icon: <PeopleIcon />,
    text: 'Customers',
    allowed: "Customers"
}, {
    to: '/resellers',
    icon: <DomainIcon />,
    text: 'Partners',
    allowed: "Resellers"
}, {
    to: '/licences',
    icon: <VpnKeyIcon />,
    text: 'Trial Licences',
    allowed: "Trials"
}, {
    to: '/nfr',
    icon: <StoreIcon />,
    text: 'NFR Licences',
    allowed: "NFRs"
}, {
    to: '/deals',
    icon: <AttachMoneyIcon />,
    text: 'Deals',
    allowed: "Deals"
}, {
    to: '/deals-hubspot',
    icon: <AttachMoneyIcon />,
    text: 'Deal management',
    allowed: "HubspotDeals"
}, {
    to: '/orders',
    icon: <ShoppingCartIcon />,
    text: 'Orders',
    allowed: "Orders"
}]

const Item = ({ to, icon, text, allowed }) => {

    const url = window.location.pathname;

    //Info - customers vs customer, deals-hubspot vs deal-hubspot
    const selected = () => {
        if (url !== '/') {
            return to !== "/" && (url.startsWith(to) ||
                url.startsWith(to.slice(0, -1)) ||
                url.startsWith(to.split("-")[0].replace("s", "") + "-" + to.split("-")[1]))
        } else {
            return url === to
        }
    }

    return (
        <Can resource={allowed}>
            <ListItem
                selected={selected()}
                button component={Link} to={to}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
            </ListItem>
        </Can>
    )
}


export default function SideBarItems() {
    return (
        <List>
            {items.map((item) => <Item key={item.to} {...item} />)}
            <Divider />
            {secondaryListItems}
        </List>
    )
};

export const secondaryListItems = (
    <div>
        <ListSubheader inset>Useful links</ListSubheader>
        <ListItem button
            component="a"
            target="_blank"
            href="https://t.sidekickopen07.com/s3t/c/5/f18dQhb0S7kF8cFnkDW1jrYv559hl3kW7_k2841CXdp3VP1kZg56R7F5W2bzNK07b9_nw101?te=W3R5hFj4cm2zwW3K6j0k49PdTbW3NBCR13M1ZwmW3F4xZk43T4NZf41QVMn04&si=8000000004282414&pi=fb44152b-2fae-46ed-d458-800e763097c4"
        >
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="E-learning" />
        </ListItem>
        <ListItem button
            component="a"
            target="_blank"
            href="https://t.sidekickopen07.com/s3t/c/5/f18dQhb0S7kF8cFnkDW1jrYv559hl3kW7_k2841CXdp3VP1kZg56R7F5W2bzNK07b9_nw101?te=W3R5hFj4cm2zwW4cGmXQ4fJdZLW1Jx5Kt41QVzHW43Xwc845TRfD0&si=8000000004282414&pi=fb44152b-2fae-46ed-d458-800e763097c4"
        >
            <ListItemIcon>
                <CloudIcon />
            </ListItemIcon>
            <ListItemText primary="Brandcloud" />
        </ListItem>
        <ListItem button
            component="a"
            target="_blank"
            href="https://www.safetica.com/support"
        >
            <ListItemIcon>
                <SupportIcon />
            </ListItemIcon>
            <ListItemText primary="Support centre" />
        </ListItem>
        <ListItem button
            component="a"
            target="_blank"
            href=" https://support.safetica.com/en/knowledge-base"
        >
            <ListItemIcon>
                <LibraryIcon />
            </ListItemIcon>
            <ListItemText primary="Technical library" />
        </ListItem>
        <ListItem button
            component="a"
            target="_blank"
            href="https://www.youtube.com/channel/UC6WFXUveK3RTKmnISPh0GDg"
        >
            <ListItemIcon>
                <YouTubeIcon />
            </ListItemIcon>
            <ListItemText primary="Youtube channel" />
        </ListItem>
        <ListItem button
            component="a"
            target="_blank"
            href="https://www.safetica.com/contact"
        >
            <ListItemIcon>
                <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
        </ListItem>
    </div>
);
