import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import Grid from "@material-ui/core/Grid";
import PeopleIcon from "@material-ui/icons/People";
import { Typography } from "@material-ui/core";
import fetchClient from "../services/axios";
import { getLocalValue, setLocalValue } from "../services/storage";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import moment from "moment";

const columnsDefault = [
  {
    name: "customer_name",
    label: "Company",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return <Link to={`/customer/detail/${tableMeta.rowData[14]}`}>{value}</Link>;
      },
    },
  },
  {
    name: "reseller_name",
    label: "Reseller",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "active_licences",
    label: "Purchased seats",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "online_endpoints",
    label: "Active endpoints",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "usage_ratio",
    label: "License usage",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        return value + "%";
      },
    },
  },
  {
    name: "server_version",
    label: "Safetica server version",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "smc_login",
    label: "Safetica logins",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "ws_login",
    label: "WebSafetica logins",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "alerts",
    label: "Alerts",
    options: {
      display: false,
      filter: false,
      sort: true,
    },
  },
  {
    name: "reports",
    label: "Reports",
    options: {
      filter: false,
      display: false,
      sort: true,
    },
  },
  {
    name: "security_audit",
    label: "Security audits",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "dlp_policies",
    label: "DLP policies",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "date_creation",
    label: "Last update",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return moment(value).format("DD.MM.YYYY");
      },
    },
  },
  {
    name: "date_renewal_last",
    label: "Renewal date",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return value ? moment(value).format("DD.MM.YYYY") : null;
      },
    },
  },
  {
    name: "customer_id",
    label: "ID",
    options: {
      filter: false,
      download: false,
      sort: false,
      customBodyRender: (value) => {
        return (
          <IconButton component={Link} size={"small"} variant="contained" to={`/customer/detail/${value}`}>
            <KeyboardArrowRight />
          </IconButton>
        );
      },
      customHeadRender: (columnMeta) => {
        return (
          <th
            key={`th_key_${columnMeta.id}`}
            className="MuiTableCell-root MUIDataTableHeadCell-root-348 MUIDataTableHeadCell-fixedHeader-349 MuiTableCell-head"
            scope="col"
          >
            <div className="MUIDataTableHeadCell-data-352"></div>
          </th>
        );
      },
    },
  },
  {
    name: "customer_guid",
    label: "Customer GUID",
    options: {
      filter: true,
      sort: false,
      display: false,
      download: true,
    },
  },
];

const columnsForCustomer = [
  {
    name: "active_licences",
    label: "Purchased seats",
  },
  {
    name: "online_endpoints",
    label: "Active endpoints",
  },
  {
    name: "usage_ratio",
    label: "License usage",
    options: {
      customBodyRender: (value) => {
        return value + "%";
      },
    },
  },
  {
    name: "server_version",
    label: "Safetica server version",
  },
  {
    name: "smc_login",
    label: "Safetica logins",
  },
  {
    name: "ws_login",
    label: "WebSafetica logins",
  },
  {
    name: "alerts",
    label: "Alerts",
  },
  {
    name: "reports",
    label: "Reports",
  },
  {
    name: "security_audit",
    label: "Security audits",
  },
  {
    name: "dlp_policies",
    label: "DLP policies",
  },
  {
    name: "date_creation",
    label: "Last update",
    options: {
      customBodyRender: (value) => {
        return moment(value).format("DD.MM.YYYY");
      },
    },
  },
];

const styles = (theme) => ({
  // tableContainer: {
  //   height: 320,
  // },
});

class ProductAdoption extends React.Component {
  state = {
    productAdoption: [],
    error: null,
    isLoading: true,
    status: false,
  };

  options = {
    filter: true,
    filterType: "dropdown",
    responsive: "stacked",
    print: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
    onChangeRowsPerPage: (rowsPerPage) => {
      this.handleStoreState({
        rowsPerPage,
      });
    },
    onChangePage: (page) => {
      this.handleStoreState({
        page,
      });
    },
    onSearchChange: (searchText) => {
      this.handleStoreState({
        searchText,
      });
    },
    onColumnSortChange: (column, direction) => {
      this.handleStoreState({
        columnSort: {
          column,
          direction,
        },
      });
    },
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        let res = 1;
        if (colIndex === 12 || colIndex === 13) {
          // dates
          if (a.data[colIndex] && b.data[colIndex]) {
            res = moment(a.data[colIndex]) < moment(b.data[colIndex]) ? -1 : 1;
          } else if (a.data[colIndex]) {
            res = 1;
          } else {
            res = -1;
          }
        } else if (colIndex === 0 || colIndex === 1) {
          res = a.data[colIndex].localeCompare(b.data[colIndex]) === -1 ? -1 : 1;
        } else {
          // others ints
          res = parseInt(a.data[colIndex]) > parseInt(b.data[colIndex]) ? -1 : 1;
        }

        return res * (order === "desc" ? 1 : -1);
      });
    },
  };

  optionsForCustomer = {
    elevation: 1,
    pagination: false,
    filterType: "dropdown",
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: true,
    responsive: "stacked",
    selectableRows: "none",
  };

  handleStoredTableOptions = (defaultOptions) => {
    return {
      ...defaultOptions,
      ...JSON.parse(getLocalValue("ProductAdoption")),
    };
  };

  handleStoredColsOptions = (columnsDefault) => {
    var options = JSON.parse(getLocalValue("ProductAdoption"));
    if (options && options.columnSort) {
      columnsDefault.forEach((i) => {
        if (i.name === options.columnSort.column) {
          if (options.columnSort.direction === "ascending") {
            i.options.sortDirection = "asc";
          }
          if (options.columnSort.direction === "descending") {
            i.options.sortDirection = "desc";
          }
        }
      });
    }
    return columnsDefault;
  };

  handleStoreState = (data) => {
    setLocalValue(
      "ProductAdoption",
      JSON.stringify({
        ...JSON.parse(getLocalValue("ProductAdoption")),
        ...data,
      })
    );
  };

  componentDidMount = () => {
    fetchClient()
      .get(
        process.env.REACT_APP_API_URL +
          process.env.REACT_APP_PRODUCT_ADOPTIONS +
          (this.props.customerId ? "?customerId=" + this.props.customerId : "")
      )
      .then((response) => {
        this.setState({
          productAdoption: response.data.productAdoption
            ? response.data.productAdoption.map((pa) => {
                return {
                  ...pa,
                  alerts: pa.alerts === true ? 1 : 0,
                  reports: pa.reports === true ? 1 : 0,
                  ws_login: pa.ws_logins === true ? 1 : 0,
                  reseller_name: pa.reseller_name ? pa.reseller_name : "",
                };
              })
            : [],
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  render() {
    const { classes, customerId } = this.props;
    const { error, productAdoption } = this.state;

    return (
      <>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}

        <div className={classes.tableContainer}>
          <ReactPlaceholder
            type="text"
            ready={!this.state.isLoading}
            rows={customerId ? 5 : 20}
            color="#E0E0E0"
            showLoadingAnimation={true}
          >
            <MUIDataTable
              title={
                <>
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item>
                      <PeopleIcon />
                    </Grid>
                    <Grid item>
                      <Typography>Product Adoption</Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="caption">
                        Note: Online endpoints, License usage and Login counts are based on data from the last 30 days.
                        Number of security audits is reported from the last 90 days. The values below are purely
                        informative. In some cases, customers can be using offline endpoints or opt out of sending the
                        telemetry.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              }
              data={productAdoption}
              options={customerId ? this.optionsForCustomer : this.handleStoredTableOptions(this.options)}
              columns={customerId ? columnsForCustomer : this.handleStoredColsOptions(columnsDefault)}
            />
          </ReactPlaceholder>

          <br />

          {!customerId && <br />}
        </div>
      </>
    );
  }
}

ProductAdoption.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductAdoption);
