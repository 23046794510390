import { Box, Button, Step, StepContent, StepLabel, Stepper } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import { format } from 'date-fns';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { newTrial } from "../../../api/trial";
import fetchClient, { postClient } from "../../../services/axios";
import CustomizedSnackbars from "../../CustomizedSnackbars";
import TrialNewCustomerForm from "./TrialNewCustomerForm";
import TrialNewLicenceInfo from "./TrialNewLicenceInfo";
import TrialNewProductForm from "./TrialNewProductForm";
import TrialNewSummary from "./TrialNewSummary";
import { Link } from 'react-router-dom';
import { customerNew } from "../../../api/customer";


class TrialNew extends Component {
    state = {
        activeStep: 0,
        showCustomerForm: false,
        error: false,
    };

    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });

        if (this.props.customer && this.props.customer.contactEmail?.value) {
            this.setState({ showCustomerForm: true });
        } else {
            this.setState({ showCustomerForm: false });
        }

    };

    handleReset = () => {
        this.setState({ activeStep: 0 });

    };

    render() {
        const { validateBasket, validateTrialCustomer, validateCustomer, customer, trial } = this.props;
        const { error, isLoading } = this.state;

        const steps = [
            {
                label: 'Select or create customer and reseller',
                component: <TrialNewCustomerForm {...{ showCustomerForm: this.state.showCustomerForm }} />

            },
            {
                label: 'Licence details',
                component: <TrialNewProductForm />
            },
            {
                label: 'Licence summary',
                component: <TrialNewSummary />
            },
            {
                label: 'Licence information',
                component: <TrialNewLicenceInfo />
            },
        ];

        const handleNext = () => {
            if (this.state.activeStep === 0) {
                if (customer) {
                    validateCustomer(customer);
                    const error = Object.values(customer).find(customer => customer.error);
                    if (!error) {
                        this.setState({ activeStep: this.state.activeStep + 1 });
                    }
                } else {
                    if (trial?.isTrialCustomerValid) {
                        this.setState({ activeStep: this.state.activeStep + 1 });
                    } else {
                        validateTrialCustomer(null);
                    }
                }
            } else if (this.state.activeStep === 1) {
                validateBasket(trial.basket);
                const errorBasket = Object.values(trial?.basket).find(item => item.error);
                if (!errorBasket) {
                    this.setState({ activeStep: this.state.activeStep + 1 });
                }
            } else if (this.state.activeStep === 2) {
                this.setState({
                    isLoading: true,
                });
                const saveNewCustomer = async () => {
                    let resCustomer = null;
                    if (customer) {

                        const formatCustomer = Object.keys(customer).reduce((acc, key) => {
                            if (customer[key].value) {
                                acc[key] = customer[key].value;
                            }
                            return acc;
                        }, {});


                        let additionalState = {
                            createIn: "PARTNER_PORTAL",
                            createAt: format(
                                new Date(new Date().toUTCString()),
                                "yyyy-MM-dd kk:mm:ss"
                            ),
                            createReason: "TRIAL",
                        };

                        // call api to create a new customer
                        resCustomer = await fetchClient()
                            .post(
                                process.env.REACT_APP_API_URL +
                                customerNew,
                                {
                                    customer: { ...formatCustomer, ...additionalState },
                                }
                            )
                            .catch((error) => {
                                this.setState({
                                    isLoading: false,
                                    error: error.response
                                        ? error.response.data.errors.message
                                        : error,
                                });
                            });
                    }

                    if (resCustomer || trial.customer?.id) {
                        const trial = this.props.trial;
                        const basket = this.props.trial.basket;
                        const customerId = resCustomer ? resCustomer?.data?.customerId : trial.customer.id;
                        const resellerId = resCustomer?.data?.resellerDetail?.id ? resCustomer.data.resellerDetail?.id : trial?.reseller?.id || null;

                        const licence = {
                            count: basket.count.value,
                            product: basket.product.value,
                            valid_from: format(
                                new Date(basket.validFrom.value),
                                "yyyy-MM-dd"
                            ),
                            validity: basket.validity.value,
                        }

                        postClient(process.env.REACT_APP_API_URL +
                            newTrial,
                            {
                                licence,
                                customer: customerId,
                                resellerId: resellerId
                            }
                        ).then(res => {
                            if (res.error) {
                                this.setState({
                                    generatedLicence: {},
                                    isLoading: false,
                                    error: res.error,
                                });
                            } else if (res.licence) {
                                this.props.setGeneratedTrial({ generatedLicence: res.licence });
                                this.setState({
                                    activeStep: this.state.activeStep + 1,
                                    isLoading: false,
                                });
                            }
                        }).catch(error => {
                            this.setState({
                                error: error.response ? error.response.data?.errors?.message : null,
                                isLoading: false,
                            });
                        });
                    }
                }
                saveNewCustomer();
            }
        };

        return (
            <Card
                style={{
                    margin: "20px",
                }}
            >
                <CustomizedSnackbars open={Boolean(error)} message={this.state.error || "Saving error"} variant="error" />
                <CardHeader title="Create new trial" />
                <CardContent>
                    <Stepper activeStep={this.state.activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={index}>
                                <StepLabel
                                    optional={
                                        index === 0 ? (
                                            <>
                                                {trial?.customer ?
                                                    <Typography variant="body2">{trial?.customer?.firm}</Typography> :
                                                    customer?.firmName ?
                                                        <Typography variant="body2">{customer.firmName.value}</Typography>
                                                        : null}
                                                {trial?.reseller ? <Typography variant="body2">{`Reseller: ${trial.reseller.company}`}</Typography> :
                                                    customer?.reseller?.value ? <Typography variant="body2">{`Reseller: ${customer.reseller.company}`}</Typography> :
                                                        null}
                                            </>
                                        ) : null
                                    }
                                >
                                    <>
                                        {step.label}

                                    </>
                                </StepLabel>
                                <StepContent>
                                    {step.component}
                                    <Box sx={{ mb: 2 }}>
                                        {index === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                component={Link}
                                                to="/licences"
                                            >
                                                Go to licences
                                            </Button>
                                            : <Button
                                                variant="contained"
                                                onClick={handleNext}
                                            >
                                                {index === steps.length - 2 ?
                                                    this.state.isLoading ?
                                                        'Generating licence...'
                                                        : 'Generate licence'
                                                    : 'Continue'}
                                            </Button>}
                                        <Button
                                            disabled={index === 0}
                                            onClick={this.handleBack}
                                        >
                                            Back
                                        </Button>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </CardContent>

            </Card >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        validateBasket: basket => dispatch({ type: 'trial/VALIDATE_TRIAL_BASKET', basket }),
        validateCustomer: customer => dispatch({ type: 'customer/VALIDATE_CUSTOMER', customer }),
        validateTrialCustomer: customer => dispatch({ type: 'trial/VALIDATE_TRIAL_CUSTOMER', customer }),
        setGeneratedTrial: trial => dispatch({ type: 'trial/SET_GENERATED_TRIAL', trial }),
    }
}

const mapStateToProps = state => ({
    isLoading: state.settings.isLoading,
    trial: state.trial,
    customer: state.customer,
    utils: state.utils,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrialNew);
