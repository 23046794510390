import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { connect } from "react-redux";
import { userProfile } from "../../../api/general";
import fetchClient from "../../../services/axios";
import { getLocalValue } from "../../../services/storage";
import { getRoundedPrice } from "../../../services/utils";
import { Select } from "../../../ui/Input";
import CustomizedSnackbars from "../../CustomizedSnackbars";
import OrderNewItemForm from "./OrderNewItemForm";
import OrderNoDealInfo from "./OrderNoDealInfo";

const initItem = {
  product: { value: null, required: true, error: false },
  count: { value: 0, required: true, error: false },
  dateStart: { value: new Date(), required: true, error: false },
  validity: { value: 0, required: true, error: false },
  discount: { value: 0, required: false, error: false },
};

const ProductDetailInfo = {
  dateStart: new Date(),
  validity: "",
  count: 0,
  product: "",
  government: 0,
  discount: 0,
};

class OrderNewProductForm extends Component {
  // state = {
  //     isLoading: false,
  //     error: false,
  // };

  state = {
    isLoading: false,
    error: false,
    products: [],
    validities: [],
    basket: [initItem],
    productDetailsInfo: [ProductDetailInfo],
    existingCustomer: "",
    newCustomer: {
      firmName: "",
      contactName: "",
      contactEmail: "",
      street: "",
      city: "",
      zip: "",
      country: "",
      industry: "",
    },
    possibleProducts: [],
    itemIdx: 0,
  };

  //id zákazníka, jestli existuje, nebo country
  // id reseller jestliže je, nebo id pod kterým jsem přihlášený do portálu

  componentDidMount = () => {
    const maxDiscount = this.props.user?.max_discount;

    if (!this.props?.order?.basket && !this.props?.order?.basket?.[0]?.product) {
      this.props.setBasket({
        [this.state.itemIdx]: {
          ...initItem,
          discount: {
            ...initItem.discount,
            //validation for max discount is turned off
            // max: maxDiscount ? parseInt(maxDiscount) : null
          },
        },
      });
      this.setState({ itemIdx: this.state.itemIdx + 1 });
    }

    fetchClient()
      .post(process.env.REACT_APP_API_URL + "/order-basket/order-product-options", {
        customer: this.props?.order?.customer?.id ?? null,
        country: this.props?.customer?.country.value ?? null,
      })
      .then((response) => {
        this.setState({
          possibleProducts: response.data.products,
          validities: response.data.validities,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    fetchClient()
      .get(process.env.REACT_APP_API_URL + userProfile)
      .then((response) => {
        this.props.setOrderDealTreshold(response.data.user.orderDealThreshold);
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  componentDidUpdate = (prevProps) => {
    const order = this.props.order;
    const sumSeats = order.basket
      ? Object.values(order.basket).reduce(
          (acc, item) => (acc + item.count?.value ? parseInt(item.count.value) : 0),
          0
        )
      : 0;
    if (prevProps.order.basket !== order.basket) {
      this.props.order.basket
        ? this.props.setTotalPrice(
            Object.values(order.basket).reduce((acc, item) => acc + item.price, 0)
          )
        : this.props.setTotalPrice(0);

      if (order?.associatedDeals?.length) {
        this.props.setShowAssociatedDealsSelect(true);
      } else if (!order?.associatedDeals?.length) {
        this.props.setShowAssociatedDealsSelect(false);
      }

      if (
        order?.orderDealTreshold &&
        sumSeats >= order?.orderDealTreshold &&
        !order.associatedDeal
      ) {
        this.props.setShowAssociatedDealsMsg(true);
      } else if (order?.orderDealTreshold && sumSeats < order?.orderDealTreshold) {
        this.props.setShowAssociatedDealsMsg(false);
      } else if (order.associatedDeal || !order?.orderDealTreshold) {
        this.props.setShowAssociatedDealsMsg(false);
      }
    }
  };

  handleCustomer = async (e, val) => {
    this.props.setCustomer(val);
  };

  handleNote = (e) => {
    this.props.setNote(e.target.value);
  };

  showMsg = () => {
    const order = this.props.order;
    if (
      order?.orderDealTreshold &&
      Object.values(order.basket).reduce(
        (acc, item) => (acc + item.count?.value ? parseInt(item.count.value) : 0),
        0
      ) >= order?.orderDealTreshold
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleAssociatedDeal = (e) => {
    const val = e.target.value;
    val
      ? this.props.setShowAssociatedDealsMsg(false)
      : this.props.setShowAssociatedDealsMsg(this.showMsg());
    this.props.setAssociatedDeal(val);
  };

  render() {
    // const { error, customers, order } = this.state;
    const { order, setBasket } = this.props;

    const { error, possibleProducts, validities } = this.state;

    if (error) {
      return <CustomizedSnackbars message={error.message} variant="error" />;
    }

    let productMinCount = getLocalValue("minOrderCount");
    if (!productMinCount) {
      productMinCount = 1;
    }

    const handleAddItem = () => {
      const itemIdx =
        Object.keys(order?.basket).reduce((max, key) => {
          return Math.max(max, key);
        }, -1) + 1;

      setBasket({ ...order?.basket, [itemIdx]: initItem });
      this.setState({ itemIdx: itemIdx + 1 });
    };

    return (
      <>
        {order?.basket
          ? Object.entries(order?.basket).map(([itemIdx, item]) => (
              <OrderNewItemForm key={itemIdx} {...{ itemIdx, possibleProducts, validities }} />
            ))
          : null}
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <Button variant="contained" color="primary" onClick={handleAddItem}>
            Add item
          </Button>
        </Box>
        {order.showAssociatedDealsMsg ? (
          <Box style={{ display: "flex" }}>
            <Typography color="error" variant="h6">
              You have reached the threshold for creating order without associated deal. Please
              select a deal.
            </Typography>
            <OrderNoDealInfo />
          </Box>
        ) : null}

        {
          <Select
            style={{ marginBottom: 15, maxWidth: 590 }}
            label="Associated deal"
            value={order.associatedDeal}
            options={order.associatedDeals?.map((deal) => ({
              id: deal.id,
              label: deal.name,
            }))}
            onChange={this.handleAssociatedDeal}
          />
        }
        <FormControl style={{ marginBottom: 15 }}>
          <TextField
            required={order?.isNoteRequired || false}
            error={order?.isNoteRequired && !order?.note ? true : false}
            variant={"outlined"}
            id="note"
            label="Note"
            name="note"
            multiline
            rows="3"
            fullWidth
            onChange={this.handleNote}
            value={order.note || ""}
          />
          {order.isNoteRequired && !order.note ? (
            <FormHelperText style={{ color: "red" }}>
              Your discount exceeds the allowed limit. A note is now required.
            </FormHelperText>
          ) : null}
          <FormHelperText>
            If your discount exceeds the allowed limit, you must justify the discount in Note to
            submit the order.
          </FormHelperText>
        </FormControl>
        <Box style={{ display: "flex", alignItems: "baseline", marginBottom: 15 }}>
          <Typography variant="h6">
            Total end user price: {order.totalPrice ? getRoundedPrice(order.totalPrice) : "0,-"}{" "}
          </Typography>
          <Typography style={{ marginLeft: 6 }}>
            {" "}
            {order.totalPrice && order.currency ? order?.currency : ""}
          </Typography>
        </Box>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowAssociatedDealsMsg: (show) =>
      dispatch({ type: "order/SET_SHOW_ASSOCIATED_DEALS_MSG", show }),
    setShowAssociatedDealsSelect: (show) =>
      dispatch({ type: "order/SET_SHOW_ASSOCIATED_DEALS_SELECT", show }),
    setOrderDealTreshold: (treshold) =>
      dispatch({ type: "order/SET_ORDER_DEAL_TRESHOLD", treshold }),
    setAssociatedDeal: (deal) => dispatch({ type: "order/SET_ASSOCIATED_DEAL", deal }),
    setBasket: (basket) => dispatch({ type: "order/SET_BASKET", basket }),
    setNote: (note) => dispatch({ type: "order/SET_NOTE", note }),
    setTotalPrice: (price) => dispatch({ type: "order/SET_TOTAL_PRICE", price }),
  };
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  order: state.order,
  customer: state.customer,
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderNewProductForm);
