export const createOrder = (props) => {
  const { customer, _this } = props;

  _this.props.setCustomer({
    companyCompanyContexts: customer.companyCompanyContexts,
    id: customer.id,
    firm: customer.company_name,
  });

  if (customer?.resellerDetail) {
    _this.props.setReseller({
      reseller: {
        id: customer.resellerDetail.id,
        company: customer.resellerDetail.company_name,
      },
    });
  }

  _this.props.validateOrderCustomer({
    companyCompanyContexts: customer.companyCompanyContexts,
    id: customer.id,
    firm: customer.company_name,
  });

  _this.props.setNewCustomer(null);
};

export function isFutureDate(date) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  return date ? new Date(date) >= tomorrow : false;
}

export function checkForMaintenance(str) {
  if (str.toLowerCase().includes("maintenance")) {
    return false;
  }
  return true;
}
