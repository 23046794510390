import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import moment from "moment";
import React from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { Link } from "react-router-dom";
import { isUserAllowed } from "../../services/acl.js";

const handleStoreState = (data) => {
  localStorage.setItem(
    "Orders",
    JSON.stringify({
      ...JSON.parse(localStorage.getItem("Orders")),
      ...data,
    })
  );
};

export const options = () => ({
  filter: true,
  filterType: "dropdown",
  responsive: "stacked",
  print: false,
  selectableRows: "none",
  rowsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
  onChangeRowsPerPage: (rowsPerPage) => {
    handleStoreState({
      rowsPerPage,
    });
  },
  onChangePage: (page) => {
    handleStoreState({
      page,
    });
  },
  onSearchChange: (searchText) => {
    handleStoreState({
      searchText,
    });
  },
  onColumnSortChange: (column, direction) => {
    handleStoreState({
      columnSort: {
        column,
        direction,
      },
    });
  },
  customSort: (data, colIndex, order) => {
    return data.sort((a, b) => {
      let res = 1;
      if (colIndex === 0 || colIndex === 5) {
        // created
        if (a.data[colIndex] && b.data[colIndex]) {
          res = moment(a.data[colIndex], "DD.MM.YYYY") < moment(b.data[colIndex], "DD.MM.YYYY") ? -1 : 1;
        } else if (a.data[colIndex]) {
          res = 1;
        } else {
          res = -1;
        }
      } else if (colIndex === 3 || colIndex === 4 || colIndex === 6 || colIndex === 7) {
        // seats, renewals
        res = parseInt(a.data[colIndex]) > parseInt(b.data[colIndex]) ? -1 : 1;
      } else {
        // others
        res = a.data[colIndex].localeCompare(b.data[colIndex]) === -1 ? -1 : 1;
      }
      return res * (order === "desc" ? 1 : -1);
    });
  },
});

export const columnsDefault = ({ user }) => [
  {
    name: "created",
    label: "Created",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "customer",
    label: "Company",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <Link to={`/customer/detail/${tableMeta.rowData[10]}`}>{value}</Link>;
      },
    },
  },
  {
    name: "products",
    label: "Product",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "seats",
    label: "Users",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "endUserPrice",
    label: "End user price",
    options: {
      filter: true,
      sort: true,
      display: isUserAllowed(user, ["Orders_create", "Orders_basket", "Orders_invoices"]),
      customBodyRender: (value, tableMeta, updateValue) => {
        return <Typography noWrap>{`${value} ${tableMeta.rowData[11]}`}</Typography>;
      },
    },
  },
  {
    name: "renewalDate",
    label: "Renewal date",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "isRenewal",
    label: "Is renewal",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value ? "Yes" : "No";
      },
    },
  },
  {
    name: "isRenewed",
    label: "Is renewed",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value ? "Yes" : "No";
      },
    },
  },
  {
    name: "state",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      download: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <IconButton component={Link} size={"small"} variant="contained" to={`/order/detail/${value}`}>
            <KeyboardArrowRight />
          </IconButton>
        );
      },
      customHeadRender: (columnMeta, handleToggleColumn) => {
        return (
          <th
            key={`th_key_${columnMeta.id}`}
            className="MuiTableCell-root MUIDataTableHeadCell-root-348 MUIDataTableHeadCell-fixedHeader-349 MuiTableCell-head"
            scope="col"
          >
            <div className="MUIDataTableHeadCell-data-352"></div>
          </th>
        );
      },
    },
  },
  {
    name: "companyId",
    label: "companyId",
    options: {
      filter: false,
      sort: false,
      display: false,
      download: false,
    },
  },
  {
    name: "currency",
    label: "currency",
    options: {
      filter: false,
      sort: false,
      display: false,
      download: false,
    },
  },
];
