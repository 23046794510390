
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { connect } from 'react-redux';
import fetchClient from "../../../services/axios";
import { getRoundedPrice } from "../../../services/utils";
import { Select } from "../../../ui/Input";
import CustomizedSnackbars from "../../CustomizedSnackbars";
import OrderNewItemFormSummary from "./OrderNewItemFormSummary";


const initItem = {
    product: { value: null, required: true, error: false },
    count: { value: 0, required: true, error: false },
    dateStart: { value: new Date(), required: true, error: false },
    validity: { value: 0, required: true, error: false },
    discount: { value: 0, required: false, error: false },
};


const ProductDetailInfo = {
    dateStart: new Date(),
    validity: "",
    count: 0,
    product: "",
    government: 0,
    discount: 0,
};


class OrderNewSummary extends Component {
    state = {
        isLoading: false,
        error: false,
        products: [],
        validities: [],
        basket: [initItem],
        productDetailsInfo: [ProductDetailInfo],
        existingCustomer: "",
        newCustomer: {
            firmName: "",
            contactName: "",
            contactEmail: "",
            street: "",
            city: "",
            zip: "",
            country: "",
            industry: "",
        },
        possibleProducts: [],
        itemIdx: 0,
    };

    componentDidMount = () => {

        if (!this.props?.order?.basket && !this.props?.order?.basket?.[0]?.product) {
            this.props.setBasket({ [this.state.itemIdx]: initItem });
            this.setState({ itemIdx: this.state.itemIdx + 1 });
        }

        fetchClient()
            .post(
                process.env.REACT_APP_API_URL +
                "/order-basket/order-product-options",
                {
                    customer: this.props?.order?.customer?.id ?? null,
                    country: this.props?.customer?.country.value ?? null,
                }
            )
            .then((response) => {
                this.setState({
                    possibleProducts: response.data.products,
                    validities: response.data.validities,
                });


            }).catch((error) => {
                console.error(error);
            });

    };

    componentDidUpdate = (prevProps) => {
        const order = this.props.order;
        const sumSeats = order.basket ?
            Object.values(order.basket).reduce((acc, item) =>
                acc + item.count?.value ? parseInt(item.count.value) : 0, 0) : 0;
        if (prevProps.order.basket !== order.basket) {
            this.props.order.basket ?
                this.props.setTotalPrice(Object.values(order.basket).reduce((acc, item) => acc + item.price, 0)) :
                this.props.setTotalPrice(0);

        }
    }

    handleCustomer = async (e, val) => {
        this.props.setCustomer(val)
    }

    handleNote = (e) => {
        this.props.setNote(e.target.value);
    }

    handleAssociatedDeal = (e) => {
        const val = e.target.value
        val ? this.props.setShowAssociatedDealsMsg(false) : this.props.setShowAssociatedDealsMsg(true);
        this.props.setAssociatedDeal(val);
    }

    render() {
        const {
            order,
        } = this.props;

        const { error, possibleProducts, validities } = this.state;

        if (error) {
            return <CustomizedSnackbars message={error.message} variant="error" />;
        }

        return (
            <>
                {
                    order?.basket ? Object.entries(order?.basket).map(([itemIdx, item]) =>
                        <OrderNewItemFormSummary key={itemIdx} {...{ itemIdx, possibleProducts, validities }} />)
                        : null
                }

                {order.associatedDeal ?
                    <Select
                        disabled
                        style={{ marginBottom: 15, maxWidth: 590 }}
                        label="Associated deal"
                        value={order.associatedDeal}
                        options={order.associatedDeals?.map((deal) => ({
                            id: deal.id,
                            label: deal.name
                        }))}
                        onChange={this.handleAssociatedDeal}
                    /> : null}
                {order.note && <FormControl style={{ marginBottom: 15 }}>
                    <TextField
                        disabled
                        variant={"outlined"}
                        id="note"
                        label="Note"
                        name="note"
                        multiline
                        rows="3"
                        fullWidth
                        onChange={this.handleNote}
                        value={order.note}
                        style={{ color: "red" }}
                    />
                </FormControl>}
                <Box style={{ display: "flex", alignItems: "baseline", marginBottom: 15 }}>
                    <Typography variant="h6">Total end user price: {order.totalPrice ? getRoundedPrice(order.totalPrice) : "0,-"} </Typography>
                    <Typography style={{ marginLeft: 6 }}> {order.totalPrice && order.currency ? order?.currency : ""}</Typography>
                </Box>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setShowAssociatedDealsMsg: (show) => dispatch({ type: 'order/SET_SHOW_ASSOCIATED_DEALS_MSG', show }),
        setShowAssociatedDealsSelect: (show) => dispatch({ type: 'order/SET_SHOW_ASSOCIATED_DEALS_SELECT', show }),
        setOrderDealTreshold: (treshold) => dispatch({ type: 'order/SET_ORDER_DEAL_TRESHOLD', treshold }),
        setAssociatedDeal: deal => dispatch({ type: 'order/SET_ASSOCIATED_DEAL', deal }),
        setBasket: basket => dispatch({ type: 'order/SET_BASKET', basket }),
        setNote: note => dispatch({ type: 'order/SET_NOTE', note }),
        setTotalPrice: price => dispatch({ type: 'order/SET_TOTAL_PRICE', price })
    }
}


const mapStateToProps = state => ({
    isLoading: state.settings.isLoading,
    order: state.order,
    customer: state.customer,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderNewSummary);

