import { debounce } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { orderEditProductCalculation, orderSaveIncrease } from "../../../api/order";
import { subscriptionSubscription } from "../../../api/subscription";
import fetchClient, { postClient } from "../../../services/axios";
import { format2Decimal, formatFullDate } from "../../../services/utils";
import FormDialog from "../../../ui/Dialog/Dialog";
import OrderCard from "../../Orders/OrderCard";
import Can from "../../../services/acl";
import OrderEditForm from "./OrderEditForm";

const initItem = {
  product: { value: null, required: true, error: false },
  count: { value: 0, required: true, error: false },
  dateStart: { value: null, required: true, error: false },
  dateEnd: { value: null, required: true, error: false },
  //   validity: { value: 0, required: true, error: false },
  discount: { value: 0, required: false, error: false },
  validFrom: { value: new Date(), required: true, error: false },
  note: { value: null, required: false, error: false },
};

class OrderEdit extends Component {
  state = {
    id: null,
    isLoading: false,
    order: null,
    newCount: "",
    error: false,
    currency: null,
  };

  componentDidMount = () => {
    const { match } = this.props;
    const id = match.params.id;

    this.setState({ id });

    if (!this.props?.order?.basket) {
      this.props.setBasket({
        [0]: {
          ...initItem,
          discount: {
            ...initItem.discount,
            //validation for max discount is turned off
            // max: maxDiscount ? parseInt(maxDiscount) : null
          },
        },
        [1]: {
          ...initItem,
          discount: {
            ...initItem.discount,
            //validation for max discount is turned off
            // max: maxDiscount ? parseInt(maxDiscount) : null
          },
        },
      });
    }
  };

  handleOpen = () => {
    const { id } = this.props.match.params;

    if (!id) {
      console.error("Order ID not found in URL params");
      return;
    }

    this.setState({ isLoading: true });

    fetchClient()
      .get(`${process.env.REACT_APP_API_URL}${subscriptionSubscription}/${id}`)
      .then((response) => {
        this.setState({ currency: response.data.currency });

        const resPrice = response.data.price_final_end_user_origin;
        const minCount = 1;

        // 0 - existing order, 1 - new order
        this.props.setBasket({
          [0]: {
            ...initItem,
            product: {
              value: response.data.title,
              required: true,
              error: false,
            },
            count: {
              value: response.data.count,
              required: true,
              error: false,
            },
            dateStart: {
              value: response.data.valid_from,
              required: true,
              error: false,
            },
            dateEnd: {
              value: response.data.valid_to,
              required: true,
              error: false,
            },
            discount: {
              value: 0,
              required: false,
              error: false,
            },
            price: resPrice || 0,
          },
          [1]: {
            ...initItem,
            product: {
              value: response.data.title,
              required: true,
              error: false,
            },
            count: {
              value: minCount,
              required: true,
              error: false,
            },
            dateStart: {
              value: response.data.valid_from,
              required: true,
              error: false,
            },
            dateEnd: {
              value: response.data.valid_to,
              required: true,
              error: false,
            },
            discount: {
              value: null,
              required: false,
              error: false,
            },
            totalPrice: resPrice ? parseFloat(resPrice) : 0,
          },
        });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  debouncedRequest = null;

  componentDidUpdate(prevProps) {
    const prevItem = prevProps.order.basket?.[1];
    const item = this.props.order.basket?.[1];
    if (
      (item?.product?.value &&
        (prevItem?.count?.value !== item?.count?.value ||
          prevItem?.discount?.value !== item?.discount?.value)) ||
      prevItem?.validFrom.value !== item?.validFrom.value
    ) {
      const { setBasket, order } = this.props;
      if (item?.count?.value) {
        if (this.debouncedRequest) {
          this.debouncedRequest.cancel();
        }
        this.debouncedRequest = debounce(() => {
          this.setState({ isLoading: true });
          const basket = this.props.state.order.basket[1];

          const data = {
            id: this.state.id,
            order: {
              count: basket.count.value,
              validFrom: basket.validFrom.value,
              discount: parseFloat(basket.discount.value),
              note: basket.note.value,
            },
          };

          postClient(process.env.REACT_APP_API_URL + orderEditProductCalculation, data)
            .then((response) => {
              const res = response;
              setBasket({
                ...this.props.order.basket,
                ...{
                  [1]: {
                    ...order.basket[1],
                    price: res.price,
                    totalPrice:
                      parseFloat(this.props.order.basket[0].price) +
                      (res.price ? parseFloat(res.price) : 0),
                  },
                },
              });
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              console.error(error);
              this.setState({ isLoading: false });
            });
        }, 300);
        this.debouncedRequest();
      }
    }
  }

  handleSubmit = () => {
    const { id } = this.state;
    const { order, user } = this.props;
    const basket = order.basket[1];

    const data = {
      orderId: id,
      user,
      order: {
        product: basket.product.value,
        count: basket.count.value,
        dateStart: basket.dateStart.value,
        dateEnd: basket.dateEnd.value,
        discount: basket.discount.value,
        note: basket.note.value,
        validFrom: basket.validFrom.value,
      },
    };

    this.setState({ isLoading: true });

    postClient(process.env.REACT_APP_API_URL + orderSaveIncrease, data)
      .then((response) => {
        this.setState({ isLoading: false });
        this.props.history.push(`/order/detail/${id}`);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { user, state, isFutureDate, checkForMaintenance } = this.props;
    const existOrder = state.order.basket?.[0];
    // const order = state.order.basket?.[1];

    const { isLoading, newCount, error } = this.state;

    return (
      <Can resource="Orders_edit">
        <FormDialog
          disableButton={!isFutureDate || !checkForMaintenance}
          openTitle="Change Order"
          dialogTitle="Change Order"
          submitTitle="Submit Order"
          dialogContentText="Existing Order"
          onOpen={() => this.handleOpen()}
          onSubmit={() => this.handleSubmit()}
          disableSubmit={error}
          onClose={() => null}
          rerender={() =>
            //fligna, aby se po znovuotevření přepočítaly ceny
            this.props.setBasket({
              ...this.props.order.basket,
              1: {
                ...this.props.order.basket[1],
                count: { ...this.props.order.basket[1].count, value: 0 },
              },
            })
          }
        >
          <OrderCard
            product={existOrder?.product?.value}
            users={existOrder?.count?.value}
            currency={this.state.currency}
            startDate={formatFullDate(existOrder?.dateStart?.value)}
            endDate={formatFullDate(existOrder?.dateEnd?.value)}
            discount={existOrder?.discount?.value}
            price={format2Decimal(existOrder?.price)}
          />
          <OrderEditForm
            isLoading={isLoading}
            currency={this.state.currency}
            user={user}
            order={this.props.order}
            onChange={(key, value, error) => {
              return (
                this.props.setBasket({
                  ...this.props.order.basket,
                  1: {
                    ...this.props.order.basket[1],
                    [key]: { ...this.props.order.basket[1][key], value, error },
                  },
                }),
                this.setState({ error })
              );
            }}
          />
        </FormDialog>
      </Can>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBasket: (basket) => dispatch({ type: "order/SET_BASKET", basket }),
    setIsNoteRequired: (required) => dispatch({ type: "order/SET_IS_NOTE_REQUIRED", required }),
  };
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  order: state.order,
  customer: state.customer,
  user: state.user,
  state: state,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderEdit));
