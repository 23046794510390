import { validateEmail } from "./validator";

const requiredCustomerAttribs = [
  "firmName",
  "contactName",
  "contactEmail",
  "city",
  "country",
  "industry",
  "web",
];

export const validateWeb = (web) => {
  const regex =
    /^(https?:\/\/)?(www\.)?([-a-zA-Z0-9@:%_\+~#=]+[-a-zA-Z0-9@:.%_\+~#=]){0,254}[-a-zA-Z0-9@:%_\+~#=]+\.[a-z]{2,6}([:?#/][a-zA-Z0-9]([-a-zA-Z0-9@:%_\+.~#?&//=]+))?$/;
  return regex.test(web);
};

export const isCustomerFormFilled = (customer) => {
  for (let key in customer) {
    if (
      requiredCustomerAttribs.indexOf(key) !== -1 &&
      (customer[key] === null || customer[key] === "" || customer[key] === false)
    ) {
      return false;
    }
  }

  return Boolean(validateEmail(customer.contactEmail)) && Boolean(validateWeb(customer.web));
};
