import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

//resource: string | [string]; acl: string
export const isUserAllowed = (user, resource) => {
  const acl = user?.allowed_acl_resources && user.allowed_acl_resources.split(",").map((aclItem) => aclItem.trim());

  if (resource === true) {
    return true;
  }

  if (!acl || !resource) {
    return false;
  }

  if (Array.isArray(resource)) {
    return resource.some((r) => acl.includes(r));
  } else {
    return acl.includes(resource);
  }
};

class Can extends React.Component {
  render() {
    const { children, resource, user } = this.props;
    return isUserAllowed(user, resource) ? children : null;
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default compose(connect(mapStateToProps))(Can);
