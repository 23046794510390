import React from "react";
import CustomizedSnackbars from "./CustomizedSnackbars";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import fetchClient from "../services/axios";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Can from "../services/acl";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

class NewResellerForm extends React.Component {
  state = {
    isLoading: false,
    error: false,
    countries: [],
    showPassword: false,
  };

  componentDidMount = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + process.env.REACT_APP_COUNTRIES_ENDPOINT)
      .then((response) => {
        this.setState({
          countries: response.data.countries,
        });
        /*if (this.props.isEditing) {

            }*/
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { classes, newReseller, handleChangeNewReseller } = this.props;
    const { countries, error } = this.state;

    return (
      <React.Fragment>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}
        <form className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="email"
                name="email"
                label="Reseller e-mail"
                fullWidth
                autoComplete="email"
                value={newReseller.email}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Can resource="Resellers_login">
                <TextField
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  label="Password (grant access)"
                  fullWidth
                  value={newReseller.password}
                  autoComplete={"reseller-password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={this.handleClickShowPassword}>
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => handleChangeNewReseller(e)}
                />
              </Can>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                autoComplete="name"
                value={newReseller.name}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="surname"
                name="surname"
                label="Surname"
                fullWidth
                autoComplete="surname"
                value={newReseller.surname}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="company"
                name="company"
                label="Company"
                fullWidth
                autoComplete="company"
                value={newReseller.company_name}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {countries && countries.length > 0 && (
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="id_country">Country*</InputLabel>
                  <Select
                    required
                    native
                    id="id_country"
                    name="id_country"
                    label="Country"
                    fullWidth
                    autoComplete="id_country"
                    value={newReseller.countryDetail ? newReseller.countryDetail.id : null}
                    onChange={(e) => handleChangeNewReseller(e)}
                  >
                    <option value=""></option>
                    {countries.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="dico"
                name="dico"
                label="VAT ID / Tax ID"
                fullWidth
                autoComplete="dico"
                value={newReseller.tax_identification_number}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="ico"
                name="ico"
                label="Firm ID"
                fullWidth
                autoComplete="ico"
                value={newReseller.identification_number}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="phone"
                name="phone"
                label="Contact phone"
                fullWidth
                autoComplete="phone"
                value={newReseller.phone}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="address"
                name="address"
                label="Address"
                fullWidth
                autoComplete="address"
                value={newReseller.address}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                fullWidth
                autoComplete="city"
                value={newReseller.city}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="zip"
                name="zip"
                label="Zip / Postal code"
                fullWidth
                autoComplete="zip"
                value={newReseller.zip}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="website"
                name="website"
                label="Company website"
                fullWidth
                autoComplete="website"
                value={newReseller.website}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                multiline={true}
                id="note"
                name="note"
                label="Note"
                fullWidth
                autoComplete="note"
                value={newReseller.note}
                onChange={(e) => handleChangeNewReseller(e)}
              />
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

NewResellerForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewResellerForm);
