import React from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { toDecimal } from "../../services/utils";
import Tooltip from "@material-ui/core/Tooltip";

export const stages = {
  "1. Discovery": "A validated prospect (opportunity identification)",
  "2. Qualification": "Prospect qualification (business case and fit evaluation, high-level demo)",
  "3. Follow-up": "Providing prospect with all information needed (technical demo, quote, documentation)",
  "4. PoC / Trial": "Proof of Concept / Trial Period (product testing)",
  "5. Negotiation": "Final negotiations, decision-making, pricing adjustments",
  "6. On Hold": "Deals with at least 50% chance of winning, but on-hold",
  "7. Closed Won": "Successfully closed deal (Prospect placed a purchase order)",
  "8. Closed Lost": "Lost deal (Sale did not materialize)",
  "9. Canceled": "Errors and duplicities",
};

export const stagesFullText = {
  "1. Discovery": "1. Discovery = A validated prospect(opportunity identification)",
  "2. Qualification": "2. Qualification = Prospect qualification(business case and fit evaluation, high - level demo)",
  "3. Follow - up":
    "3. Follow - up = Providing prospect with all information needed(technical demo, quote, documentation)",
  "4. PoC / Trial": "4. PoC / Trial = Proof of Concept / Trial Period(product testing)",
  "5. Negotiation": "5. Negotiation = Final negotiations, decision - making, pricing adjustments",
  "6. On Hold": "6. On Hold = Deals with at least 50 % chance of winning, but on - hold",
  "7. Closed Won": "7. Closed Won = Successfully closed deal(Prospect placed a purchase order)",
  "8. Closed Lost": "8. Closed Lost = Lost deal(Sale did not materialize)",
  "9. Canceled": "9. Canceled = Errors and duplicities",
};

export const getColumnStatus = (value) => {
  if (value) {
    if (value.startsWith("Confirmed")) {
      return "Confirmed";
    } else if (value.startsWith("Refused") || value.startsWith("Rejected")) {
      return "Rejected";
    } else {
      return value;
    }
  } else {
    return null;
  }
};

export const options = (handleStoreState) => ({
  filter: true,
  filterType: "dropdown",
  responsive: "stacked",
  print: false,
  selectableRows: "none",
  rowsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
  textLabels: {
    // body: {
    //     noMatch: "Deal records cannot be loaded from database now. Please try refreshing this page in 5 minutes."
    // }
  },
  // onChangeRowsPerPage: (rowsPerPage) => {handleStoreState({rowsPerPage});},
  // onChangePage: (page) => {},
  // onSearchChange: (searchText) => {},
  // onColumnSortChange: (column, direction) => {
  //     handleStoreState({
  //         columnSort: {
  //             column,
  //             direction,
  //         },
  //     });
  // },
  customSort: (data, colIndex, order) => {
    return data.sort((a, b) => {
      let res = 1;
      if (colIndex === 7 || colIndex === 8 || colIndex === 9) {
        // dates
        if (a.data[colIndex] && b.data[colIndex]) {
          res = moment(a.data[colIndex], "DD.MM.YYYY") < moment(b.data[colIndex], "DD.MM.YYYY") ? -1 : 1;
        } else if (a.data[colIndex]) {
          res = 1;
        } else {
          res = -1;
        }
      } else if (colIndex === 6 || colIndex === 7) {
        // numbers
        res = (parseInt(a.data[colIndex]) || 0) > (parseInt(b.data[colIndex]) || 0) ? -1 : 1;
      } else {
        // others
        if (a.data[colIndex] === null) a.data[colIndex] = "";
        res = a.data[colIndex].localeCompare(b.data[colIndex]) === -1 ? -1 : 1;
      }
      return res * (order === "desc" ? 1 : -1);
    });
  },
  // customToolbar: () => {
  //   return (
  //     <FormControlLabel
  //       control={
  //         <Checkbox
  //           size="small"
  //           color="primary"
  //           checked={this.state.onlyActive}
  //           onChange={this.handleOnlyActive}
  //         />
  //       }
  //       label="Active deals"
  //     />
  //   );
  // },
});

export const columnsDefault = ({ currency }) => [
  {
    name: "name",
    label: "Deal name",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <Link to={`/deal-hubspot/detail/${tableMeta.rowData[9]}`}>{value}</Link>;
        // return tableMeta.rowData[8] !== "Waiting for confirmation" ? (
        //   <Link to={`/deal-hubspot/detail/${tableMeta.rowData[9]}`}>{value}</Link>
        // ) : (
        //   value
        // );
      },
    },
  },
  {
    name: "customer",
    label: "Customer firm",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "deal_stage",
    label: "Deal Stage",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Tooltip title={stages[value] || "-"} style={{ cursor: "pointer" }} classes={{ tooltip: "" }}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
  },
  {
    name: "source",
    label: "Source",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "seats",
    label: "Users",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "end_user_price",
    label: "End User Price",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value ? <Typography noWrap>{`${value ? toDecimal(value, 2) : "-"} ${currency}`}</Typography> : "";
      },
    },
  },
  {
    name: "date_closed",
    label: "Close date",
    options: {
      filter: true,
      sort: true,
    },
  },
  // {
  //     name: "date_modified",
  //     label: "Last update",
  //     options: {
  //         filter: true,
  //         sort: true,
  //     },
  // },
  {
    name: "reserved_until",
    label: "Reserved until",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "approval_status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => getColumnStatus(value),
    },
  },
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      download: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        // return tableMeta.rowData[8] !== "Waiting for confirmation" ? (
        return (
          <IconButton component={Link} size={"small"} variant="contained" to={`/deal-hubspot/detail/${value}`}>
            <KeyboardArrowRight />
          </IconButton>
        );
        // ) : (
        //   ""
        // );
      },
      customHeadRender: (columnMeta, handleToggleColumn) => {
        return (
          <th
            key={`th_key_${columnMeta.id}`}
            className="MuiTableCell-root MUIDataTableHeadCell-root-348 MUIDataTableHeadCell-fixedHeader-349 MuiTableCell-head"
            scope="col"
          >
            <div className="MUIDataTableHeadCell-data-352"></div>
          </th>
        );
      },
    },
  },
  {
    name: "customerId",
    label: "Customer ID",
    options: {
      filter: false,
      sort: false,
      display: false,
      download: false,
    },
  },
  {
    name: "hs_is_closed",
    label: "Is closed?",
    options: {
      filter: true,
      sort: true,
      display: false,
      download: true,
    },
  },
];
