import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { debounce } from "lodash";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import React, { Component } from "react";
import { connect } from "react-redux";
import fetchClient from "../../../services/axios";
import { getLocalValue } from "../../../services/storage";
import { getRoundedPrice } from "../../../services/utils";
import { Number, Select } from "../../../ui/Input";
import { orderProductCalculation } from "../../../api/order";
import { checkDecimal8 } from "../../../services/utils";
import Spinner from "../../../ui/Spinner/Spinner";

class OrderNewItemForm extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    const { itemIdx, setBasket, order } = this.props;
    setBasket({
      ...order.basket,
      ...{
        [itemIdx]: {
          ...order.basket[itemIdx],
          count: {
            ...order.basket[itemIdx].count,
            value: order.basket[itemIdx].count?.value || order.orderSettings.minSeats,
          },
          price: order.basket[itemIdx]?.price || 0,
        },
      },
    });
  }

  debouncedRequest = null;

  componentDidUpdate(prevProps) {
    const prevItem = prevProps.order.basket[this.props.itemIdx];
    const item = this.props.order.basket[this.props.itemIdx];

    if (
      !item.discount.error &&
      item?.product?.value &&
      (prevItem?.product?.value !== item?.product?.value ||
        prevItem?.count?.value !== item?.count?.value ||
        prevItem?.validity?.value !== item?.validity?.value ||
        prevItem?.discount?.value !== item?.discount?.value)
    ) {
      const { itemIdx, setBasket, order } = this.props;
      if (item?.product?.value && item?.count?.value && item?.validity?.value) {
        if (this.debouncedRequest) {
          this.debouncedRequest.cancel();
        }

        this.debouncedRequest = debounce(() => {
          this.setState({ isLoading: true });
          fetchClient()
            .post(process.env.REACT_APP_API_URL + orderProductCalculation, {
              product: this.props.order?.basket[itemIdx],
              customer: this.props.order?.customer?.id ?? null,
              country: this.props.customer?.country.value ?? null,
              reseller: this.props.order?.reseller?.id ?? null,
            })
            .then((response) => {
              const res = response.data;
              setBasket({
                ...order.basket,
                ...{
                  [itemIdx]: {
                    ...order.basket[itemIdx],
                    price: res.price || 0,
                    currency: res.currency || "-",
                  },
                },
              });

              this.props.setCurrency(res.currency);
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              console.error(error);
              this.setState({ isLoading: false });
            });
        }, 300);

        this.debouncedRequest();
      }
    }
  }

  render() {
    const { itemIdx, validities, possibleProducts, order, user } = this.props;

    const { isLoading } = this.state;

    const basket = order.basket;

    const usedProducts = Object.values(basket).map((item) => item.product.value);

    const filteredList = possibleProducts.filter(
      (item) => !usedProducts.includes(item.id) || item.id === basket[itemIdx]?.product.value
    );

    const handleRemoveItem = () => {
      const updatedBasket = Object.entries(basket).reduce((acc, [i, item]) => {
        if (i !== itemIdx) {
          acc[i] = item;
        }
        return acc;
      }, {});

      this.props.setBasket(updatedBasket);
    };
    const handleDetail = (val, prop) => {
      this.setState({ isLoading: true });
      if (prop === "product") {
        this.props.setBasket({
          ...basket,
          ...{
            [itemIdx]: {
              ...basket[itemIdx],
              [prop]: { ...basket[itemIdx][prop], value: val, error: false },
              ["validity"]: { ...basket[itemIdx]["validity"], value: null, error: false },
              price: 0,
              currency: null,
            },
          },
        });
      } else if (prop === "discount") {
        const d = user.max_discount;
        if (d && d === "null" && parseFloat(val) > 0) {
          this.props.setIsNoteRequired(true);
        } else if (!d && parseFloat(val) > 0) {
          this.props.setIsNoteRequired(true);
        } else if (user.max_discount && parseFloat(user.max_discount) < parseFloat(val)) {
          this.props.setIsNoteRequired(true);
        } else {
          this.props.setIsNoteRequired(false);
        }

        if (val > 100) {
          this.props.setBasket({
            ...basket,
            ...{
              [itemIdx]: {
                ...basket[itemIdx],
                [prop]: { ...basket[itemIdx][prop], value: val, error: "Max value is 100 %" },
                price: 0,
              },
            },
          });
        } else if (!checkDecimal8(val)) {
          this.props.setBasket({
            ...basket,
            ...{
              [itemIdx]: {
                ...basket[itemIdx],
                [prop]: { ...basket[itemIdx][prop], value: val, error: "Invalid format" },
                price: 0,
              },
            },
          });
        } else {
          this.props.setBasket({
            ...basket,
            ...{
              [itemIdx]: {
                ...basket[itemIdx],
                [prop]: { ...basket[itemIdx][prop], value: val, error: false },
                price: 0,
              },
            },
          });
        }
      } else {
        this.props.setBasket({
          ...basket,
          ...{
            [itemIdx]: {
              ...basket[itemIdx],
              [prop]: { ...basket[itemIdx][prop], value: val, error: false },
            },
          },
        });
      }
    };

    const validity =
      possibleProducts.find((item) => item.id === basket[itemIdx]?.product?.value)
        ?.allowedValidities || null;

    const filteredValidities = validities
      ? validities.filter((item) => validity?.includes(parseInt(item.id)))
      : [];

    return (
      <Paper elevation={3} style={{ marginBottom: 20, padding: 20 }}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Select
                error={basket[itemIdx]?.product?.error}
                label="Product"
                required
                fullWidth
                options={filteredList}
                value={basket[itemIdx]?.product?.value || ""}
                onChange={(e) => handleDetail(e.target.value, "product")}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                error={basket[itemIdx]?.count?.error}
                helperText={basket[itemIdx]?.count?.error}
                variant={"outlined"}
                value={basket[itemIdx]?.count?.value}
                label="Users"
                type="number"
                fullWidth
                style={{ width: 120 }}
                inputProps={{
                  min: order?.orderSettings?.minSeats || 0,
                  max: getLocalValue("maxProductCount") || null,
                  step: order?.orderSettings?.step || 1,
                  style: { textAlign: "center" },
                }}
                onChange={(e) => handleDetail(e.target.value, "count")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>End User Price</Typography>
                <Box style={{ display: "flex", alignItems: "end" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {basket[itemIdx]?.price ? (
                      getRoundedPrice(basket[itemIdx]?.price)
                    ) : isLoading && basket[itemIdx]?.validity.value ? (
                      <Spinner size={15} />
                    ) : (
                      "0,-"
                    )}
                  </Typography>
                  {basket[itemIdx]?.price ? (
                    <Typography style={{ marginLeft: 10, fontSize: 12, marginBottom: 2 }}>
                      {basket[itemIdx]?.currency}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  error={basket[itemIdx]?.dateStart?.error}
                  helperText={basket[itemIdx]?.dateStart?.error}
                  format="MMMM d'th', yyyy"
                  required
                  margin=""
                  label="Start date"
                  value={basket[itemIdx]?.dateStart?.value}
                  fullWidth
                  onChange={(val) => handleDetail(val, "dateStart")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                disabled={!Boolean(basket[itemIdx]?.product?.value)}
                error={basket[itemIdx]?.validity?.error}
                required
                label="Validity"
                fullWidth
                autoComplete="validity"
                value={basket[itemIdx]?.validity?.value || ""}
                options={filteredValidities}
                onChange={(e) => handleDetail(e.target.value, "validity")}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Number
                label={`Discount (max. ${user?.max_discount && user.max_discount !== "null" ? parseInt(user?.max_discount) : 0} %)`}
                value={basket[itemIdx]?.discount?.value || ""}
                onChange={(val) => {
                  if (val === "") {
                    handleDetail(0, "discount");
                  } else {
                    handleDetail(val, "discount");
                  }
                }}
                error={basket[itemIdx]?.discount?.error}
                helperText={basket[itemIdx]?.discount?.error}
              />
            </Grid>
          </Grid>
        </form>
        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Button
            color={"secondary"}
            disabled={Object.keys(basket).length <= 1}
            size="small"
            onClick={() => handleRemoveItem()}
          >
            Remove
          </Button>
        </Box>
      </Paper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBasket: (basket) => dispatch({ type: "order/SET_BASKET", basket }),
    setCurrency: (currency) => dispatch({ type: "order/SET_CURRENCY", currency }),
    setIsNoteRequired: (required) => dispatch({ type: "order/SET_IS_NOTE_REQUIRED", required }),
  };
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  order: state.order,
  customer: state.customer,
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderNewItemForm);
