import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import moment from 'moment';
import DomainIcon from "@material-ui/icons/Domain";
import { Typography } from "@material-ui/core";
// import Submenu from './GridSubmenu';
// import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import fetchClient from "../services/axios";
import { getLocalValue, setLocalValue } from "../services/storage";
import Can from "../services/acl";
import { getLoggedUserCompanyId } from "../services/auth";
import { resellers } from "../api/partner.js";

const columnsDefault = [
  {
    name: "company",
    label: "Company",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return tableMeta.rowData[7] !== getLoggedUserCompanyId() ? (
          <Link to={`/reseller/detail/${tableMeta.rowData[7]}`}>{value}</Link>
        ) : (
          value
        );
      },
    },
  },
  {
    name: "country",
    label: "Country",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "parent",
    label: "Parent",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "customers_count",
    label: "Customers",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "orders_count",
    label: "Orders",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "nfrs_count",
    label: "NFRs",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "type",
    label: "Type",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      download: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return parseInt(value) !== getLoggedUserCompanyId() ? (
          <IconButton component={Link} size={"small"} variant="contained" to={`/reseller/detail/${value}`}>
            <KeyboardArrowRight />
          </IconButton>
        ) : (
          ""
        );
      },
      customHeadRender: (columnMeta, handleToggleColumn) => {
        return (
          <th
            key={`th_key_${columnMeta.id}`}
            className="MuiTableCell-root MUIDataTableHeadCell-root-348 MUIDataTableHeadCell-fixedHeader-349 MuiTableCell-head"
            scope="col"
          >
            <div className="MUIDataTableHeadCell-data-352"></div>
          </th>
        );
      },
    },
  },
];

const styles = (theme) => ({
  tableContainer: {
    minHeight: 320,
  },
});

class Resellers extends React.Component {
  state = {
    resellers: [],
    error: null,
    isLoading: true,
    status: false,
  };

  options = {
    filter: true,
    filterType: "dropdown",
    responsive: "stacked",
    print: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
    onChangeRowsPerPage: (rowsPerPage) => {
      this.handleStoreState({
        rowsPerPage,
      });
    },
    onChangePage: (page) => {
      this.handleStoreState({
        page,
      });
    },
    onSearchChange: (searchText) => {
      this.handleStoreState({
        searchText,
      });
    },
    onColumnSortChange: (column, direction) => {
      this.handleStoreState({
        columnSort: {
          column,
          direction,
        },
      });
    },
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        let res = 1;
        if (colIndex === 3 || colIndex === 4 || colIndex === 5) {
          res = parseInt(a.data[colIndex]) > parseInt(b.data[colIndex]) ? -1 : 1;
        } else if (a.data[colIndex] && b.data[colIndex]) {
          // others
          res = a.data[colIndex].localeCompare(b.data[colIndex]) === -1 ? -1 : 1;
        }
        return res * (order === "desc" ? 1 : -1);
      });
    },
  };

  handleStoredTableOptions = (defaultOptions) => {
    return {
      ...defaultOptions,
      ...JSON.parse(getLocalValue("Resellers")),
    };
  };

  handleStoredColsOptions = (columnsDefault) => {
    var options = JSON.parse(getLocalValue("Resellers"));
    if (options && options.columnSort) {
      columnsDefault.forEach((i) => {
        if (i.name === options.columnSort.column) {
          if (options.columnSort.direction === "ascending") {
            i.options.sortDirection = "asc";
          }
          if (options.columnSort.direction === "descending") {
            i.options.sortDirection = "desc";
          }
        }
      });
    }
    return columnsDefault;
  };

  handleStoreState = (data) => {
    setLocalValue(
      "Resellers",
      JSON.stringify({
        ...JSON.parse(getLocalValue("Resellers")),
        ...data,
      })
    );
  };

  componentDidMount = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + resellers)
      .then((response) => {
        return response.data.resellers.map((reseller) => {
          return {
            ...reseller,
            parent: reseller.parent ? reseller.parent.company_name : "",
            country: reseller.countryDetail ? reseller.countryDetail.name : "",
          };
        });
      })
      .then((resellers) => {
        this.setState({
          resellers,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  render() {
    const { classes } = this.props;
    const { error, resellers } = this.state;

    return (
      <div>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}

        <Can resource="Resellers_create">
          <Button
            component={Link}
            className={classes.button}
            to="/reseller/create"
            variant="contained"
            color="primary"
            style={{ marginBottom: 15 }}
          >
            Add new reseller
          </Button>
        </Can>

        <div className={classes.tableContainer}>
          <ReactPlaceholder
            type="text"
            ready={!this.state.isLoading}
            rows={20}
            color="#E0E0E0"
            showLoadingAnimation={true}
          >
            <MUIDataTable
              title={
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <DomainIcon />
                  </Grid>
                  <Grid item>
                    <Typography>Partners</Typography>
                  </Grid>
                </Grid>
              }
              data={resellers}
              options={this.handleStoredTableOptions(this.options)}
              columns={this.handleStoredColsOptions(columnsDefault)}
            />
          </ReactPlaceholder>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

Resellers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Resellers);
