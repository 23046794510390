import "date-fns";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
// import NewCustomerForm from './NewCustomerForm';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import fetchClient from "../services/axios";
import NewResellerForm from "./NewResellerForm";
import { resellerDetail } from "../api/partner.js";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

class EditResellerWrapperFrom extends React.Component {
  state = {
    customer: {
      company: "",
      name: "",
      surname: "",
      email: "",
      address: "",
      phone: "",
      fax: "",
      website: "",
      ico: "",
      dico: "",
      zip: "",
      note: "",
      id_country: 0,
      city: "",
      country_state: "",
      password: "",
    },
    countries: [],
    isLoading: false,
    error: false,
  };

  componentDidMount = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + resellerDetail + "/" + this.props.match.params.id)
      .then((response) => {
        //console.log(response.data);
        this.setState({
          reseller: response.data.reseller,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  handleSubmit = (e) => {
    if (this.handleValidate()) {
      // reset generated licence
      this.setState({
        isLoading: true,
      });

      // call API
      fetchClient()
        .put(
          process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_EDIT_RESELLER + "/" + this.props.match.params.id,
          {
            reseller: this.state.reseller,
          }
        )
        .then((response) => {
          console.log(response.data);
          // assign generated and returned licence
          this.setState({
            isLoading: false,
          });

          // redirect to customer detail
          this.props.history.push("/reseller/detail/" + this.props.match.params.id);
        })
        .catch((error, response) => {
          console.log(error, response);
          this.setState({
            isLoading: false,
            error: error.message,
          });
        });
    }
  };

  handleChangeNewReseller = (e) => {
    this.setState({
      reseller: {
        ...this.state.reseller,
        [e.target.name]: e.target.value,
      },
      error: false,
    });
  };

  handleValidate = () => {
    let isNewCustomerEmpty = Object.values(this.state.reseller).find(
      (x) => x === null || x === "" || x.length < 1 || x === false
    );
    if (isNewCustomerEmpty !== undefined || this.state.reseller.country === "") isNewCustomerEmpty = true;
    if (isNewCustomerEmpty) {
      this.setState({ error: "You must set all required reseller data." });
      return false;
    } else {
      this.setState({ error: "" });
      return true;
    }
  };

  render() {
    const { classes } = this.props;
    const { reseller, error, isLoading } = this.state;

    return (
      <React.Fragment>
        {error ? (
          <CustomizedSnackbars message={JSON.stringify(error)} variant="error" open={error ? true : false} />
        ) : (
          ""
        )}
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Paper className={classes.root} style={{ padding: "30px 30px 50px" }}>
              <Typography variant="h4" gutterBottom component="h3">
                Edit reseller
              </Typography>
              <NewResellerForm
                {...this.props}
                newReseller={reseller}
                handleLoadReseller={this.handleLoadReseller}
                handleChangeNewCustomer={this.handleChangeNewReseller}
              />
              <Button
                style={{ marginTop: "30px" }}
                disabled={error || isLoading}
                variant="contained"
                color="primary"
                onClick={(e) => this.handleSubmit(e)}
                className={classes.button}
              >
                {isLoading ? "Loading..." : "Save changes"}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

EditResellerWrapperFrom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditResellerWrapperFrom);
