import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0),
    padding: 0,
    fontSize: 10,
    height: 20,
  },
  margin: {
    margin: theme.spacing(2),
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "20%",
    right: -5,
  },
}))(Badge);

const Order = (props) => {
  const classes = props.classes;
  const { item } = props;

  return (
    <Card
      className={classes.card}
      style={{
        marginBottom: "15px",
        border: "none",
        boxShadow: "none",
        borderRadius: 0,
        borderBottom: "1px solid #eee",
      }}
    >
      <CardContent>
        <Typography>Product: {item.title}</Typography>
        <Typography>Date order: {moment(item.date_order).format("DD.MM.YYYY")}</Typography>
        <Typography>Status: {item.state}</Typography>
        <Typography>Users: {item.count}</Typography>
        <Typography>
          Price: {parseFloat(item.price_final_end_user_origin).toFixed(2)} {item.currency}
        </Typography>
        <Typography>Valid from : {moment(item.valid_from).format("DD.MM.YYYY")}</Typography>
        <Typography>Valid to : {moment(item.valid_to).format("DD.MM.YYYY")}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" component={Link} to={`/order/detail/${item.id}`}>
          Detail
        </Button>
      </CardActions>
    </Card>
  );
};

const NFR = (props) => {
  const { item } = props;

  return (
    <Card
      style={{
        marginBottom: "15px",
        border: "none",
        boxShadow: "none",
        borderRadius: 0,
        borderBottom: "1px solid #eee",
      }}
    >
      <CardContent>
        <Typography variant="h6" component="h2" color={"primary"}>
          {item.name}
        </Typography>
        <Typography variant="body2" component="p">
          Customer: {item.customerDetail.firm || " - "}
        </Typography>
        <Typography variant="body2" component="p">
          Product: {item.title || " - "}
        </Typography>
        <Typography variant="body2" component="p">
          Status: {item.state}
        </Typography>
        <Typography variant="body2" component="p">
          Users: {item.count || " - "}
        </Typography>
        <Typography variant="body2" component="p">
          Licence: {item.licences.map((l) => l.serial).join(", ")}
        </Typography>
        <Typography variant="body2" component="p">
          Created: {moment(item.date_creation).format("DD.MM.YYYY")}
        </Typography>
        <Typography variant="body2" component="p">
          Valid from: {moment(item.valid_from).format("DD.MM.YYYY")}
        </Typography>
        <Typography variant="body2" component="p">
          Valid to: {moment(item.valid_to).format("DD.MM.YYYY")}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Customer = (props) => {
  const classes = props.classes;
  const { item } = props;
  return (
    <Card
      className={classes.card}
      style={{
        marginBottom: "15px",
        border: "none",
        boxShadow: "none",
        borderRadius: 0,
        borderBottom: "1px solid #eee",
      }}
    >
      <CardContent>
        <Typography variant="h6" component="h2" color={"primary"}>
          {item.company_name}
        </Typography>
        <Typography variant="body2" component="p">
          created {moment(item.doa).format("DD.MM.YYYY")}
        </Typography>
      </CardContent>
      <CardActions>
        <Button component={Link} to={`/customer/detail/${item.id}`} size="small" color={"primary"}>
          Detail
        </Button>
      </CardActions>
    </Card>
  );
};

class ResellerDetailSidePanel extends React.Component {
  getLocalStorageStatsKey = () => "resellerStatsLen" + this.props.resellerId;
  state = {
    tab: 0,
  };

  handleTabChange = (e, newValue) => {
    this.setState({ tab: newValue });
  };

  render() {
    const { tab } = this.state;
    const { orders, nfr, customers } = this.props.reseller;

    return (
      <React.Fragment>
        <Paper square>
          <Tabs value={tab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
            <Tab
              label={
                <StyledBadge badgeContent={customers ? customers.length : 0} color="primary">
                  Customers
                </StyledBadge>
              }
            />
            <Tab
              label={
                <StyledBadge badgeContent={orders ? orders.length : 0} color="error">
                  Orders
                </StyledBadge>
              }
            />
            <Tab
              label={
                <StyledBadge badgeContent={nfr ? nfr.length : 0} color="primary">
                  NFR Licences
                </StyledBadge>
              }
            />
          </Tabs>
        </Paper>

        {tab === 0 && (
          <Paper style={{ padding: 6 * 3 }}>
            {customers && customers.map((item) => <Customer key={item.id} item={item} {...this.props} />)}
          </Paper>
        )}

        {tab === 1 && (
          <Paper style={{ padding: 6 * 3 }}>
            {orders && orders.map((item) => <Order key={item.id} item={item} {...this.props} />)}
          </Paper>
        )}

        {tab === 2 && (
          <Paper style={{ padding: 6 * 3 }}>
            {nfr && nfr.map((item) => <NFR key={item.id} item={item} {...this.props} />)}
          </Paper>
        )}

        {/* {tab === 2 && <Nfr resellerId={reseller.id} />} */}
      </React.Fragment>
    );
  }
}

ResellerDetailSidePanel.propTypes = {
  classes: PropTypes.object.isRequired,
  resellerId: PropTypes.number.isRequired,
  resellerDetail: PropTypes.object,
};

export default withStyles(styles)(ResellerDetailSidePanel);
