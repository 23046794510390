import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { connect } from "react-redux";
import { Select } from "../../ui/Input";
import CustomizedSnackbars from "../CustomizedSnackbars.js";
import fetchClient from "../../services/axios.js";
import { loginUser } from "../../services/auth.js";


const styles = (theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
});

class Settings extends React.Component {
  state = {
    error: null,
    isLoading: false,
    success: null,
  };

  handleChange = (e) => {
    const newUser = this.props.profiles?.find(profile =>
      profile.companyDetail.id === e.target.value);

    fetchClient()
      .post(
        process.env.REACT_APP_API_URL +
        "/profilechange",
        { id: newUser.id }
      )
      .then((response) => {
        const apiToken = localStorage.getItem("apiToken");
        loginUser(response.data.user, apiToken)
        this.props.setUser(response.data.user)
        this.setState({ success: "Profile changed successfully." });
      })
      .catch((error) => {
        console.error("Change profile error: ", error);
        this.setState({ error: "Change profile error." });
      });
  };

  render() {
    const {
      user,
      profiles
    } = this.props;

    const { error, success } = this.state;

    let options = [];

    if (profiles?.length > 1) {
      options = profiles.map(profile => ({
        id: profile.companyDetail.id,
        label: profile.companyDetail.company_name
      }))
    }

    return (
      <>
        {error && (
          <CustomizedSnackbars
            message={JSON.stringify(error)}
            open={!!error}
            variant="error"
          />
        )}
        {success && (
          <CustomizedSnackbars
            message={success}
            open={!!success}
            variant="success"
            autoHideDuration={2000}
          />
        )}

        {user?.companyDetail?.id &&
          profiles?.length > 1 &&
          <Select
            id="account"
            name="account"
            label="Select account"
            required
            value={user?.companyDetail?.id}
            onChange={(e) => this.handleChange(e)}
            options={options}
            style={{ maxWidth: "300px", marginBottom: "20px" }}
          />}

        <Grid container>
          <Grid item md={8} lg={8} xl={6}>
            <Alert severity="info">
              E-mail: <strong>{user?.email}</strong>
              <br />
              Company: <strong>{user?.companyDetail?.company_name}</strong>
            </Alert>
          </Grid>
        </Grid>
      </>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: user => dispatch({ type: 'user/SET_USER', user })
  }
}

const mapStateToProps = state => ({
  isLoading: state.settings.isLoading,
  user: state.user,
  profiles: state.profiles
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Settings));
