import React, { useState } from "react";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { isInteger } from "lodash";
import { checkDecimal8, toDecimal } from "../../../services/utils";
import Typography from "@material-ui/core/Typography";
import { Number } from "../../../ui/Input";
import { Spinner } from "../../../ui/Spinner";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function OrderEditForm(props) {
  const [errorCount, setErrorCount] = useState(null);
  const [errorDiscount, setErrorDiscount] = useState(null);
  const [errorNote, setErrorNote] = useState(null);
  const [errorValidFrom, setErrorValidFrom] = useState(null);
  const { isLoading, user, order, currency, onChange } = props;
  const { basket } = order;

  const discount = basket[1]?.discount?.value;
  const validFrom = basket[1]?.validFrom?.value;
  const price = basket[1]?.price;
  // const totalPrice = basket[1]?.totalPrice;
  const count = basket[1]?.count?.value;
  const note = basket[1]?.note?.value;
  const dateEnd = basket[0]?.dateEnd?.value;
  const maxDiscount = user?.max_discount && parseInt(user?.max_discount);

  const handleChange = (key, value) => {
    const isError = (value) => {
      if (key === "count") {
        if (value < 1) {
          setErrorCount("Users count should be at least 1.");
          return true;
        } else if (!isInteger(toDecimal(value, 2))) {
          setErrorCount("Users count must be an integer.");
          return true;
        }
        // else if (parseInt(value) > (user?.max_count && parseInt(user?.max_product_count))) {
        //   setErrorCount(`Max users count is ${user?.max_product_count}.`);
        //   return true;
        // }
        else {
          setErrorCount(null);
          return false;
        }
      } else if (key === "discount") {
        if (value > 100) {
          setErrorDiscount("Discount should be at most 100.");
          return true;
        } else if (!checkDecimal8(value)) {
          setErrorDiscount("Discount should be at most 8 decimal places.");
          return true;
        } else if (!note && toDecimal(value, 10) > maxDiscount) {
          setErrorNote("Note is required when discount is higher than user's max discount.");
          setErrorDiscount(null);
          return true;
        } else if (toDecimal(value, 10) <= maxDiscount || !value) {
          setErrorNote(null);
          return false;
        } else {
          setErrorDiscount(null);
          return false;
        }
      } else if (key === "note") {
        if (!value && discount > maxDiscount) {
          setErrorNote("Note is required when discount is higher than user's max discount.");
          return true;
        } else {
          setErrorNote(null);
          return false;
        }
      }
    };

    onChange(key, value, isError(value));
  };

  return (
    <Box>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>New Adjustments - Order Upsell</Typography>
      </Box>
      <Box style={{ marginTop: 20, marginBottom: 20 }}>
        <Box style={{ display: "flex", marginRight: 40 }}>
          <Typography style={{ marginRight: 10 }}>End User Price:</Typography>
          {!isLoading ? (
            <>
              <Typography style={{ marginRight: 10 }}>{price ? Math.round(price) : 0}</Typography>
              <Typography
                style={{ marginRight: 10, fontSize: 12, alignContent: "flex-end", marginBottom: 2 }}
              >
                {currency || ",-"}
              </Typography>
            </>
          ) : (
            <Spinner size={15} />
          )}
        </Box>
        {/* <Box style={{ display: "flex" }}>
          <Typography style={{ marginRight: 10 }}>Total End User Price:</Typography>
          {!isLoading ? (
            <>
              <Typography style={{ marginRight: 10 }}>{totalPrice || 0}</Typography>
              <Typography style={{ marginRight: 10, fontSize: 12, alignContent: "flex-end", marginBottom: 2 }}>
                {currency || ",-"}
              </Typography>
            </>
          ) : (
            <Spinner size={15} style={{ marginTop: 5 }} />
          )}
        </Box> */}
      </Box>
      <Box style={{ display: "flex", width: "100%", marginBottom: 20 }}>
        <TextField
          type="number"
          error={errorCount}
          helperText={errorCount}
          label="Add Users"
          value={count || ""}
          onChange={(e) => handleChange("count", e.target.value)}
          inputProps={{ min: "1", step: "1" }}
          style={{ marginRight: "20px" }}
        />
        <Number
          error={errorDiscount}
          helperText={errorDiscount}
          label="Discount"
          value={discount ? toDecimal(discount, 10) : ""}
          onChange={(val) => handleChange("discount", val)}
        />
      </Box>
      <Box style={{ width: 160 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            error={errorValidFrom}
            helperText={errorValidFrom}
            format="MMMM d'th', yyyy"
            required
            label="Valid from"
            value={validFrom}
            fullWidth
            onChange={(val) => handleChange("validFrom", new Date(val).toISOString())}
            minDate={new Date()}
            maxDate={dateEnd}
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box style={{ display: "flex", width: "100%" }}>
        <TextField
          error={errorNote}
          helperText={errorNote}
          label="Note"
          multiline
          rows={2}
          value={note || ""}
          onChange={(e) => handleChange("note", e.target.value)}
          style={{ marginRight: "20px", width: "100%" }}
        />
      </Box>
    </Box>
  );
}
