import { withStyles, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React from "react";
import fetchClient from "../services/axios";
import CustomizedSnackbars from "./CustomizedSnackbars";
// import axios from "axios";
import { getLocalValue } from "../services/storage";
import { Select } from "../ui/Input";
import { Select as MuiSelect } from "@material-ui/core";
import { resellersList } from "../api/partner";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

class NewCustomerForm extends React.Component {
  state = {
    isLoading: false,
    error: false,
    countries: [],
    industries: [],
    resellers: [],
  };

  componentDidMount = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + process.env.REACT_APP_INDUSTRIES_ENDPOINT)
      .then((response) => {
        this.setState({
          industries: response.data.industries,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    fetchClient()
      .get(process.env.REACT_APP_API_URL + process.env.REACT_APP_COUNTRIES_ENDPOINT)
      .then((response) => {
        this.setState({
          countries: response.data.countries,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    if (!this.props.resellers) {
      fetchClient()
        .get(process.env.REACT_APP_API_URL + resellersList)
        .then((response) => {
          this.setState({
            resellers: response.data.resellers,
          });
        })
        .catch((error) => {
          this.setState({ error, isLoading: false });
        });
    } else {
      this.setState({
        resellers: this.props.resellers,
      });
    }
  };

  render() {
    const { classes, newCustomer, handleChangeNewCustomer, withNote } = this.props;
    const { countries, industries, resellers, error } = this.state;

    return (
      <React.Fragment>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="firmName"
                name="firmName"
                label="Company"
                fullWidth
                autoComplete="company"
                value={newCustomer.firmName}
                onChange={(e) => handleChangeNewCustomer(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="tin"
                name="tin"
                label="VAT ID / Tax ID"
                fullWidth
                autoComplete="tin"
                value={newCustomer.tin}
                onChange={(e) => handleChangeNewCustomer(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="contactName"
                name="contactName"
                label="Customer Contact Name"
                fullWidth
                autoComplete="name"
                value={newCustomer.contactName}
                onChange={(e) => handleChangeNewCustomer(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="contactEmail"
                name="contactEmail"
                label="Customer e-mail"
                fullWidth
                autoComplete="email"
                value={newCustomer.contactEmail}
                onChange={(e) => handleChangeNewCustomer(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="street"
                name="street"
                label="Street"
                fullWidth
                autoComplete="street"
                value={newCustomer.street}
                onChange={(e) => handleChangeNewCustomer(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                fullWidth
                autoComplete="city"
                value={newCustomer.city}
                onChange={(e) => handleChangeNewCustomer(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {countries && countries.length > 0 && (
                <Select
                  required
                  id="country"
                  name="country"
                  label="Country"
                  value={newCustomer.country}
                  onChange={(e) => handleChangeNewCustomer(e)}
                  options={countries}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="zip"
                name="zip"
                label="Zip / Postal code"
                fullWidth
                autoComplete="zip"
                value={newCustomer.zip}
                onChange={(e) => handleChangeNewCustomer(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                id="industry"
                name="industry"
                label="Industry"
                required
                value={newCustomer.industry}
                options={industries}
                onChange={(e) => handleChangeNewCustomer(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {resellers && resellers.length > 0 && (
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="reseller">Reseller</InputLabel>
                  <MuiSelect
                    id="reseller"
                    name="reseller"
                    fullWidth
                    autoComplete="reseller"
                    value={newCustomer.reseller}
                    onChange={(e) => handleChangeNewCustomer(e)}
                  >
                    {(getLocalValue("resellerId") === "null" ||
                      !getLocalValue("resellerId") ||
                      getLocalValue("resellerId") === "0") && <MenuItem value="">None</MenuItem>}
                    {resellers.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.company}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                required
                id="web"
                name="web"
                label="Company website"
                fullWidth
                autoComplete="web"
                value={newCustomer.web ?? ""}
                onChange={(e) => handleChangeNewCustomer(e)}
              />
            </Grid>
            {withNote && (
              <Grid item xs={12} md={12}>
                <TextField
                  id="note"
                  label="Note"
                  name="note"
                  multiline
                  rows="3"
                  fullWidth
                  onChange={(e) => handleChangeNewCustomer(e)}
                  value={newCustomer.note ?? ""}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

NewCustomerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  withNote: PropTypes.bool,
};

export default withStyles(styles)(NewCustomerForm);
