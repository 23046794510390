import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import React from "react";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function DealClosedStageInfo() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <ErrorIcon color="error" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} />
      <Popover
        disableScrollLock={true}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>
          If this deal was closed by accident or by automation due to inactivity, <br />
          and you need to reopen it, please contact your Channel Account Manager <br />
          via the note field and mention the reasons for reopening. In all other cases, <br />a new deal must be created
          to ensure accurate tracking and management of opportunities.
        </Typography>
      </Popover>
    </div>
  );
}
