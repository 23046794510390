import { Box, Button, Step, StepContent, StepLabel, Stepper } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import React, { Component } from "react";
import { connect } from "react-redux";
import { newDeal } from "../../../api/deal";
import fetchClient, { postClient } from "../../../services/axios";
import CustomizedSnackbars from "../../CustomizedSnackbars";
import DealNewCustomerForm from "./DealNewCustomerForm";
// import TrialNewLicenceInfo from "./TrialNewLicenceInfo";
import DealNewProductForm from "./DealNewProductForm";
import { Link } from "react-router-dom";
import { customerNew } from "../../../api/customer";
import DealNewProductFormSummary from "./DealNewProductFormSummary";
import DealNewInfo from "./DealNewInfo";

class DealNew extends Component {
  state = {
    activeStep: 0,
    showCustomerForm: false,
    error: false,
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });

    if (this.props.customer && this.props.customer.contactEmail?.value) {
      this.setState({ showCustomerForm: true });
    } else {
      this.setState({ showCustomerForm: false });
    }
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  render() {
    const { validateBasket, validateDealCustomer, validateCustomer, customer, deal } = this.props;
    const { error, isLoading } = this.state;

    const steps = [
      {
        label: "Select or create customer and reseller",
        component: <DealNewCustomerForm {...{ showCustomerForm: this.state.showCustomerForm }} />,
      },
      {
        label: "Deal details",
        component: <DealNewProductForm />,
      },
      {
        label: "Deal summary",
        component: <DealNewProductFormSummary />,
      },
      {
        label: "Deal information",
        component: <DealNewInfo />,
      },
    ];

    const handleNext = () => {
      if (this.state.activeStep === 0) {
        if (customer) {
          validateCustomer(customer);
          const error = Object.values(customer).find((customer) => customer.error);
          if (!error) {
            this.setState({ activeStep: this.state.activeStep + 1 });
          }
        } else {
          if (deal?.isDealCustomerValid) {
            this.setState({ activeStep: this.state.activeStep + 1 });
          } else {
            validateDealCustomer(null);
          }
        }
      } else if (this.state.activeStep === 1) {
        validateBasket(deal.basket);
        const errorBasket = Object.values(deal?.basket).find((item) => item?.error);
        if (!errorBasket) {
          this.setState({ activeStep: this.state.activeStep + 1 });
        }
      } else if (this.state.activeStep === 2) {
        this.setState({
          isLoading: true,
        });
        const saveNewCustomer = async () => {
          let resCustomer = null;
          if (customer) {
            const formatCustomer = Object.keys(customer).reduce((acc, key) => {
              if (customer[key].value) {
                acc[key] = customer[key].value;
              }
              return acc;
            }, {});

            let additionalState = {
              createIn: "PARTNER_PORTAL",
              createAt: format(new Date(new Date().toUTCString()), "yyyy-MM-dd kk:mm:ss"),
              createReason: "DEAL",
            };

            // call api to create a new customer
            resCustomer = await fetchClient()
              .post(process.env.REACT_APP_API_URL + customerNew, {
                customer: { ...formatCustomer, ...additionalState },
              })
              .catch((error) => {
                this.setState({
                  isLoading: false,
                  error: error.response ? error.response.data.errors.message : error,
                });
              });
          }

          if (resCustomer || deal.customer?.id) {
            const _deal = this.props.deal;
            const basket = this.props.deal.basket;
            const customerId = resCustomer ? resCustomer?.data?.customerId : _deal.customer.id;
            const resellerId = resCustomer?.data?.resellerDetail?.id
              ? resCustomer.data.resellerDetail?.id
              : _deal?.reseller?.id || null;

            const deal = {
              seats: basket.seats.value,
              product: basket.product.value,
              date_closed: format(new Date(basket.date_closed.value), "yyyy-MM-dd"),
              validity: basket.validity.value,
              note: basket.description.value,
              end_user_price: basket.end_user_price.value,
              intended_use_case: basket.intended_use_case.value,
              name: basket.name.value,
              status:
                process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT !== "production"
                  ? "16257674"
                  : "6327935",
              description: basket.description.value,
            };

            postClient(process.env.REACT_APP_API_URL + newDeal, {
              deal,
              customer: customerId,
              resellerId: resellerId,
            })
              .then((res) => {
                if (res.error) {
                  this.setState({
                    generatedLicence: {},
                    isLoading: false,
                    error: res.error,
                  });
                } else if (res.dealId) {
                  this.setState({
                    activeStep: this.state.activeStep + 1,
                    isLoading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  error: error.response ? error.response.data?.errors?.message : null,
                  isLoading: false,
                });
              });
          }
        };
        saveNewCustomer();
      }
    };

    return (
      <Card
        style={{
          margin: "20px",
        }}
      >
        <CustomizedSnackbars open={Boolean(error)} message={this.state.error || "Saving Error"} variant="error" />
        <CardHeader title="Create new deal" />
        <CardContent>
          <Stepper activeStep={this.state.activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel
                  optional={
                    index === 0 ? (
                      <>
                        {deal?.customer ? (
                          <Typography variant="body2">{deal?.customer?.firm}</Typography>
                        ) : customer?.firmName ? (
                          <Typography variant="body2">{customer.firmName.value}</Typography>
                        ) : null}
                        {deal?.reseller ? (
                          <Typography variant="body2">{`Reseller: ${deal.reseller.company}`}</Typography>
                        ) : customer?.reseller?.value ? (
                          <Typography variant="body2">{`Reseller: ${customer.reseller.company}`}</Typography>
                        ) : null}
                      </>
                    ) : null
                  }
                >
                  <>{step.label}</>
                </StepLabel>
                <StepContent>
                  {step.component}
                  <Box sx={{ mb: 2 }}>
                    {index === steps.length - 1 ? (
                      <Button variant="contained" component={Link} to="/deals-hubspot">
                        Go to deals
                      </Button>
                    ) : (
                      <Button variant="contained" onClick={handleNext}>
                        {index === steps.length - 2 ? (this.state.isLoading ? "Saving..." : "Save") : "Continue"}
                      </Button>
                    )}
                    <Button disabled={index === 0} onClick={this.handleBack}>
                      Back
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    validateBasket: (basket) => dispatch({ type: "deal/VALIDATE_DEAL_BASKET", basket }),
    validateCustomer: (customer) => dispatch({ type: "customer/VALIDATE_CUSTOMER", customer }),
    validateDealCustomer: (customer) => dispatch({ type: "deal/VALIDATE_DEAL_CUSTOMER", customer }),
    // setGeneratedTrial: trial => dispatch({ type: 'deal/SET_GENERATED_TRIAL', trial }),
  };
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  deal: state.deal,
  customer: state.customer,
  // utils: state.utils,
});

export default connect(mapStateToProps, mapDispatchToProps)(DealNew);
