import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import React from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { subscription } from "../../api/subscription.js";
import Can from "../../services/acl.js";
import { postClient } from "../../services/axios.js";
import CustomizedSnackbars from "../CustomizedSnackbars.js";
import { columnsDefault, options } from "./tableSettings.js";

const styles = (theme) => ({
  tableContainer: {
    height: 320,
  },
});

class Orders extends React.Component {
  state = {
    orders: [],
    error: null,
    isLoading: true,
    status: false,
    user: null,
  };

  handleStoredTableOptions = (defaultOptions) => {
    return {
      ...defaultOptions,
      ...JSON.parse(localStorage.getItem("Orders")),
    };
  };

  handleStoredColsOptions = (columnsDefault) => {
    var options = JSON.parse(localStorage.getItem("Orders"));
    if (options && options.columnSort) {
      columnsDefault.forEach((i) => {
        if (i.name === options.columnSort.column) {
          if (options.columnSort.direction === "ascending") {
            i.options.sortDirection = "asc";
          }
          if (options.columnSort.direction === "descending") {
            i.options.sortDirection = "desc";
          }
        }
      });
    }
    return columnsDefault;
  };

  componentDidMount = () => {
    postClient(process.env.REACT_APP_API_URL + subscription, { isOrdersFilter: true })
      .then((response) => {
        return response.data.map((o) => {
          let renewalDate;
          let isRenewal = 0;
          let isRenewed = 0;
          let s = {};

          try {
            const title = o.title.toLowerCase();
            if (title.includes("perpetual")) {
              renewalDate = moment(o.valid_from).add("years", 1).format("DD.MM.YYYY");
            } else if (
              title.includes("service") ||
              title.includes("implement") ||
              title.includes("proof") ||
              title.includes("nástroj")
            ) {
              renewalDate = moment(o.valid_to).format("DD.MM.YYYY");
            } else {
              renewalDate = moment(o.valid_to).format("DD.MM.YYYY");
            }

            if (o.related && o.related.length > 0) {
              for (let i = 0; i < o.related.length; i++) {
                if (o.related[i].renewal && o.related[i].child_id === o.id) {
                  isRenewal = 1;
                }
              }
            }

            if (o.parent && o.parent.length > 0) {
              for (let i = 0; i < o.parent.length; i++) {
                if (o.parent[i].renewal && o.parent[i].parent_id === o.id) {
                  isRenewed = 1;
                }
              }
            }

            s = {
              id: `${o.id}`,
              created: o.date_order ? `${moment(o.date_order).format("DD.MM.YYYY")}` : " - ",
              state: o.state,
              customer: o.customerDetail.firm ?? "",
              products: o.title,
              edit: o.id,
              companyId: o.customerDetail.id,
              endUserPrice:
                o.for_invoice_price_final_end_user_origin &&
                `${parseFloat(o.for_invoice_price_final_end_user_origin).toFixed(2)}`,
              seats: o.for_invoice_count,
              renewalDate,
              isRenewal: o.renewal,
              isRenewed: o.renewed,
              currency: o.currency,
            };
          } catch (e) {
            console.log(e);
          }
          return s;
        });
      })
      .then((orders) => {
        this.setState({
          orders: orders,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  render() {
    const { classes, setNewCustomer, validateOrderCustomer, cleanOrder } = this.props;
    const { error, orders } = this.state;

    return (
      <div>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}

        <Can resource={["Orders_create", "Orders_basket"]}>
          <div style={{ marginBottom: 15 }}>
            <Can resource="Orders_basket">
              <Button
                component={Link}
                className={classes.button}
                to="/basket/create"
                variant="contained"
                color="primary"
                onClick={() => {
                  setNewCustomer(null);
                  validateOrderCustomer("setNull");
                  cleanOrder();
                }}
              >
                Create new order
              </Button>
            </Can>
          </div>
        </Can>

        <div className={classes.tableContainer}>
          <ReactPlaceholder
            type="text"
            ready={!this.state.isLoading}
            rows={20}
            color="#E0E0E0"
            showLoadingAnimation={true}
          >
            <MUIDataTable
              title={
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <ShoppingCartIcon />
                  </Grid>
                  <Grid item>
                    <Typography>Orders</Typography>
                  </Grid>
                </Grid>
              }
              data={orders}
              options={this.handleStoredTableOptions(options())}
              columns={this.handleStoredColsOptions(columnsDefault({ user: this.props.user }))}
            />
          </ReactPlaceholder>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    cleanOrder: () => dispatch({ type: "order/CLEAN" }),
    setNewCustomer: (customer) => dispatch({ type: "customer/SET_NEW_CUSTOMER", customer }),
    validateOrderCustomer: (customer) => dispatch({ type: "order/VALIDATE_ORDER_CUSTOMER", customer }),
  };
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Orders);
