import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { customerDetail } from "../api/customer";
import Can from "../services/acl";
import fetchClient from "../services/axios";
import CustomerDetailSidePanel from "./CustomerDetailSidePanel";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import EditCustomerDialog from "./EditCustomerDialog";
import { createOrder } from "./Orders/utils";
import { createTrial } from "./Trials/utils";

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0),
    padding: 0,
    fontSize: 10,
    height: 20,
  },
  margin: {
    margin: theme.spacing(2),
  },
});

class CustomerDetail extends React.Component {
  state = {
    customer: {},
    stats: [],
    isLoading: true,
    isLoadingForensics: false,
    isLoadingStats: true,
    error: false,
    status: false,
    tab: 0,
    notFound: false,
    showCustomerEdit: false,
    dialogOpen: false,
    success: false,
  };

  componentWillReceiveProps = (props) => {
    if (this.props.match.params.id !== props.match.params.id) {
      this.handleLoadCustomer(props.match.params.id);
    }
  };

  componentDidMount = () => {
    this.handleLoadCustomer(this.props.match.params.id);
  };

  handleLoadCustomer = (id) => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + customerDetail + "/" + id)
      .then((response) => {
        if (response.data.customer) {
          this.setState({
            customer: response.data.customer,
            isLoading: false,
            status: response.status,
          });
        } else {
          this.setState({ notFound: true, isLoadingStats: false });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.response ? error.response.data.errors : error,
          isLoading: false,
        });
      });
  };

  /*handleTabCHange = (e, newValue) => {
        this.setState({ tab: newValue });
    };*/

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true,
      success: false,
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handleEditSuccess = (msg) => {
    this.setState({
      success: msg,
    });
  };

  handleCreateOrder = () => {
    const { customer } = this.state;
    this.props.cleanOrder();
    createOrder({ customer, _this: this });
  };

  handleCreateTrial = () => {
    const { customer } = this.state;
    this.props.cleanTrial();
    createTrial({ customer, _this: this });
  };

  render() {
    const { classes } = this.props;
    //const { id } = this.props.match.params;
    const { error, customer, notFound, success, dialogOpen } = this.state;

    return (
      <div>
        <CustomizedSnackbars open={!!error} message={error.message} variant="error" />

        {success && <CustomizedSnackbars message={success} open={success} variant="success" />}
        <Grid container spacing={3}>
          {notFound ? (
            <Grid item md={4} lg={4} xl={3}>
              <Card className={classes.card} style={{ marginBottom: "20px" }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom={true} component="h1">
                    Customer not found
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item md={4} lg={4} xl={3}>
                <Card className={classes.card} style={{ marginBottom: "20px" }}>
                  <CardContent>
                    <Typography variant="h5" gutterBottom={true} component="h1">
                      {customer.company_name}
                    </Typography>
                    <Typography color="primary" variant="caption">
                      Reseller:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {customer.resellerDetail ? (
                        <Link to={`/reseller/detail/${customer.resellerDetail.id}`}>
                          {customer.resellerDetail.company_name}
                        </Link>
                      ) : (
                        "..."
                      )}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      Contact person:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {customer.name} {customer.surname}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      Address:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {customer.address}
                      {customer.city && `, ${customer.city}`}
                      {customer.zip && `, ${customer.zip}`}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      Industry:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {customer.industryValue ? customer.industryValue : customer.industry}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      Firm ID:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {customer.identification_number}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      VAT ID:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {customer.tax_identification_number}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      Created:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {moment(customer.create_at).format("DD.MM.YYYY")}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      GUID:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {customer.guid}
                    </Typography>

                    <Typography color="primary" variant="caption">
                      Contacts:
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {customer.email}
                      <br />
                      {customer.phone}
                      <br />
                      <a href={`//${customer.website}`} target="_blank" rel="noopener noreferrer">
                        {customer.website}
                      </a>
                    </Typography>
                    {/*<a className={classes.link} href={`mailto: ${customer.email}`}>
                                        <IconButton color="primary">
                                            <EmailOutline />
                                        </IconButton>
                                        {customer.email}
                                    </a>*/}
                    <br />
                    <br />

                    {!customer.hubspot_sync_id && (
                      <>
                        <Alert severity="error">
                          Customer has not been fully synchronised in our CRM, some features will be limited. Please
                          contact your account manager!
                        </Alert>
                        <br />
                      </>
                    )}

                    <Grid justify="space-between" container>
                      <Grid item>
                        <Can resource="Trials_create">
                          <Button
                            disabled={!customer.hubspot_sync_id}
                            component={Link}
                            color="default"
                            variant="contained"
                            to={`/licence/create/${customer.id}`}
                            size="small"
                            onClick={this.handleCreateTrial}
                            style={{ marginBottom: 10 }}
                          >
                            Create trial
                          </Button>
                          <br />
                        </Can>
                        <Can resource="Orders_basket">
                          <Button
                            disabled={!customer.hubspot_sync_id}
                            component={Link}
                            color="default"
                            variant="contained"
                            onClick={this.handleCreateOrder}
                            to={`/basket/create`}
                            size="small"
                          >
                            Create order
                          </Button>
                        </Can>
                      </Grid>
                      <Grid item>
                        <Button color="primary" variant="contained" onClick={this.handleDialogOpen} size="small">
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item md={8} lg={8} xl={9}>
                {customer && customer.id && (
                  <CustomerDetailSidePanel customerDetail={customer} customerId={customer.id} />
                )}
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        {customer && customer.id && (
          <EditCustomerDialog
            customer={customer}
            handleLoadCustomer={(id) => this.handleLoadCustomer(id)}
            handleDialogClose={this.handleDialogClose}
            handleEditSuccess={this.handleEditSuccess}
            dialogOpen={dialogOpen}
          />
        )}
      </div>
    );
  }
}

CustomerDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    cleanOrder: () => dispatch({ type: "order/CLEAN" }),
    cleanTrial: () => dispatch({ type: "trial/CLEAN_TRIAL" }),
    setCustomer: (customer) => dispatch({ type: "order/SET_CUSTOMER", customer }),
    setReseller: (reseller) => dispatch({ type: "order/SET_RESELLER", reseller }),
    validateOrderCustomer: (customer) => dispatch({ type: "order/VALIDATE_ORDER_CUSTOMER", customer }),
    setTrialCustomer: (customer) => dispatch({ type: "trial/SET_CUSTOMER_TRIAL", customer }),
    setTrialReseller: (reseller) => dispatch({ type: "trial/SET_RESELLER_TRIAL", reseller }),
    validateTrialCustomer: (customer) => dispatch({ type: "trial/VALIDATE_TRIAL_CUSTOMER", customer }),
    setNewCustomer: (customer) => dispatch({ type: "customer/SET_NEW_CUSTOMER", customer }),
  };
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(CustomerDetail);
