import React from "react";
import TrialMaxCountInfo from "../../components/Basket/Trial/TrialMaxCountInfo";
import {
    CLEAN_TRIAL,
    SET_BASKET_TRIAL,
    SET_CUSTOMER_TRIAL,
    SET_GENERATED_TRIAL,
    SET_NOTE_TRIAL,
    SET_RESELLER_TRIAL,
    SET_TRIAL_SETTINGS,
    SET_TRIAL_TRESHOLD,
    VALIDATE_TRIAL_BASKET,
    VALIDATE_TRIAL_CUSTOMER
} from "../actionTypes";

//return - boolean | null
const validateTrialCustomer = (customer) => {
    if (customer === "setNull") {
        return null
    }

    if (customer) {
        return true
    } else {
        return false
    }
}

const validate = (basket) => {
    return Object.entries(basket).reduce((acc, [key, item]) => {
        if (item?.error !== undefined) {
            if (!item.value && item.required) {
                item.error = "Required field";
            } else if (item.max || item.max === 0) {
                if (item.value && item.value > item.max) {
                    item.error = <div style={{ display: "flex" }}><span style={{ marginRight: 2 }}>Max value is {item.max}.</span> <TrialMaxCountInfo /></div>;
                } else {
                    item.error = false;
                }
            } else {
                item.error = false;
            }
        }
        return { ...acc, [key]: item };

    }, { ...basket })
};

const trial = (state = null, action) => {
    switch (action.type) {
        case "trial/" + CLEAN_TRIAL: {
            return null;
        }
        case "trial/" + SET_TRIAL_TRESHOLD: {
            return {
                ...state,
                orderDealTreshold: action.treshold
            };
        }
        case "trial/" + SET_NOTE_TRIAL: {
            return {
                ...state,
                note: action.note
            };
        }
        case "trial/" + VALIDATE_TRIAL_BASKET: {
            return {
                ...state,
                isBasketValid: validate(action.basket)
            };
        }
        case "trial/" + SET_BASKET_TRIAL: {
            return {
                ...state,
                basket: action.basket
            };
        }
        case "trial/" + VALIDATE_TRIAL_CUSTOMER: {
            return {
                ...state,
                isTrialCustomerValid: validateTrialCustomer(action.customer)
            };
        }
        case "trial/" + SET_TRIAL_SETTINGS: {
            return {
                ...state,
                orderSettings: action.orderSettings
            };
        }
        case "trial/" + SET_CUSTOMER_TRIAL: {
            return {
                ...state,
                customer: action.customer
            };
        }
        case "trial/" + SET_RESELLER_TRIAL: {
            return {
                ...state,
                ...action.reseller
            };
        }
        case "trial/" + SET_GENERATED_TRIAL: {
            return {
                ...state,
                ...action.trial
            };
        }
        default: {
            return state;
        }
    }
};

export default trial; 