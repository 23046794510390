import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PeopleIcon from "@material-ui/icons/People";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import React from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { Link } from "react-router-dom";
import Can from "../services/acl";
import fetchClient from "../services/axios";
import { getLocalValue, setLocalValue } from "../services/storage";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import { customers } from "../api/customer.js";

const columnsDefault = [
  {
    name: "doa",
    label: "Created",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "firm",
    label: "Company",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <Link to={`/customer/detail/${tableMeta.rowData[9]}`}>{value}</Link>;
      } /*,
            customBodyRender: (value, tableMeta, updateValue) => {
                console.log(tableMeta.rowData);
                return (
                    <React.Fragment>
                        <Button
                            component={Link}
                            variant="contained"
                            color="default"
                            to={`/customer/detail/${tableMeta.rowData[0]}`}>
                            {value}
                        </Button>
                    </React.Fragment>
                );
            }*/,
    },
  },
  {
    name: "country",
    label: "Country",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "guid",
    label: "GUID",
    options: {
      filter: true,
      sort: false,
      display: false,
      download: false,
    },
  },
  {
    name: "trialSeats",
    label: "Trial seats",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "trialUntil",
    label: "Trial until",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "purchasedSeats",
    label: "Purchased seats",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "licenseUntil",
    label: "License until",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return value && moment(value, "DD.MM.YYYY") >= moment("2100-01-01") ? "Perpetual" : value;
      },
    },
  },
  {
    name: "parent",
    label: "Parent",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      download: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <IconButton component={Link} size={"small"} variant="contained" to={`/customer/detail/${value}`}>
            <KeyboardArrowRight />
          </IconButton>
        );
      },
      customHeadRender: (columnMeta, handleToggleColumn) => {
        return (
          <th
            key={`th_key_${columnMeta.id}`}
            className="MuiTableCell-root MUIDataTableHeadCell-root-348 MUIDataTableHeadCell-fixedHeader-349 MuiTableCell-head"
            scope="col"
          >
            <div className="MUIDataTableHeadCell-data-352"></div>
          </th>
        );
      },
    },
  },
];

const styles = (theme) => ({
  tableContainer: {
    minHeight: 320,
  },
});

class Customers extends React.Component {
  state = {
    customers: [],
    error: null,
    isLoading: true,
    status: false,
  };

  options = {
    filter: true,
    filterType: "dropdown",
    responsive: "stacked",
    print: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
    onChangeRowsPerPage: (rowsPerPage) => {
      this.handleStoreState({
        rowsPerPage,
      });
    },
    onChangePage: (page) => {
      this.handleStoreState({
        page,
      });
    },
    onSearchChange: (searchText) => {
      this.handleStoreState({
        searchText,
      });
    },
    onColumnSortChange: (column, direction) => {
      this.handleStoreState({
        columnSort: {
          column,
          direction,
        },
      });
    },
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        let res = 1;
        if (colIndex === 0 || colIndex === 5 || colIndex === 7) {
          // created, licence until
          if (a.data[colIndex] && b.data[colIndex]) {
            res = moment(a.data[colIndex], "DD.MM.YYYY") < moment(b.data[colIndex], "DD.MM.YYYY") ? -1 : 1;
          } else if (a.data[colIndex]) {
            res = 1;
          } else {
            res = -1;
          }
        } else if (colIndex === 4 || colIndex === 6) {
          // seats, renewed
          res = parseInt(a.data[colIndex]) > parseInt(b.data[colIndex]) ? -1 : 1;
        } else {
          // others
          res = a.data[colIndex].localeCompare(b.data[colIndex]) === -1 ? -1 : 1;
        }
        return res * (order === "desc" ? 1 : -1);
      });
    },
  };

  handleStoredTableOptions = (defaultOptions) => {
    return {
      ...defaultOptions,
      ...JSON.parse(getLocalValue("Customers")),
    };
  };

  handleStoredColsOptions = (columnsDefault) => {
    var options = JSON.parse(getLocalValue("Customers"));
    if (options && options.columnSort) {
      columnsDefault.forEach((i) => {
        if (i.name === options.columnSort.column) {
          if (options.columnSort.direction === "ascending") {
            i.options.sortDirection = "asc";
          }
          if (options.columnSort.direction === "descending") {
            i.options.sortDirection = "desc";
          }
        }
      });
    }
    return columnsDefault;
  };

  handleStoreState = (data) => {
    setLocalValue(
      "Customers",
      JSON.stringify({
        ...JSON.parse(getLocalValue("Customers")),
        ...data,
      })
    );
  };

  componentDidMount = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + customers)
      .then((response) => {
        return response.data.customers.map((customer) => {
          return {
            id: customer.id,
            doa: moment(customer.doa).format("DD.MM.YYYY"),
            firm: customer.firm,
            country: customer.countryDetail ? customer.countryDetail.name : "",
            guid: customer.guid,
            trialSeats: customer.trial_seats ? customer.trial_seats : 0,
            purchasedSeats: customer.purchased_seats ? customer.purchased_seats : 0,
            parent: customer.parent ? customer.parent.company_name : null,
            trialUntil: customer.max_trial_valid_to ? moment(customer.max_trial_valid_to).format("DD.MM.YYYY") : false,
            licenseUntil: customer.max_licence_until ? moment(customer.max_licence_until).format("DD.MM.YYYY") : false,
          };
        });
      })
      .then((customers) => {
        this.setState({
          customers,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  render() {
    const { classes } = this.props;
    const { error, customers } = this.state;

    return (
      <div>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}
        <Can resource="Customers_create">
          <Button
            component={Link}
            className={classes.button}
            to="/customer/create"
            variant="contained"
            color="primary"
            style={{ marginBottom: 15 }}
          >
            Add new customer
          </Button>
        </Can>
        <div className={classes.tableContainer}>
          <ReactPlaceholder
            type="text"
            ready={!this.state.isLoading}
            rows={20}
            color="#E0E0E0"
            showLoadingAnimation={true}
          >
            <MUIDataTable
              title={
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <PeopleIcon />
                  </Grid>
                  <Grid item>
                    <Typography>Customers</Typography>
                  </Grid>
                </Grid>
              }
              data={customers}
              options={this.handleStoredTableOptions(this.options)}
              columns={this.handleStoredColsOptions(columnsDefault)}
            />
          </ReactPlaceholder>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

Customers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Customers);
