import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { connect } from "react-redux";
import fetchClient from "../../services/axios";
import { Select } from "../../ui/Input";
import CustomizedSnackbars from "../CustomizedSnackbars";
import { resellersList } from "../../api/partner";

const initCustomer = {
  firmName: { value: "", required: true, error: false },
  tin: { value: "", required: false, error: false },
  contactName: { value: "", required: true, error: false },
  contactEmail: { value: "", required: true, error: false },
  street: { value: "", required: false, error: false },
  city: { value: "", required: true, error: false },
  country: { value: "", required: true, error: false },
  zip: { value: "", required: false, error: false },
  industry: { value: "", required: true, error: false },
  reseller: { value: "", required: false, error: false },
  web: { value: "", required: true, error: false },
  note: { value: "", required: false, error: false },
};

class CustomerNew extends React.Component {
  state = {
    isLoading: false,
    error: false,
    countries: [],
    industries: [],
    resellers: [],
  };

  componentDidMount = () => {
    if (!this.props.customer) {
      this.props.setNewCustomer(initCustomer);
    }

    fetchClient()
      .get(process.env.REACT_APP_API_URL + process.env.REACT_APP_INDUSTRIES_ENDPOINT)
      .then((response) => {
        this.setState({
          industries: response.data.industries,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    fetchClient()
      .get(process.env.REACT_APP_API_URL + process.env.REACT_APP_COUNTRIES_ENDPOINT)
      .then((response) => {
        this.setState({
          countries: response.data.countries,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    if (!this.props.resellers) {
      fetchClient()
        .get(process.env.REACT_APP_API_URL + resellersList)
        .then((response) => {
          this.setState({
            resellers: response.data.resellers,
          });
        })
        .catch((error) => {
          this.setState({ error, isLoading: false });
        });
    } else {
      this.setState({
        resellers: this.props.resellers,
      });
    }
  };

  render() {
    const { customer, setNewCustomer, withNote } = this.props;

    const handleCustomer = (e) => {
      setNewCustomer({
        [e.target.name]: { ...customer[e.target.name], value: e.target.value, error: false },
      });
    };
    const setReseller = (val) => {
      setNewCustomer({
        ["reseller"]: {
          ...customer["reseller"],
          value: val.id,
          company: val.company,
          error: false,
        },
      });
    };

    const { countries, industries, resellers, error } = this.state;

    return (
      <React.Fragment>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required={customer?.firmName?.required}
                error={customer?.firmName?.error}
                id="firmName"
                name="firmName"
                label="Company"
                fullWidth
                autoComplete="company"
                value={customer?.firmName?.value}
                onChange={(e) => handleCustomer(e)}
                helperText={customer?.firmName?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required={customer?.tin?.required}
                error={customer?.tin?.error}
                id="tin"
                name="tin"
                label="VAT ID / Tax ID"
                fullWidth
                autoComplete="tin"
                value={customer?.tin?.value}
                onChange={(e) => handleCustomer(e)}
                helperText={customer?.tin?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required={customer?.contactName?.required}
                error={customer?.contactName?.error}
                id="contactName"
                name="contactName"
                label="Customer contact name"
                fullWidth
                autoComplete="name"
                value={customer?.contactName?.value}
                onChange={(e) => handleCustomer(e)}
                helperText={customer?.contactName?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required={customer?.contactEmail?.required}
                error={customer?.contactEmail?.error}
                id="contactEmail"
                name="contactEmail"
                label="Customer e-mail"
                fullWidth
                autoComplete="email"
                value={customer?.contactEmail?.value}
                onChange={(e) => handleCustomer(e)}
                helperText={customer?.contactEmail?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required={customer?.street?.required}
                error={customer?.street?.error}
                id="street"
                name="street"
                label="Street"
                fullWidth
                autoComplete="street"
                value={customer?.street?.value}
                onChange={(e) => handleCustomer(e)}
                helperText={customer?.street?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required={customer?.city?.required}
                error={customer?.city?.error}
                id="city"
                name="city"
                label="City"
                fullWidth
                autoComplete="city"
                value={customer?.city?.value}
                onChange={(e) => handleCustomer(e)}
                helperText={customer?.city?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {countries && countries.length > 0 && (
                <Select
                  required={customer?.country?.required}
                  error={customer?.country?.error}
                  id="country"
                  name="country"
                  label="Country"
                  value={customer?.country?.value}
                  onChange={(e) => handleCustomer(e)}
                  options={countries}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  required={customer?.zip?.required}
                  error={customer?.zip?.error}
                  id="zip"
                  name="zip"
                  label="Zip / Postal code"
                  fullWidth
                  autoComplete="zip"
                  value={customer?.zip?.value}
                  onChange={(e) => handleCustomer(e)}
                  helperText={customer?.zip?.error}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                required={customer?.industry?.required}
                error={customer?.industry?.error}
                id="industry"
                name="industry"
                label="Industry"
                value={customer?.industry?.value}
                options={industries}
                onChange={(e) => handleCustomer(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {resellers && resellers.length > 0 && (
                <Select
                  name="reseller"
                  style={{ maxWidth: "600px" }}
                  label="Select reseller"
                  value={customer?.reseller?.value ?? ""}
                  onChange={(e, v) =>
                    setReseller({
                      id: e.target.value,
                      company:
                        v.props.children && v.props.children !== "None" ? v.props.children : "",
                    })
                  }
                  options={resellers}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                required={customer?.web?.required}
                error={customer?.web?.error}
                id="web"
                name="web"
                label="Company website"
                fullWidth
                autoComplete="web"
                value={customer?.web?.value ?? ""}
                onChange={(e) => handleCustomer(e)}
                helperText={customer?.web?.error}
              />
            </Grid>
            {withNote && (
              <Grid item xs={12} md={12}>
                <TextField
                  required={customer?.note?.required}
                  error={customer?.note?.error}
                  id="note"
                  label="Note"
                  name="note"
                  multiline
                  rows="3"
                  fullWidth
                  onChange={(e) => handleCustomer(e)}
                  value={customer?.note?.value ?? ""}
                  helperText={customer?.note?.error}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNewCustomer: (customer) => dispatch({ type: "customer/SET_NEW_CUSTOMER", customer }),
  };
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  order: state.order,
  customer: state.customer,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNew);
