import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { Link } from "react-router-dom";

const styles = () => ({
  tableContainer: {
    minHeight: 320,
  },
});

class CustomersTabs extends React.Component {
  state = {
    tab: 0,
  };

  setTab(pathName) {
    switch (pathName) {
      case "/customers":
        this.setState({ tab: 0 });
        break;
      case "/customers/productadoption":
        this.setState({ tab: 1 });
        break;
      default:
        break;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setTab(this.props.location.pathname);
    }
  }

  componentDidMount() {
    this.setTab(this.props.history.location.pathname);
  }

  handleTabChange = (e, newValue) => {
    this.setState({ tab: newValue });
  };

  render() {
    const { tab } = this.state;

    return (
      <Paper square style={{ marginBottom: 15, borderRadius: 4 }}>
        <Tabs value={tab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Customers" component={Link} to="/customers" />
          <Tab label="Product Adoption" component={Link} to="/customers/productadoption" />
        </Tabs>
      </Paper>
    );
  }
}

CustomersTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomersTabs);
