import moment from "moment";
// const userLanguage = navigator.language || navigator.userLanguage;

export const formatFullDate = (date) => {
  // return date ? new Date(date).toLocaleDateString(userLanguage) : "";
  // return date ? new Intl.DateTimeFormat(userLanguage).format(new Date(date)) : "";
  // return date ? new Date(date).toLocaleDateString("cs-CZ") : "";
  return date ? moment(date).format("DD.MM.YYYY") : "";
};

export const format2Decimal = (value) => {
  return value ? parseFloat(value).toFixed(2) : "";
};

export const formatCurrency = (value, currency) => {
  return value || value === 0 ? `${format2Decimal(value)} ${currency}` : "";
};

export const getRoundedPrice = (number) => {
  if (!number) {
    return 0;
  }
  const roundedNumber = parseFloat(number).toFixed(2);
  return roundedNumber.replace(/\.00$|(\.[1-9]0$)|0$/, "$1");
};

export const checkDecimal2 = (n) => {
  var re = /^\d+(\.\d{1,2})?$/;
  return re.test(n);
};

export const checkDecimal8 = (n) => {
  const re = /^-?\d*([.,]?\d{0,8})?$/;
  return re.test(n);
};

export const toDecimal = (n, decimal) => parseFloat(parseFloat(n).toFixed(decimal));
