import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import classnames from "classnames";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import React from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { Link } from "react-router-dom";
import { subscription } from "../api/subscription.js";
import Can from "../services/acl";
import { postClient } from "../services/axios";
import { getLocalValue, setLocalValue } from "../services/storage";
import GreyText from "../ui/Text/GreyText.js";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import { compose } from "redux";
import { connect } from "react-redux";

const columnsDefault = [
  /*{
        name: "id",
        label: "ID",
        options: {
            filter: false,
            display: false,
            sort: false
        }
    },*/
  {
    name: "created",
    label: "Created",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "company",
    label: "Company",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <Link to={`/customer/detail/${tableMeta.rowData[7]}`}>{value}</Link>;
      },
    },
  },
  {
    name: "product",
    label: "Product",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "licence",
    label: "License",
    options: {
      filter: false,
      sort: false /*,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <React.Fragment>
                        {value}
                        <IconButton
                            component={Link}
                            size={'small'}
                            variant="contained"
                            to={`/licence/detail/${tableMeta.rowData[0]}`}>
                            <OpenInBrowser />
                        </IconButton>
                    </React.Fragment>
                );
            }*/,
    },
  },
  {
    name: "validFrom",
    label: "Valid from",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "validUntil",
    label: "Valid until",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      download: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <IconButton
            component={Link}
            size={"small"}
            variant="contained"
            to={`/licence/detail/${value /*tableMeta.rowData[0]*/}`}
          >
            <KeyboardArrowRight />
          </IconButton>
        );
      },
      customHeadRender: (columnMeta, handleToggleColumn) => {
        return (
          <th
            key={`th_key_${columnMeta.id}`}
            className="MuiTableCell-root MUIDataTableHeadCell-root-348 MUIDataTableHeadCell-fixedHeader-349 MuiTableCell-head"
            scope="col"
          >
            <div className="MUIDataTableHeadCell-data-352"></div>
          </th>
        );
      },
    },
  },
  {
    name: "companyId",
    label: "companyId",
    options: {
      filter: false,
      sort: false,
      display: false,
      download: false,
    },
  },
];

const styles = (theme) => ({
  tableContainer: {
    height: 320,
  },
  ExpiredRow: {
    "& td": {
      textDecoration: "line-through",
      opacity: "0.3",
    },
  },
});

class Licences extends React.Component {
  state = {
    licences: [],
    error: false,
    isLoading: true,
    status: false,
  };

  options = {
    filter: true,
    filterType: "dropdown",
    responsive: "stacked",
    print: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
    setRowProps: (row) => {
      return {
        className: classnames({
          [this.props.classes.ExpiredRow]: moment(row[4]?.substr(13), "DD.MM.YYYY") < moment(),
        }),
        //style: { textDecoration: 'line-through' }
      };
    },
    onChangeRowsPerPage: (rowsPerPage) => {
      this.handleStoreState({
        rowsPerPage,
      });
    },
    onChangePage: (page) => {
      this.handleStoreState({
        page,
      });
    },
    onSearchChange: (searchText) => {
      this.handleStoreState({
        searchText,
      });
    },
    onColumnSortChange: (column, direction) => {
      this.handleStoreState({
        columnSort: {
          column,
          direction,
        },
      });
    },
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        let res = 1;
        if (colIndex === 0 || colIndex === 4 || colIndex === 5) {
          // created, licence until
          if (a.data[colIndex] && b.data[colIndex]) {
            let format = "DD.MM.YYYY";
            if (colIndex === 0) format = "DD.MM.YYYY hh:mm";
            res = moment(a.data[colIndex], format) < moment(b.data[colIndex], format) ? -1 : 1;
          } else if (a.data[colIndex]) {
            res = 1;
          } else {
            res = -1;
          }
          /*} else if (colIndex === 4 || colIndex === 5) {
                    // seats, renewed
                    res = parseInt(a.data[colIndex]) > parseInt(b.data[colIndex]) ? -1 : 1;*/
        } else {
          // others
          res = a.data[colIndex].localeCompare(b.data[colIndex]) === -1 ? -1 : 1;
        }
        return res * (order === "desc" ? 1 : -1);
      });
    },
  };

  handleStoredTableOptions = (defaultOptions) => {
    return {
      ...defaultOptions,
      ...JSON.parse(getLocalValue("Licences")),
    };
  };

  handleStoredColsOptions = (columnsDefault) => {
    var options = JSON.parse(getLocalValue("Licences"));
    if (options && options.columnSort) {
      columnsDefault.forEach((i) => {
        if (i.name === options.columnSort.column) {
          if (options.columnSort.direction === "ascending") {
            i.options.sortDirection = "asc";
          }
          if (options.columnSort.direction === "descending") {
            i.options.sortDirection = "desc";
          }
        }
      });
    }
    return columnsDefault;
  };

  handleStoreState = (data) => {
    setLocalValue(
      "Licences",
      JSON.stringify({
        ...JSON.parse(getLocalValue("Licences")),
        ...data,
      })
    );
  };

  componentDidMount = () => {
    postClient(process.env.REACT_APP_API_URL + subscription, {
      where: { type: "trial" },
    })
      .then((response) => {
        return response.data.map((licence) => {
          let l = {};
          const getSerial = () => {
            if (licence.licences.length) {
              if (licence.licences?.length > 1) {
                return licence.licences.map((item) => (
                  <GreyText variant="body2" disabled={item.disabled} style={{ marginRight: 7 }}>
                    {item.serial}
                  </GreyText>
                ));
              } else {
                return (
                  <GreyText
                    variant="body2"
                    {...{
                      text: licence.licences[0].serial,
                      disabled: licence.licences[0].disabled,
                    }}
                  />
                );
              }
            } else {
              return (
                <a href="https://hub.safetica.com" target="_blank">
                  Safetica HUB
                </a>
              );
            }
          };
          try {
            l = {
              id: licence.id,
              created: licence.licences.length
                ? `${moment(licence.licences[0].created).format("DD.MM.YYYY hh:mm")}`
                : `${moment(licence.date_creation).format("DD.MM.YYYY hh:mm")}`,
              company: licence.customerDetail ? licence.customerDetail.firm : " - ",
              product: licence.title ? licence.title : " - ",
              count: licence.count,
              licence: getSerial(),
              validFrom: moment(licence.valid_from).format("DD.MM.YYYY"),
              validUntil: moment(licence.valid_to).format("DD.MM.YYYY"),
              edit: licence.id,
              companyId: licence.customerDetail ? licence.customerDetail.id : "",
            };
          } catch (e) {
            console.log(e);
          }
          return l;
        });
      })
      .then((licences) => {
        this.setState({
          licences,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  render() {
    const { error, licences } = this.state;
    const { classes, setNewCustomer, validateTrialCustomer, cleanTrial } = this.props;

    return (
      <div>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}

        <Can resource="Trials_create">
          <Button
            component={Link}
            className={classes.button}
            to="/licence/create"
            variant="contained"
            color="primary"
            style={{ marginBottom: "15px" }}
            onClick={() => {
              setNewCustomer(null);
              validateTrialCustomer("setNull");
              cleanTrial();
            }}
          >
            Create new Trial
          </Button>
        </Can>

        <div className={classes.tableContainer}>
          <ReactPlaceholder
            type="text"
            ready={!this.state.isLoading}
            rows={20}
            color="#E0E0E0"
            showLoadingAnimation={true}
          >
            <MUIDataTable
              title={
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <VpnKeyIcon />
                  </Grid>
                  <Grid item>
                    <Typography>Licences</Typography>
                  </Grid>
                </Grid>
              }
              data={licences}
              options={this.handleStoredTableOptions(this.options)}
              columns={this.handleStoredColsOptions(columnsDefault)}
            />
          </ReactPlaceholder>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

Licences.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    cleanTrial: () => dispatch({ type: "trial/CLEAN_TRIAL" }),
    setNewCustomer: (customer) => dispatch({ type: "customer/SET_NEW_CUSTOMER", customer }),
    validateTrialCustomer: (customer) => dispatch({ type: "trial/VALIDATE_TRIAL_CUSTOMER", customer }),
  };
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Licences);
