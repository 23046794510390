import "date-fns";
import React from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import NewCustomerForm from "./NewCustomerForm";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import fetchClient from "../services/axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { isCustomerFormFilled } from "../services/customer";
import { validateEmail } from "../services/validator";
import { customerNew } from "../api/customer.js";
import { validateWeb } from "../services/customer";

/*const requiredFields = [
    'firmName',
    'contactName',
    'contactEmail',
    'street',
    'city',
    'zip',
    'country',
    'industry',
    'web'
];*/

class EditCustomerDialog extends React.Component {
  state = {
    customer: {
      firmName: "",
      contactName: "",
      contactEmail: "",
      street: "",
      city: "",
      zip: "",
      country: "",
      industry: "",
      web: "",
      tin: "",
      reseller: "",
    },
    countries: [],
    isLoading: false,
    error: false,
  };

  componentDidMount = () => {
    this.setState({
      customer: {
        firmName: this.props.customer.company_name,
        contactName: this.props.customer.name,
        contactEmail: this.props.customer.email,
        street: this.props.customer.address,
        city: this.props.customer.city,
        zip: this.props.customer.zip,
        country: this.props.customer.country,
        industry: this.props.customer.industry,
        web: this.props.customer.website,
        tin: this.props.customer.tax_identification_number,
        reseller: this.props.customer.resellerDetail ? this.props.customer.resellerDetail.id : null,
      },
      isLoading: false,
    });
  };

  handleSubmit = (e) => {
    if (this.handleValidate()) {
      // reset generated licence
      this.setState({
        isLoading: true,
      });

      // call API
      fetchClient()
        .put(process.env.REACT_APP_API_URL + customerNew + "/" + this.props.customer.id, {
          customer: this.state.customer,
        })
        .then((response) => {
          // assign generated and returned licence
          this.setState({
            isLoading: false,
          });

          // redirect to customer detail
          this.props.handleLoadCustomer(this.props.customer.id);
          this.props.handleDialogClose();
          this.props.handleEditSuccess("Changes have been saved.");
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            error: error.response ? error.response.data.errors : error,
          });
        });
    }
  };

  handleChangeNewCustomer = (e) => {
    this.setState({
      customer: {
        ...this.state.customer,
        [e.target.name]: e.target.value,
      },
      error: false,
    });
  };

  handleValidate = () => {
    if (isCustomerFormFilled(this.state.customer) === false) {
      this.setState({
        error:
          this.state.customer?.contactEmail && !validateEmail(this.state.customer.contactEmail)
            ? "Email is in incorrect format"
            : this.state.customer?.web && !validateWeb(this.state.customer.web)
              ? "Website is in incorrect format"
              : "You must set all required customers data.",
      });
      return false;
    } else {
      this.setState({ error: "" });
      return true;
    }
    /*let isNewCustomerEmpty = requiredFields.find(x => (this.state.customer[x] === null || this.state.customer[x] === '' || this.state.customer[x].length < 1 || this.state.customer[x] === false));
        if (isNewCustomerEmpty !== undefined  || this.state.customer.country === '') isNewCustomerEmpty = true;
        if (isNewCustomerEmpty) {
            this.setState({ error: 'You must set all required customers data.' });
            return false;
        } else {
            this.setState({ error: '' });
            return true;
        }*/
  };

  render() {
    const { dialogOpen, handleDialogClose, handleLoadCustomer } = this.props;
    const { customer, error, isLoading } = this.state;

    return (
      <React.Fragment>
        <CustomizedSnackbars
          message={error.message ? error.message : error}
          variant="error"
          open={!!error}
        />
        <Dialog
          disableScrollLock={true}
          open={dialogOpen}
          onClose={handleDialogClose}
          scroll="paper"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit customer</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <NewCustomerForm
                  {...this.props}
                  newCustomer={customer}
                  handleLoadCustomer={handleLoadCustomer}
                  handleChangeNewCustomer={this.handleChangeNewCustomer}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button disabled={error ? true : false} onClick={this.handleSubmit} color="primary">
              {isLoading && isLoading ? "Saving changes..." : "Save changes"}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

// EditCustomerDialog.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default EditCustomerDialog;
