import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import React from "react";
import { connect } from 'react-redux';
import { trialProducts } from "../../../api/trial";
import fetchClient from "../../../services/axios";
import { getLocalValue } from "../../../services/storage";
import { Select } from "../../../ui/Input";
import CustomizedSnackbars from "../../CustomizedSnackbars";
import { max } from "lodash";

class TrialNewProductForm extends React.Component {
    state = {
        isLoading: false,
        error: false,
        products: [],
        filteredProducts: [],
        basket: {},
    };

    initItem = {
        product: { value: null, required: true, error: false },
        count: { value: 20, required: true, error: false, max: this.props.user.max_count },
        validFrom: { value: new Date(), required: true, error: false },
        validity: { value: 1, required: true, error: false },
    };

    componentDidMount = () => {

        if (!this.props?.trial?.basket && !this.props?.trial?.basket?.product) {
            this.props.setBasket(this.initItem);
        }

        fetchClient()
            .get(
                process.env.REACT_APP_API_URL +
                trialProducts
            )
            .then((response) => {
                const products = response.data?.products;
                const filteredProducts = this.props.user.companyDetail.has_hub
                    ? products
                    : products?.filter((product) => product.type !== "MONTHLY_BILLING");

                this.setState({
                    products: filteredProducts,
                });
            })
            .catch((error) => {
                this.setState({ error, isLoading: false });
            });
    };

    render() {
        const { trial } = this.props;
        const { products, error } = this.state;
        const basket = trial.basket;

        const handleTrial = (val, key) => {
            this.props.setBasket({
                ...basket, ...{
                    [key]: { ...basket[key], value: val, error: false }
                }
            })
        }

        if (error) {
            return <CustomizedSnackbars message={error.message} variant="error" />;
        }

        return (
            <React.Fragment>
                <form style={{ marginBottom: 20 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    required
                                    margin=""
                                    label="Valid from"
                                    value={trial?.basket?.validFrom?.value || null}
                                    fullWidth
                                    onChange={(val) =>
                                        handleTrial(val, "validFrom")
                                    }
                                    error={basket?.validFrom?.error}
                                    helperText={basket?.validFrom?.error}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                required
                                id="validity"
                                label="Validity in months"
                                value={trial?.basket?.validity?.value}
                                name="validity"
                                type="number"
                                fullWidth
                                inputProps={{
                                    min: "1",
                                    max: getLocalValue("maxMonths"),
                                    step: "1",
                                }}
                                onChange={(e) => handleTrial(e.target.value, "validity")}
                                error={basket?.validity?.error}
                                helperText={basket?.validity?.error}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                required
                                id="count"
                                label="Users"
                                value={trial?.basket?.count?.value || 20}
                                name="count"
                                type="number"
                                fullWidth
                                inputProps={{ min: "20", step: "1" }}
                                onChange={(e) => handleTrial(e.target.value, "count")}
                                error={basket?.count?.error}
                                helperText={basket?.count?.error}
                            />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Select
                                label="Product"
                                name="product"
                                required={true}
                                fullWidth
                                value={basket?.product?.value || ""}
                                onChange={(e) => handleTrial(e.target.value, "product")}
                                options={products}
                                error={basket?.product?.error}
                            />
                        </Grid>
                    </Grid>

                </form>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTrialTreshold: (treshold) => dispatch({ type: 'trial/SET_TRIAL_TRESHOLD', treshold }),
        setBasket: basket => dispatch({ type: 'trial/SET_BASKET_TRIAL', basket }),
        setNote: note => dispatch({ type: 'trial/SET_NOTE_TRIAL', note }),
    }
}


const mapStateToProps = state => ({
    isLoading: state.settings.isLoading,
    trial: state.trial,
    customer: state.customer,
    user: state.user
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrialNewProductForm);
