export const createTrial = (props) => {
    const { customer, _this } = props;

    _this.props.setTrialCustomer({
        companyCompanyContexts: customer.companyCompanyContexts,
        id: customer.id,
        firm: customer.company_name
    });

    if (customer?.resellerDetail) {
        _this.props.setTrialReseller({
            reseller: {
                id: customer.resellerDetail.id,
                company: customer.resellerDetail.company_name
            }
        });
    }

    _this.props.validateTrialCustomer({
        companyCompanyContexts: customer.companyCompanyContexts,
        id: customer.id,
        firm: customer.company_name
    });

    _this.props.setNewCustomer(null);
}