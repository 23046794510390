import { Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logoutUser } from "../../services/auth";
import { msal } from "./App";
import "./App.css";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function logoutRequest() {
    try {
      const accounts = msal.getAllAccounts();
      await msal.logoutRedirect({
        account: accounts[0],
      });
    } catch (error) {
      console.error("Logout error: ", error);
    }
  }

  const handleLogout = (e) => {
    logoutRequest();
    logoutUser();
    setAnchorEl(null);
    window.location.href = "/login";
  }
  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }} />
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock={true}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}
          aria-describedby="logged-user-popover"
          aria-haspopup="true"
          color="inherit"
          component={Link}
          to="/account"
        >
          <ListItemIcon>
            <AccountCircleIcon
              style={{ opacity: 0.8 }}
            />
          </ListItemIcon>
          <Typography>
            Account
          </Typography>
        </MenuItem>
        <Divider style={{ margin: '0 auto', width: '90%' }} />
        <MenuItem onClick={handleLogout}
          color="inherit"
          component={Link}
          to="/login"
        >
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}