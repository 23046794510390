import { PublicClientApplication } from "@azure/msal-browser";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React from "react";
import { msalConfig } from "../../authConfig.js";
import withLoginTheme from "../../withLoginTheme.js";
import CustomizedSnackbars from "../CustomizedSnackbars.js";
import LoginHelp from "./LoginHelp";

const msalInstance = new PublicClientApplication(msalConfig);

const LoginButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#a2cbf9"),
    backgroundColor: "#a2cbf9",
    '&:hover': {
      backgroundColor: "#74c7f7",
    },
  },
}))(Button);

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(1) * 3}px ${theme.spacing(1) * 3
      }px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isLoading: false,
      isLoadingSso: false,
      notFound: false,
      error: false,
      showPassword: false,
      from:
        props.location && props.location.state && props.location.state.from
          ? props.location.state.from.pathname
          : null,
    };
  }

  handleLoginByAzure = () => {
    this.setState({ isLoadingSso: true });
    msalInstance.loginRedirect();
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { classes } = this.props;
    const { error, notFound } = this.state;

    return (
      <main className={classes.main}>
        <CssBaseline />
        {error ? (
          <CustomizedSnackbars
            message={JSON.stringify(error)}
            variant="error"
            open={error ? true : false}
          />
        ) : (
          ""
        )}
        {notFound ? (
          <CustomizedSnackbars
            message="Auth failed!!"
            variant="warning"
            open={notFound ? true : false}
          />
        ) : (
          ""
        )}
        <Paper className={classes.paper}>
          <br />
          <img
            src="https://cdn.safetica.com/web/safetica/img/logo.png"
            alt="Safetica"
          />
          <br />
          <LoginButton
            style={{ marginBottom: 40 }}
            onClick={this.handleLoginByAzure}
            variant="contained"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
              <rect x="1" y="1" width="9" height="9" fill="#f25022" />
              <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
              <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
              <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
            </svg>
            <span style={{ paddingLeft: 10 }}>
              Log in with Microsoft
            </span>
          </LoginButton>
          <LoginHelp />
        </Paper>
      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withLoginTheme(withStyles(styles)(SignIn));
