import { applyMiddleware, createStore } from "redux";
import fetchClient from "../services/axios";
import rootReducer from "./reducers";

const asyncFunctionMiddleware = (storeAPI) => (next) => (action) => {
  if (typeof action === "function") {
    return action(storeAPI.dispatch, storeAPI.getState);
  }
  return next(action);
};

const middlewareEnhancer = applyMiddleware(asyncFunctionMiddleware);
const store = createStore(rootReducer, middlewareEnhancer);

const fetchProfiles = (dispatch, getState) => {
  const pathname = new URL(window.location.href).pathname;
  pathname !== "/login" &&
    pathname !== "/oauth-redirect" &&
    fetchClient()
      .get(process.env.REACT_APP_API_URL + "/userinit")
      .then((response) => {
        dispatch({ type: "user/SET_USER", user: response?.data?.user });
        dispatch({
          type: "profiles/SET_PROFILES",
          profiles: response?.data?.profiles,
        });

        // const allProfiles = getState()
        // console.log('Settings, User, Profiles: ', allProfiles)
      })
      .catch((error) => {
        // console.error("Chyba při načítání dat do Reduxu: ", error);
        console.log("Unauthorized");
      });
};

store.dispatch(fetchProfiles);

// store.dispatch({
//     type: 'order/SET_CUSTOMER',
//     customer: {},
// })

store.dispatch({
  type: "order/SET_ORDER_SETTINGS",
  orderSettings: { minSeats: 20, step: 10 },
});

store.dispatch({
  type: "utils/SET_UTILS",
  utils: { isLoading: false, error: false, success: false },
});

export default store;
