import { Box, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { connect } from 'react-redux';
import Can from "../../../services/acl";
import fetchClient from "../../../services/axios";
import { Select } from "../../../ui/Input";
import CustomerNew from "../CustomerNew";
import { customersList } from "../../../api/customer";
import { resellersList } from "../../../api/partner";
import { customerDetail } from "../../../api/customer";



class TrialNewCustomerForm extends Component {
    state = {
        isLoading: false,
        error: false,
        showCustomerForm: this.props.showCustomerForm,
        // showCustomerForm: false,
        resellers: [],
    };

    componentDidMount = () => {
        if (!this.props.customers) {
            fetchClient()
                .get(
                    process.env.REACT_APP_API_URL +
                    customersList
                )
                .then((response) => {
                    this.setState({
                        customers: response.data.customers,
                    });
                })
                .catch((error) => {
                    this.setState({ error, isLoading: false });
                });
        } else {
            this.setState({ customers: this.props.customers });
        }

        if (!this.props.resellers) {
            fetchClient()
                .get(
                    process.env.REACT_APP_API_URL +
                    resellersList
                )
                .then((response) => {
                    this.setState({
                        resellers: response.data.resellers,
                    });
                })
                .catch((error) => {
                    this.setState({ error, isLoading: false });
                });
        } else {
            this.setState({
                resellers: this.props.resellers,
            });
        }
    };

    render() {
        const { error, customers = [], resellers, showCustomerForm } = this.state;
        const { setCustomer, setNewCustomer, customer, setReseller, trial,
            validateTrialCustomer, setUtils } = this.props;

        const handleCustomer = async (e, val) => {
            setCustomer(val);
            validateTrialCustomer(val);
            setNewCustomer(null);
            !val && setReseller({ reseller: null });
            this.setState({ showCustomerForm: false });

            val?.id && fetchClient()
                .get(
                    process.env.REACT_APP_API_URL +
                    customerDetail +
                    "/"
                    + val.id
                )
                .then((response) => {
                    const customer = response.data.customer;

                    if (customer) {
                        setReseller({ reseller: { id: customer?.resellerDetail.id, company: customer?.resellerDetail.company_name } || null });
                    }
                })
                .catch((error) => {
                    this.setState({
                        error: error.response ? error.response.data.errors : error,
                        isLoading: false,
                    });
                });
        }

        return (
            <Box style={{ flexDirection: "column", paddingBottom: 30 }}>
                <FormControl fullWidth style={{ paddingBottom: 20 }}>
                    <Autocomplete
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ maxWidth: "600px" }}
                                error={trial?.isTrialCustomerValid === false}
                                helperText={trial?.isTrialCustomerValid === false && "Please select a customer or create new one."}
                                label="Select customer"
                                placeholder="Choose from an existing customers"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off",
                                }}
                            />
                        )}
                        autoSelect={true}
                        autoHighlight={true}
                        id="customer"
                        name="customer"
                        value={trial?.customer ?? ""}
                        options={customers}
                        getOptionLabel={(option) => option.firm ?? ""}
                        onChange={(e, val) => handleCustomer(e, val)}
                    />
                </FormControl>
                <Can resource="Customers_create">
                    <Button
                        style={{ marginBottom: 20 }}
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            this.setState({ showCustomerForm: !this.state.showCustomerForm });
                            setCustomer(null)
                            //remove errors
                            const newCustomer = customer ?
                                Object.keys(customer).reduce((acc, key) => {
                                    acc[key] = { ...customer[key], error: false }
                                    return acc;
                                }, {})
                                : null;
                            setNewCustomer(newCustomer ?? null)

                            setReseller({ reseller: null })
                            validateTrialCustomer("setNull");
                        }}
                    >
                        Create new customer
                    </Button>
                    {this.state.showCustomerForm &&
                        <CustomerNew />}
                    {/* TODO: Check v.props.children */}
                    {!showCustomerForm && resellers && resellers.length > 0 && (
                        <Select
                            disabled={trial?.customer ? false : true}
                            style={{ maxWidth: "600px" }}
                            label="Select reseller"
                            value={trial?.reseller?.id ?? ""}
                            onChange={(e, v) => setReseller({
                                reseller: {
                                    id: e.target.value,
                                    company: v.props.children && v.props.children !== "None" ? v.props.children : ""
                                }
                            }
                            )}
                            options={resellers}
                        />
                    )}
                </Can>

            </Box>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUtils: utils => dispatch({ type: 'utils/SET_UTILS', utils }),
        setNewCustomer: customer => dispatch({ type: 'customer/SET_NEW_CUSTOMER', customer }),
        setCustomer: customer => dispatch({ type: 'trial/SET_CUSTOMER_TRIAL', customer }),
        setReseller: reseller => dispatch({ type: 'trial/SET_RESELLER_TRIAL', reseller }),
        validateTrialCustomer: customer => dispatch({ type: 'trial/VALIDATE_TRIAL_CUSTOMER', customer }),
    }
}

const mapStateToProps = state => ({
    isLoading: state.settings.isLoading,
    trial: state.trial,
    customer: state.customer,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrialNewCustomerForm);

