import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { BrowserRouter as ReactRouter } from "react-router-dom";
import { logoutUser } from "../../services/auth";
import fetchClient from "../../services/axios";
import { getLocalValue, setLocalValue } from "../../services/storage";
import { styles } from "../App/styles";
import Outlet from "./Outlet";
import SideBar from "./SideBar";
import TopBar from "./TopBar";


class Layout extends React.Component {
  state = {
    isDrawerOpened: true,
    isLoggedOut: false,
    showSearchResults: false,
    searchResultsAnchorEl: null,
    isLoading: false,
    searchResults: [],
  };

  componentDidMount() {
    this.setState({
      isDrawerOpened: getLocalValue("isDrawerOpened") !== "false",
    });
  }

  handleDrawerOpen = () => {
    setLocalValue("isDrawerOpened", true);
    this.setState({ isDrawerOpened: true });
  };

  handleDrawerClose = () => {
    setLocalValue("isDrawerOpened", false);
    this.setState({ isDrawerOpened: false });
  };

  handleLogout = (e) => {
    logoutUser();
    window.location.href = "/login";
    //this.setState({ isLoggedOut: true })
    e.preventDefault();
  };

  handleSearch = (e) => {
    let toSearch = e.currentTarget;
    clearTimeout(this.searchTimeout);
    this.setState({
      isLoading: true,
    });
    this.searchTimeout = setTimeout(
      () =>
        fetchClient()
          .get(
            process.env.REACT_APP_API_URL +
            process.env.REACT_APP_USER_SEARCH_ENDPOINT +
            "/" +
            toSearch.value
          )
          .then((search) => {
            this.setState({
              searchResults: search.data,
              searchResultsAnchorEl: toSearch,
              showSearchResults: true,
              isLoading: false,
            });
          })
          .catch((error) => {
            this.setState({ error, isLoading: false });
          }),
      500
    );

    e.preventDefault();
  };

  handleCloseSearchResults = () => {
    this.setState({
      searchResultsAnchorEl: null,
      showSearchResults: false,
      isLoading: false,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      isDrawerOpened,
    } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <ReactRouter>
          <TopBar {...{ isDrawerOpened, handleDrawerOpen: this.handleDrawerOpen }} />
          <SideBar {...{ isDrawerOpened, handleDrawerClose: this.handleDrawerClose }} />
          <Outlet {...{ isDrawerOpened }} />
        </ReactRouter>
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Layout);
