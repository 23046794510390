import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
});

class NotFound extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Card className={classes.card} style={{ marginBottom: "20px" }}>
            <CardContent>
              <Typography variant="h5" gutterBottom component="h1">
                This particular resource is not availible for your user rights.
                <br />
                Please contact your Safetica account manager.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
