import React from "react";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

// import purple from "material-ui/colors/purple";
// import green from "material-ui/colors/green";
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    minHeight: "100vh",
                    backgroundImage: "url(https://cdn.safetica.com/web/safetica/img/web2.jpg)",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }
            }
        }
    }
});

function withLoginTheme(Component) {
    function withLoginTheme(props) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        return (
            <MuiThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...props} />
            </MuiThemeProvider>
        );
    }

    return withLoginTheme;
}

export default withLoginTheme;