import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import React from "react";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function ChangeOrderInfo(props) {
  const { checkForMaintenance, isFutureDate } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <ErrorIcon
        style={{ marginLeft: 4 }}
        fontSize="small"
        color="error"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        disableScrollLock={true}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {!checkForMaintenance ? (
          <Typography>
            Upsell possible only for perpetual licence order. <br />
            Contact back office for further assistance.
          </Typography>
        ) : null}
        {!isFutureDate ? <Typography>Upsell not possible due to expired product validity.</Typography> : null}
      </Popover>
    </div>
  );
}
