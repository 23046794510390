import {
    FORM_CHANGE_PASSWORD,
    FEEDBACK_ERROR,
    FEEDBACK_SUCCESS,
    FEEDBACK_RESET,
    FORM_CHANGE_MASTER_EMAIL,
    FEEDBACK_RESET_MASTER_EMAIL
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoadingMasterEmail: false,
    success: false,
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
    masterEmail: '',
    error: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FORM_CHANGE_PASSWORD: {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value,
                error: false,
                success: false
            };
        }
        case FORM_CHANGE_MASTER_EMAIL: {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value,
                error: false,
                success: false
            };
        }
        case FEEDBACK_ERROR: {
            const { error } = action.payload;
            return {
                ...state,
                isLoading: false,
                isLoadingMasterEmail: false,
                error,
                success: false
            };
        }
        case FEEDBACK_SUCCESS: {
            const { status, error, message } = action.payload;
            return {
                ...state,
                isLoading: false,
                isLoadingMasterEmail: false,
                error: status !== 200 ? error : false,
                success: status === 200 ? message : false
            };
        }
        case FEEDBACK_RESET: {
            return {
                ...state,
                isLoading: true,
                error: false,
                success: false
            };
        }
        case FEEDBACK_RESET_MASTER_EMAIL: {
            return {
                ...state,
                isLoadingMasterEmail: true,
                error: false,
                success: false
            };
        }
        default:
            return state;
    }
}
