import "date-fns";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import NewCustomerForm from "./NewCustomerForm";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import fetchClient from "../services/axios";
import { customerDetail, customerNew } from "../api/customer.js";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

class EditCustomerWrapperFrom extends React.Component {
  state = {
    customer: {
      firmName: "",
      contactName: "",
      contactEmail: "",
      street: "",
      city: "",
      zip: "",
      country: "",
      industry: "",
      web: "",
      tin: "",
      reseller: "",
    },
    countries: [],
    isLoading: false,
    error: false,
  };

  componentDidMount = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + customerDetail + "/" + this.props.match.params.id)
      .then((response) => {
        //console.log(response.data);
        this.setState({
          customer: {
            firmName: response.data.customer.firm,
            contactName: response.data.customer.name,
            contactEmail: response.data.customer.email,
            street: response.data.customer.street,
            city: response.data.customer.city,
            zip: response.data.customer.zip,
            country: response.data.customer.country,
            industry: response.data.customer.industry,
            web: response.data.customer.web,
            tin: response.data.customer.tin,
            reseller: response.data.customer.reseller,
          },
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  handleSubmit = (e) => {
    if (this.handleValidate()) {
      // reset generated licence
      this.setState({
        isLoading: true,
      });

      // call API
      fetchClient()
        .put(process.env.REACT_APP_API_URL + customerNew + "/" + this.props.match.params.id, {
          customer: this.state.customer,
        })
        .then((response) => {
          // assign generated and returned licence
          this.setState({
            isLoading: false,
          });

          // redirect to customer detail
          this.props.history.push("/customer/detail/" + this.props.match.params.id);
        })
        .catch((error, response) => {
          console.log(error, response);
          this.setState({
            isLoading: false,
            error: error.message,
          });
        });
    }
  };

  handleChangeNewCustomer = (e) => {
    this.setState({
      customer: {
        ...this.state.customer,
        [e.target.name]: e.target.value,
      },
      error: false,
    });
  };

  handleValidate = () => {
    let isNewCustomerEmpty = Object.values(this.state.customer).find(
      (x) => x === null || x === "" || x.length < 1 || x === false
    );
    if (isNewCustomerEmpty !== undefined || this.state.customer.country === "") isNewCustomerEmpty = true;
    if (isNewCustomerEmpty) {
      this.setState({ error: "You must set all required customers data." });
      return false;
    } else {
      this.setState({ error: "" });
      return true;
    }
  };

  render() {
    const { classes } = this.props;
    const { customer, error, isLoading } = this.state;

    return (
      <React.Fragment>
        {error ? (
          <CustomizedSnackbars message={JSON.stringify(error)} variant="error" open={error ? true : false} />
        ) : (
          ""
        )}
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Paper className={classes.root} style={{ padding: "30px 30px 50px" }}>
              <Typography variant="h4" gutterBottom component="h3">
                Edit customer
              </Typography>
              <NewCustomerForm
                {...this.props}
                newCustomer={customer}
                handleLoadCustomer={this.handleLoadCustomer}
                handleChangeNewCustomer={this.handleChangeNewCustomer}
              />
              <Button
                style={{ marginTop: "30px" }}
                disabled={error || isLoading}
                variant="contained"
                color="primary"
                onClick={(e) => this.handleSubmit(e)}
                className={classes.button}
              >
                {isLoading ? "Loading..." : "Save changes"}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

EditCustomerWrapperFrom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditCustomerWrapperFrom);
