import { withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { compose } from 'redux';
import AccountMenu from "./AccountMenu";
import NoSafeticaHubInfo from "./NoSafeticaHubInfo";
import { styles } from "./styles";

class TopBar extends React.Component {

  render() {
    const { classes, isDrawerOpened, handleDrawerOpen, user, profiles } = this.props;

    return (
      <AppBar
        className={classNames(
          classes.appBar,
          isDrawerOpened && classes.appBarShift
        )}
        style={{
          boxSizing: 'border-box',
          width: isDrawerOpened ? "calc(100vw - 240px)" : "100vw",
          left: 0,
          top: 0,
          padding: isDrawerOpened ? "0 16px" : "0 16px 0 0",
        }}
      >
        <Toolbar disableGutters={!isDrawerOpened} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classNames(
              classes.menuButton,
              isDrawerOpened && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
              style={{
                display: "flex", alignItems: "center",
              }}
            >
              <Tooltip title={profiles?.length > 1 ? "Change account" : "Account info"} placement="bottom-start">
                <Link
                  to="/account"
                  style={{ textDecoration: 'none', color: 'inherit', display: "flex", alignItems: "center" }}
                >
                  {profiles?.length > 1 &&
                    <Box style={{ height: 24, marginRight: 8 }} >
                      <ArrowDropDownCircleIcon />
                    </Box>}
                  {user?.companyDetail?.company_name ? user?.companyDetail?.company_name : "Portal"}
                </Link>
              </Tooltip>
            </Typography>
          </Box>
          <Box style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}>
            {user && !user?.companyDetail?.has_hub &&
              <Box style={{
                marginRight: 40,
                display: "flex",
                alignItems: "center"
              }}>
                <Typography
                  variant="h5"
                  color="error"
                  noWrap
                  style={{
                    display: "flex", alignItems: "center", marginRight: 8
                  }}
                >
                  NO SAFETICA HUB
                </Typography>
                <NoSafeticaHubInfo />
              </Box>
            }
            <AccountMenu />
          </Box>

        </Toolbar>
      </AppBar>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.settings.isLoading,
  user: state.user,
  profiles: state.profiles
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(TopBar);