import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class TrialMaxCountInfo extends Component {
    state = {
        anchorEl: null,
    };

    handlePopoverOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const open = Boolean(this.state.anchorEl);
        return (
            <>
                <HelpOutlineIcon
                    style={{ fontSize: 14, cursor: "pointer" }}
                    color="error"
                    onMouseEnter={this.handlePopoverOpen}
                    onMouseLeave={this.handlePopoverClose}
                />
                <Popover
                    style={{ pointerEvents: 'none' }}
                    disableScrollLock={true}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={this.handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography style={{ padding: 10 }}>
                        The trial user count limit is set to {this.props.user?.max_count}. <br />
                        Please contact your account manager if you need to increase this limit.
                    </Typography>

                </Popover>
            </>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(
    mapStateToProps,
)(TrialMaxCountInfo);