import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "react-placeholder/lib/reactPlaceholder.css";
// import CustomizedSnackbars from './CustomizedSnackbars.js';
// import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Badge from "@material-ui/core/Badge";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Chart from "react-google-charts";
import ReactPlaceholder from "react-placeholder";
import fetchClient from "../services/axios";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { getLocalValue, setLocalValue } from "../services/storage";
import ProductAdoptions from "./ProductAdoption";
import { customerDetail, customerStats } from "../api/customer";

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0),
    padding: 0,
    fontSize: 10,
    height: 20,
  },
  margin: {
    margin: theme.spacing(2),
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "20%",
    right: -5,
  },
}))(Badge);

const Licence = (props) => {
  const classes = props.classes;
  const { item } = props;

  let status = "Active";

  if (item.disabled) {
    status = "Disabled";
  } else if (!item.orderItemDetail || moment(item.orderItemDetail.valid_to).isBefore()) {
    status = "Expired";
  }

  return (
    <Card
      className={classes.card}
      style={{
        marginBottom: "15px",
        border: "none",
        boxShadow: "none",
        borderRadius: 0,
        borderBottom: "1px solid #eee",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          component="h2"
          color={"secondary"}
          style={item.disabled ? { textDecoration: "line-through" } : {}}
        >
          {item.serial}
        </Typography>
        {item.orderItemDetail && (
          <Typography variant="body2" component="p">
            <strong>{status}</strong>
            <br />
            {moment(item.orderItemDetail.valid_from).format("DD.MM.YYYY")} -
            {moment(item.orderItemDetail.valid_to).isAfter("2100-01-01")
              ? " Perpetual"
              : moment(item.orderItemDetail.valid_to).format("DD.MM.YYYY")}
            , {item.orderItemDetail.seats} seats, {item.orderItemDetail.product_name}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const Order = (props) => {
  const classes = props.classes;
  const { item } = props;
  return (
    <Card
      className={classes.card}
      style={{
        marginBottom: "15px",
        border: "none",
        boxShadow: "none",
        borderRadius: 0,
        borderBottom: "1px solid #eee",
      }}
    >
      <CardContent>
        <Typography>Product: {item.title}</Typography>
        <Typography>Date order: {moment(item.date_order).format("DD.MM.YYYY")}</Typography>
        <Typography>Status: {item.state}</Typography>
        <Typography>Users: {item.count}</Typography>
        <Typography>
          Price: {parseFloat(item.price_final_end_user_origin).toFixed(2)} {item.currency}
        </Typography>
        <Typography>Valid from : {moment(item.valid_from).format("DD.MM.YYYY")}</Typography>
        <Typography>Valid to : {moment(item.valid_to).format("DD.MM.YYYY")}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" component={Link} to={`/order/detail/${item.id}`}>
          Detail
        </Button>
      </CardActions>
    </Card>
  );
};

const TrialLicence = (props) => {
  const classes = props.classes;
  const { item } = props;
  return (
    <Card
      className={classes.card}
      style={{
        marginBottom: "15px",
        border: "none",
        boxShadow: "none",
        borderRadius: 0,
        borderBottom: "1px solid #eee",
      }}
    >
      <CardContent>
        <Typography variant="h6" component="h2" color={"primary"}>
          {item.serial}
        </Typography>
        <Typography variant="body2" component="p">
          {moment(item.valid_from).format("DD.MM.")} - {moment(item.valid_to).format("DD.MM.YYYY")}, {item.state},{" "}
          {item.count} seats, {item.title}, created {moment(item.date_creation).format("DD.MM.YYYY")}
        </Typography>
      </CardContent>
      <CardActions>
        <Button component={Link} to={`/licence/detail/${item.id}`} size="small" color={"primary"}>
          Detail
        </Button>
      </CardActions>
    </Card>
  );
};

class CustomerDetailSidePanel extends React.Component {
  getLocalStorageStatsKey = () => "customerStatsLen" + this.props.customerId;

  state = {
    customer: {},
    stats: [],
    isLoading: true,
    isLoadingStats: true,
    error: false,
    status: false,
    tab: 0,
    notFound: false,
    statsLength: getLocalValue(this.getLocalStorageStatsKey(), 30),
  };

  componentWillReceiveProps = (props) => {
    if (this.props.customerId !== props.customerId) {
      this.handleProcessCustomer(props.customerDetail);
      this.handleLoadStats(props.customerDetail.guid);
    }
  };

  componentDidMount = async () => {
    let customerDetail;
    if (!this.props.customerDetail && this.props.customerId) {
      customerDetail = await this.handleLoadCustomer(this.props.customerId);
    } else {
      customerDetail = this.props.customerDetail;
    }
    this.handleProcessCustomer(customerDetail);
    this.handleLoadStats(customerDetail.guid);
  };

  handleLoadCustomer = async (id) => {
    let customerDetail = await fetchClient().get(process.env.REACT_APP_API_URL + customerDetail + "/" + id);
    return customerDetail.data.customer;
  };

  handleProcessCustomer = (customerDetail) => {
    if (customerDetail) {
      this.setState({
        customer: customerDetail,
        isLoading: false,
      });
    } else {
      this.setState({
        notFound: true,
        isLoading: false,
      });
    }
  };

  handleLoadStats = (customerGuid, length = getLocalValue(this.getLocalStorageStatsKey(), 30)) => {
    this.setState({
      isLoadingStats: true,
      statsLength: length,
    });
    setLocalValue(this.getLocalStorageStatsKey(), length);

    fetchClient()
      .get(process.env.REACT_APP_API_URL + customerStats + "/" + customerGuid + "/" + length)
      .then((responseStats) => {
        this.setState({
          stats: responseStats.data.stats,
          isLoadingStats: false,
        });
      })
      .catch((error) => {
        this.setState({
          error,
          isLoadingStats: false,
        });
      });
  };

  handleTabChange = (e, newValue) => {
    this.setState({ tab: newValue });
  };

  handleChangeStatsPeriod = (e) => {
    this.handleLoadStats(this.state.customer.guid, e.target.value);
  };

  render() {
    const { classes, customerId } = this.props;
    const { stats, tab, isLoadingStats, customer, statsLength } = this.state;
    const { productLicences, orders, trials } = customer;

    return (
      <React.Fragment>
        <Paper
          style={{
            padding: 5 * 3,
            marginBottom: 20,
          }}
        >
          <ReactPlaceholder
            type="rect"
            ready={!isLoadingStats}
            style={{ width: "100%", height: 400 }}
            color="#E0E0E0"
            showLoadingAnimation={true}
          >
            {stats.length > 0 && (
              <React.Fragment>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                  <FormControl className={classes.formControl}>
                    <Select
                      native
                      id="period"
                      name="period"
                      label="Period"
                      autoComplete="industry"
                      value={statsLength}
                      onChange={(e) => this.handleChangeStatsPeriod(e)}
                    >
                      <option value="30">last month</option>
                      <option value="90">last 3 months</option>
                      <option value="365">last 12 months</option>
                    </Select>
                  </FormControl>
                </Grid>

                <Chart
                  width={"100%"}
                  height={"400px"}
                  chartType="ComboChart"
                  loader={
                    <ReactPlaceholder
                      type="rect"
                      color="#E0E0E0"
                      style={{ width: "100%", height: 400 }}
                      showLoadingAnimation={true}
                    ></ReactPlaceholder>
                  }
                  data={stats}
                  options={{
                    title: "Licenses usage",
                    vAxis: { title: "Licences" },
                    hAxis: { title: "Day" },
                    seriesType: "bars",
                    series: { 3: { type: "line" } },
                    animation: { startup: true },
                    colors: ["#2b6f9f", "#173d58", "#8db2cc", "orange", "#E33E7F"],
                    isStacked: false,
                  }}
                  rootProps={{ "data-testid": "1" }}
                />
              </React.Fragment>
            )}
          </ReactPlaceholder>
        </Paper>

        <ProductAdoptions customerId={customerId} />

        <Paper square>
          <Tabs value={tab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
            <Tab
              label={
                <StyledBadge badgeContent={trials ? trials.length : 0} color="primary">
                  Trials
                </StyledBadge>
              }
            />
            <Tab
              label={
                <StyledBadge badgeContent={orders ? orders.length : 0} color="error">
                  Orders
                </StyledBadge>
              }
            />
            <Tab
              label={
                <StyledBadge badgeContent={productLicences ? productLicences.length : 0} color="secondary">
                  Licences
                </StyledBadge>
              }
            />
          </Tabs>
        </Paper>

        {tab === 0 && (
          <Paper style={{ padding: 6 * 3 }}>
            {trials && trials.map((item) => <TrialLicence key={item.id} item={item} {...this.props} />)}
          </Paper>
        )}

        {tab === 1 && (
          <Paper style={{ padding: 6 * 3 }}>
            {orders && orders.map((item) => <Order key={item.id} item={item} {...this.props} />)}
          </Paper>
        )}

        {tab === 2 && (
          <Paper style={{ padding: 6 * 3 }}>
            {productLicences && productLicences.map((item) => <Licence key={item.id} item={item} {...this.props} />)}
          </Paper>
        )}
      </React.Fragment>
    );
  }
}

CustomerDetailSidePanel.propTypes = {
  classes: PropTypes.object.isRequired,
  customerId: PropTypes.number.isRequired,
  customerDetail: PropTypes.object,
};

export default withStyles(styles)(CustomerDetailSidePanel);
