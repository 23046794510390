import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Spinner from "../Spinner/Spinner";
import Box from "@material-ui/core/Box";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const {
    isLoading = false,
    disableButton,
    disableSubmit,
    openTitle,
    dialogTitle,
    dialogContentText,
    children,
    submitTitle,
    onSubmit,
    onOpen,
    onClose,
    rerender,
    ...other
  } = props;

  const handleClickOpen = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    rerender();
  };

  const handleSubmit = () => {
    setOpen(false);
    onSubmit && onSubmit();
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen} disabled={disableButton}>
        {openTitle || "Open"}
      </Button>
      <Dialog open={open} onClose={onClose || handleClose} aria-labelledby="form-dialog-title" {...other}>
        {dialogTitle ? <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle> : null}

        {!isLoading ? (
          <DialogContent>
            {dialogContentText ? <DialogContentText>{dialogContentText}</DialogContentText> : null}
            {children || null}
          </DialogContent>
        ) : (
          <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Spinner size={30} />
          </Box>
        )}

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={isLoading || disableSubmit} onClick={handleSubmit} color="primary">
            {submitTitle || "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
