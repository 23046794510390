import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { dealUseCases } from "../../../api/deal";
import { userProfile } from "../../../api/general";
import { orderProducts } from "../../../api/order";
import fetchClient from "../../../services/axios";
import { Select } from "../../../ui/Input";
import CustomizedSnackbars from "../../CustomizedSnackbars";
import { checkDecimal2 } from "../../../services/utils";

const initItem = {
  date_closed: { value: moment().add(6, "months").toDate(), required: true, error: false },
  description: { value: "", required: true, error: false },
  end_user_price: { value: "", required: true, error: false },
  intended_use_case: { value: "", required: false, error: false },
  name: { value: "", required: false, error: false },
  product: { value: null, required: true, error: false },
  seats: { value: 1, required: true, error: false },
  status: { value: "", required: false, error: false },
  validity: { value: "", required: true, error: false },
};

const validities = ["1 year", "2 years", "3 years", "other"];

class DealNewProductForm extends React.Component {
  state = {
    isLoading: false,
    error: false,
    products: [],
    filteredProducts: [],
    basket: initItem,
    currency: "",
    useCases: [],
  };

  componentDidMount = () => {
    if (!this.props?.deal?.basket && !this.props?.deal?.basket?.product) {
      this.props.setBasket(initItem);
    }

    fetchClient()
      .get(process.env.REACT_APP_API_URL + userProfile)
      .then((response) => {
        this.setState({
          currency: response.data.user.currency,
          partnerCompany: response.data.user.partnerCompany,
        });

        this.props.setDealCurrency(response.data.user.currency);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchClient()
      .get(process.env.REACT_APP_API_URL + dealUseCases)
      .then((response) => {
        this.setState({
          useCases: response.data.useCases,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    fetchClient()
      .get(process.env.REACT_APP_API_URL + orderProducts)
      .then((response) => {
        const products = response.data?.products;
        const filteredProducts = this.props.user.companyDetail.has_hub
          ? products
          : products?.filter((product) => product.type !== "MONTHLY_BILLING");

        this.setState({
          products: filteredProducts,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  render() {
    const { deal, customer } = this.props;
    const { products, error, currency, useCases } = this.state;
    const basket = deal?.basket;

    const handleDeal = (val, key) => {
      const productName =
        key === "product"
          ? products.find((item) => val === item.id)?.name
          : products.find((item) => basket.product?.value === item.id)?.name;
      const seats = key === "seats" ? val : basket.seats.value;
      const validity = key === "validity" ? val : basket.validity.value;
      const company = deal.customer?.firm || customer?.firmName?.value;

      if (key === "end_user_price" && !checkDecimal2(val)) {
        return this.props.setBasket({
          ...basket,
          ...{
            [key]: { ...basket[key], value: val, error: "Max two decimal" },
            name: {
              ...basket.name,
              value:
                company +
                " " +
                productName +
                " " +
                seats +
                "x " +
                validity +
                " (" +
                this.props.user.companyDetail.company_name +
                "/" +
                (deal?.reseller?.company || customer?.reseller?.company || "-") +
                ")",
            },
          },
        });
      } else {
        this.props.setBasket({
          ...basket,
          ...{
            [key]: { ...basket[key], value: val, error: false },
            name: {
              ...basket.name,
              value:
                company +
                " " +
                productName +
                " " +
                seats +
                "x " +
                validity +
                " (" +
                this.props.user.companyDetail.company_name +
                "/" +
                (deal?.reseller?.company || customer?.reseller?.company || "-") +
                ")",
            },
          },
        });
      }
    };

    if (error) {
      return <CustomizedSnackbars message={error.message} variant="error" />;
    }

    return (
      <React.Fragment>
        <form style={{ marginBottom: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Select
                label="Validity"
                required
                fullWidth
                value={deal?.basket?.validity?.value}
                onChange={(e) => handleDeal(e.target.value, "validity")}
                options={validities.map((item) => ({
                  key: item,
                  label: item,
                }))}
                error={deal?.basket?.validity?.error}
                helperText={deal?.basket?.validity?.error}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                required
                id="seats"
                label="Users"
                value={deal?.basket?.seats?.value}
                name="seats"
                type="number"
                fullWidth
                inputProps={{ min: "1", step: "1" }}
                onChange={(e) => handleDeal(e.target.value, "seats")}
                error={deal?.basket?.seats?.error}
                helperText={deal?.basket?.seats?.error}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  disablePast
                  required
                  margin=""
                  label="Expected close date"
                  value={deal?.basket?.date_closed?.value}
                  initialFocusedDate={moment().add(6, "months").toDate()}
                  fullWidth
                  format="MM/dd/yyyy"
                  onChange={(val) => handleDeal(val, "date_closed")}
                  InputLabelProps={{ shrink: true }}
                  error={deal?.basket?.date_closed?.error}
                  helperText={deal?.basket?.date_closed?.error}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} md={4}>
              <Select
                required
                fullWidth
                label="Product"
                value={deal?.basket?.product?.value}
                onChange={(e) => handleDeal(e.target.value, "product")}
                options={products?.map((item) => ({
                  key: item.id,
                  label: item.name,
                }))}
                error={deal?.basket?.product?.error}
                helperText={deal?.basket?.product?.error}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <TextField
                required
                label="End User Price"
                value={deal?.basket?.end_user_price?.value}
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" style={{ marginLeft: 5 }}>
                      {currency}
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleDeal(e.target.value, "end_user_price")}
                error={deal?.basket?.end_user_price?.error}
                helperText={deal?.basket?.end_user_price?.error}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Select
                label="Use Cases"
                fullWidth
                value={deal?.basket?.intended_use_case?.value}
                onChange={(e) => handleDeal(e.target.value, "intended_use_case")}
                options={useCases?.map((item) => ({
                  key: item.key,
                  label: item.value,
                }))}
                error={deal?.basket?.intended_use_case?.error}
                helperText={deal?.basket?.intended_use_case?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Description"
                multiline
                rows="3"
                fullWidth
                onChange={(e) => handleDeal(e.target.value, "description")}
                value={deal?.basket?.description?.value}
                InputLabelProps={{ shrink: true }}
                error={deal?.basket?.description?.error}
                helperText={deal?.basket?.description?.error}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 15 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="caption" gutterBottom component="p">
                Generated deal name
              </Typography>
              <Typography variant="body1" gutterBottom component="p">
                {deal?.basket?.name?.value}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBasket: (basket) => dispatch({ type: "deal/SET_BASKET_DEAL", basket }),
    setDealCurrency: (currency) => dispatch({ type: "deal/SET_DEAL_CURRENCY", currency }),
  };
};

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  deal: state.deal,
  customer: state.customer,
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(DealNewProductForm);
