import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export default function Spinner({ fetching, size, ...other }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress size={size || 25} {...other} />
    </Box>
  );
}
