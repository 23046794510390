import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Typography, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { downloadOrderCertificate } from "../../api/order";
import { subscriptionHistoryDetail } from "../../api/subscription";
import Can, { isUserAllowed } from "../../services/acl";
import fetchClient, { postClient } from "../../services/axios";
import { formatCurrency, formatFullDate } from "../../services/utils";
import OrderEdit from "../Basket/Order/OrderEdit";
import CustomizedSnackbars from "../CustomizedSnackbars";
import { checkForMaintenance, isFutureDate } from "./utils";
import ChangeOrderInfo from "./ChangeOrderInfo";

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(0),
    padding: 0,
    fontSize: 10,
    height: 20,
  },
  margin: {
    margin: theme.spacing(2),
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: "20%",
    right: -8,
  },
}))(Badge);

class OrderDetail extends React.Component {
  state = {
    order: {},
    licence: {},
    isLoading: false,
    error: false,
    status: false,
    tab: 0,
    products: 0,
    isLoadingInvoice: false,
    isLoadingCert: false,
    validTo: null,
    title: "",
  };

  componentDidMount = () => {
    fetchClient()
      .get(
        process.env.REACT_APP_API_URL + subscriptionHistoryDetail + "/" + this.props.match.params.id
      )
      .then((response) => {
        let products = 0;
        this.setState({
          order: response.data.order,
          isLoading: false,
          status: response.status,
          products,
          invoices: response.data.order.invoicesDetail ? 1 : 0,
          validTo: response.data.order.valid_to,
          title: response.data.order.title,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  getInvoice = async (invoiceId, invoiceVs) => {
    this.setState({ isLoadingInvoice: invoiceId });
    let response = await fetchClient().get(
      process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_ORDER_INVOICE + "/" + invoiceId,
      {
        headers: {
          Accept: "application/pdf",
        },
        responseType: "blob",
      }
    );

    this.setState({ isLoadingInvoice: false });

    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = url;
    link.download = invoiceVs + ".pdf";
    link.hidden = true;
    link.click();
    link.remove();

    return false;
  };

  getCertificate = async (customerId) => {
    this.setState({
      isLoadingCert: true,
    });

    const response = await postClient(
      process.env.REACT_APP_API_URL + downloadOrderCertificate,
      { customerId: this.state.order.customerDetail.id },
      { Accept: "application/pdf" },
      { responseType: "blob" }
    );

    if (response) {
      try {
        const blob = new Blob([response], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        const customerName = this.state.order.customerDetail.firm || "";
        document.body.appendChild(link);
        link.href = url;
        link.download =
          "License_certificate_" +
          (customerName ? customerName.replace(" ", "") : "") +
          "_" +
          moment().format("YYYYMMDDHms") +
          ".pdf";
        link.hidden = true;
        link.click();
        link.remove();
      } catch (error) {
        console.error("Chyba při zpracování souboru:", error);
      } finally {
        this.setState({ isLoadingCert: false });
      }
    }
  };

  handleTabChange = (e, newValue) => {
    this.setState({ tab: newValue });
  };

  render() {
    const { classes, user } = this.props;
    const {
      error,
      order,
      products,
      tab,
      invoices,
      isLoadingInvoice,
      isLoadingCert,
      validTo,
      title,
    } = this.state;

    const { invoiceDetail, customerDetail, resellerDetail, subscriptions } = order;

    this.props.setCustomer(customerDetail);

    return (
      <div>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}
        <Grid container spacing={3}>
          <Grid item md={4}>
            <Card className={classes.card} style={{ marginBottom: "20px" }}>
              <CardContent>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h5" gutterBottom={true} component="h1">
                    Order #{order.id}
                  </Typography>
                </Box>

                <Typography color="primary" variant="caption">
                  Created:
                </Typography>
                <Typography color="textSecondary" gutterBottom={true}>
                  {formatFullDate(order.date_order)}
                </Typography>

                <Typography color="primary" variant="caption">
                  Customer:
                </Typography>
                <Typography color="textSecondary" gutterBottom={true}>
                  {customerDetail?.firm ? (
                    <Link to={`/customer/detail/${customerDetail?.id}`}>{customerDetail.firm}</Link>
                  ) : (
                    "..."
                  )}
                </Typography>

                <Typography color="primary" variant="caption">
                  Reseller:
                </Typography>
                <Typography color="textSecondary" gutterBottom={true}>
                  {resellerDetail && resellerDetail?.company_name ? (
                    <Link to={`/reseller/detail/${resellerDetail?.id}`}>
                      {resellerDetail.company_name}
                    </Link>
                  ) : (
                    "..."
                  )}
                </Typography>

                <Typography color="primary" variant="caption">
                  Status:
                </Typography>
                <Typography color="textSecondary" gutterBottom={true}>
                  {order.state}
                </Typography>

                <Can resource={["Orders_create", "Orders_invoices", "Orders_basket"]}>
                  <Typography color="primary" variant="caption">
                    End user price:
                  </Typography>
                  <Typography color="textSecondary" gutterBottom={true}>
                    {order?.for_invoice_price_final_end_user_origin &&
                      formatCurrency(order.for_invoice_price_final_end_user_origin, order.currency)}
                  </Typography>
                </Can>
                <Can resource="Orders_invoices">
                  <Typography color="primary" variant="caption">
                    Partner price:
                  </Typography>
                  <Typography color="textSecondary" gutterBottom={2}>
                    {order?.price_final_origin &&
                      formatCurrency(order.price_final_origin, order.currency)}
                  </Typography>
                </Can>

                {/* TODO - Wait for Patrik !!! */}
                {/* {order.hubspot_deal_id && (
                  <Typography color="textSecondary" gutterBottom={true}>
                    <Link to={`/deal-hubspot/detail/${order.hubspot_deal_id}`}>
                      View Associated Deal
                    </Link>
                  </Typography>
                )} */}

                <Box style={{ display: "flex", marginTop: 20 }}>
                  <Button
                    component={Link}
                    style={{ marginRight: 20 }}
                    to="/orders"
                    variant="contained"
                  >
                    Back
                  </Button>

                  <OrderEdit
                    isFutureDate={isFutureDate(validTo)}
                    checkForMaintenance={checkForMaintenance(title)}
                  />
                  {!isFutureDate(validTo) || !checkForMaintenance(title) ? (
                    <ChangeOrderInfo
                      isFutureDate={isFutureDate(validTo)}
                      checkForMaintenance={checkForMaintenance(title)}
                    />
                  ) : null}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* --- Tabs --- */}
          <Grid item md={8}>
            <Paper square>
              <Tabs
                value={tab}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  label={
                    <StyledBadge badgeContent={products} color="primary">
                      Products
                    </StyledBadge>
                  }
                />
                {isUserAllowed(user, "Orders_invoices") && (
                  <Tab
                    label={
                      <StyledBadge badgeContent={invoices} color="error">
                        Invoices
                      </StyledBadge>
                    }
                  />
                )}
              </Tabs>
            </Paper>

            {tab === 0 && (
              <Paper style={{ padding: 6 * 3 }}>
                <Card
                  key={order.id}
                  className={classes.card}
                  style={{
                    marginBottom: "15px",
                    border: "none",
                    boxShadow: "none",
                    borderRadius: 0,
                    borderBottom: "1px solid #eee",
                  }}
                >
                  <CardContent>
                    {subscriptions?.licences.map((l, idx) => {
                      const validFrom = formatFullDate(l.valid_from ?? order.valid_from);
                      const validTo = formatFullDate(l.valid_to ?? order.valid_to);

                      return (
                        <Fragment key={idx}>
                          <Typography key={l.id} variant="h6" component="h2" color={"primary"}>
                            {l.serial}
                          </Typography>

                          <Typography variant="body2" component="p">
                            {l.count} seats, {order.title}
                            <br />
                            {validFrom} - {validTo}
                          </Typography>
                        </Fragment>
                      );
                    })}
                    {!subscriptions?.licences.length && order?.title && (
                      <Typography variant="h6" component="h2" color="primary">
                        {order.title}
                      </Typography>
                    )}
                    {!subscriptions?.licences.length && order?.count && (
                      <Typography>Users: {order.count}</Typography>
                    )}
                    {!subscriptions?.licences.length && order?.count && (
                      <Typography>
                        {formatFullDate(order?.subscriptions?.valid_from)} -{" "}
                        {formatFullDate(order?.subscriptions?.valid_to)}
                      </Typography>
                    )}
                  </CardContent>
                  {subscriptions?.licences && subscriptions.licences.length > 0 && (
                    <CardActions>
                      <Button size="small" onClick={() => this.getCertificate(order.customer)}>
                        {isLoadingCert ? "Downloading..." : "Download certificate"}
                      </Button>
                    </CardActions>
                  )}

                  {order?.product_name &&
                    order.product_name.toLowerCase().indexOf("maintenance") !== -1 && (
                      <CardActions>
                        <Button size="small" onClick={() => this.getCertificate(order.customer)}>
                          {isLoadingCert === order.id ? "Downloading..." : "Download certificate"}
                        </Button>
                      </CardActions>
                    )}
                </Card>
              </Paper>
            )}

            {tab === 1 && (
              <Can resource="Orders_invoices">
                <Paper style={{ padding: 6 * 3 }}>
                  <Card
                    className={classes.card}
                    key={invoiceDetail?.id}
                    style={{
                      marginBottom: "15px",
                      border: "none",
                      boxShadow: "none",
                      borderRadius: 0,
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    <CardContent>
                      <Typography variant="body2" component="p">
                        {formatFullDate(invoiceDetail?.issued)}
                      </Typography>
                      <Typography variant="h6" component="h2" color={"error"}>
                        {invoiceDetail?.vs}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {formatCurrency(
                          invoiceDetail?.amount_foreign_curr,
                          invoiceDetail?.currency
                        )}
                        {" - "}
                        {invoiceDetail?.state}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {invoiceDetail && (
                        <Button
                          size="small"
                          onClick={() => this.getInvoice(invoiceDetail?.id, invoiceDetail?.vs)}
                        >
                          {isLoadingInvoice === invoiceDetail?.id
                            ? "Downloading invoice..."
                            : "Download invoice"}
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Paper>
              </Can>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

OrderDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCustomer: (customer) => dispatch({ type: "order/SET_CUSTOMER", customer }),
  };
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(withRouter(OrderDetail));
