import axios from "axios";
import { getLocalValue } from "./storage";

export const redirectLogin = () => {
  localStorage.clear();
  window.location.href = "/login";
};

const fetchClient = (apiToken = null) => {
  let token = apiToken ? apiToken : getLocalValue("apiToken");
  let client = axios.create();

  if (token) {
    client.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    redirectLogin();
  }

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        redirectLogin();
      }
      return Promise.reject(error);
    }
  );

  return client;
};

export default fetchClient;

export const postClient = async (url, data, _headers, other = {}) => {
  let token = getLocalValue("apiToken");

  let headers = {};

  if (token) {
    headers["Authorization"] = "Bearer " + token;
  } else {
    redirectLogin();
  }

  const client = axios.create({
    headers: { ...headers, ..._headers },
    ...other,
  });

  try {
    const response = await client.post(url, data);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      redirectLogin();
    }
    throw error;
  }
};
