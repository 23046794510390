import "date-fns";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import fetchClient from "../services/axios";
import { isResellerFormFilled } from "../services/reseller";
import NewResellerForm from "./NewResellerForm";
import { validateEmail } from "../services/validator";
import { validateWeb } from "../services/customer";

const styles = (theme) => ({
  root: {
    // width: '90%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

class NewResellerWrapperFrom extends React.Component {
  state = {
    newReseller: {
      company: "",
      name: "",
      surname: "",
      email: "",
      address: "",
      phone: "",
      fax: "",
      website: "",
      ico: "",
      dico: "",
      zip: "",
      note: "",
      id_country: 0,
      city: "",
      country_state: "",
      password: "",
    },
    countries: [],
    isLoading: false,
    error: false,
  };

  handleSubmit = (e) => {
    if (this.handleValidate()) {
      // reset generated licence
      this.setState({
        isLoading: true,
      });

      // call API
      fetchClient()
        .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_NEW_RESELLER, {
          reseller: this.state.newReseller,
        })
        .then((response) => {
          // assign generated and returned licence
          this.setState({
            isLoading: false,
          });

          // redirect to reseller detail
          this.props.history.push("/reseller/detail/" + response.data.resellerId);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            error: error.response ? error.response.data.errors.message : error.message,
          });
        });
    }
  };

  handleChangeNewReseller = (e) => {
    this.setState({
      newReseller: {
        ...this.state.newReseller,
        [e.target.name]: e.target.value,
      },
      error: false,
    });
  };

  handleValidate = () => {
    if (isResellerFormFilled(this.state.newReseller) === false) {
      this.setState({
        error:
          this.state.newReseller?.contactEmail &&
          !validateEmail(this.state.newReseller.contactEmail)
            ? "Email is in incorrect format"
            : this.state.newReseller?.website && !validateWeb(this.state.newReseller.website)
              ? "Website is in incorrect format"
              : "You must set all required resellers data.",
      });
      return false;
    } else {
      if (this.state.newReseller?.website && !validateWeb(this.state.newReseller.website)) {
        this.setState({ error: "Website is in incorrect format" });
        return false;
      } else {
        this.setState({ error: "" });
        return true;
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { error, isLoading } = this.state;

    return (
      <React.Fragment>
        {error ? (
          <CustomizedSnackbars
            message={JSON.stringify(error)}
            variant="error"
            open={error ? true : false}
          />
        ) : (
          ""
        )}
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.root} style={{ padding: "30px 30px 50px" }}>
              <Typography variant="h4" gutterBottom component="h3">
                Create new reseller
              </Typography>
              <NewResellerForm
                {...this.props}
                newReseller
                handleChangeNewReseller={this.handleChangeNewReseller}
              />
              <Button
                style={{ marginTop: "30px" }}
                disabled={error || isLoading}
                variant="contained"
                color="primary"
                onClick={(e) => this.handleSubmit(e)}
                className={classes.button}
              >
                {isLoading ? "Loading..." : "Create reseller"}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

NewResellerWrapperFrom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewResellerWrapperFrom);
