import { Tabs as MuiTabs } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import React from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';



class Tabs extends React.Component {
    state = {
        tab: 0,
    };

    setTabFromUrl() {
        const params = new URLSearchParams(this.props.location.search);
        const section = params.get('f');
        this.setTab(section);
    }

    setTab(section) {
        switch (section) {
            case "":
                this.setState({ tab: 0 });
                break;
            case "new":
                this.setState({ tab: 1 });
                break;
            case "renewals":
                this.setState({ tab: 2 });
                break;
            case "all":
                this.setState({ tab: 3 });
                break;
            case "overdue":
                this.setState({ tab: 4 });
                break;
            default:
                this.setState({ tab: 0 });
                break;
        }
    }

    componentDidMount() {
        this.setTabFromUrl();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setTabFromUrl();
        }
    }

    setSection = (section) => {
        const params = new URLSearchParams(this.props.location.search);
        if (section === "") {
            params.delete('f');
            this.props.history.replace(this.props.location.pathname);
        } else {
            params.set('f', section);
            this.props.history.replace(`${this.props.location.pathname}?${params.toString()}`);
        }
    }

    handleTabChange = (event, newValue) => {
        const sections = ["", "new", "renewals", "all", "overdue"];
        this.setSection(sections[newValue]);
        this.setState({ tab: newValue });
    };

    render() {
        const { tab } = this.state;

        return (
            <Paper square style={{ marginBottom: 15, borderRadius: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <MuiTabs
                    value={tab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="Open Deals" />
                    <Tab label="New Deals" />
                    <Tab label="Renewals" />
                    <Tab label="All Deals" />
                    <Tab label="Overdue" />
                </MuiTabs>
            </Paper>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const mapStateToProps = state => ({
    user: state.user,
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Tabs);
