import { checkDecimal2 } from "../../services/utils";
import {
    VALIDATE_DEAL_BASKET,
    SET_BASKET_DEAL,
    VALIDATE_DEAL_CUSTOMER,
    SET_CUSTOMER_DEAL,
    SET_RESELLER_DEAL,
    SET_DEAL_CURRENCY,
    CLEAN_DEAL,
    SET_DEALS
} from "../actionTypes";

//return - boolean | null
const validateDealCustomer = (customer) => {
    if (customer === "setNull") {
        return null
    }

    if (customer) {
        return true
    } else {
        return false
    }
}

const validate = (basket) => {
    return Object.entries(basket).reduce((acc, [key, item]) => {
        if (item?.error !== undefined) {
            if (!item.value && item.required) {
                item.error = "Required field";
            } else if (item.max || item.max === 0) {
                if (item.value && item.value > item.max) {
                    item.error = `Max value is ${item.max}.`;
                } else {
                    item.error = false;
                }
            } else if (key === "end_user_price") {
                if (!checkDecimal2(item.value)) {
                    item.error = `Max two decimal`;
                } else {
                    item.error = false;
                }
            } else {
                item.error = false;
            }
        }
        return { ...acc, [key]: item };

    }, { ...basket })
};

const trial = (state = null, action) => {
    switch (action.type) {
        case "deal/" + SET_DEALS: {
            return {
                ...state,
                deals: action.deals
            };
        }
        case "deal/" + CLEAN_DEAL: {
            return null;
        }
        case "deal/" + VALIDATE_DEAL_BASKET: {
            return {
                ...state,
                isBasketValid: validate(action.basket)
            };
        }
        case "deal/" + SET_DEAL_CURRENCY: {
            return {
                ...state,
                basket: { ...state.basket, currency: action.currency }
            };
        }
        case "deal/" + SET_BASKET_DEAL: {
            return {
                ...state,
                basket: action.basket
            };
        }
        case "deal/" + VALIDATE_DEAL_CUSTOMER: {
            return {
                ...state,
                isDealCustomerValid: validateDealCustomer(action.customer)
            };
        }
        case "deal/" + SET_CUSTOMER_DEAL: {
            return {
                ...state,
                customer: action.customer
            };
        }
        case "deal/" + SET_RESELLER_DEAL: {
            return {
                ...state,
                ...action.reseller
            };
        }
        default: {
            return state;
        }
    }
};

export default trial; 