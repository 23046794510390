import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MUIDataTable from "mui-datatables";
import React from "react";
import ReactPlaceholder from "react-placeholder";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import CustomizedSnackbars from "../CustomizedSnackbars.js";
import { columnsDefault, options } from "./utils";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Can from "../../services/acl";

// const activeStages = [
//     "Prospecting",
//     "Qualification",
//     "Needs Analysis",
//     "Value Proposition",
//     "Decision Making",
//     "Negotiation/Closing",
// ];

class Table extends React.Component {

    options = () => options();

    handleStoredColsOptions = (columnsDefault) => {
        if (options && options.columnSort) {
            columnsDefault.forEach((i) => {
                if (i.name === options.columnSort.column) {
                    if (options.columnSort.direction === "ascending") {
                        i.options.sortDirection = "asc";
                    }
                    if (options.columnSort.direction === "descending") {
                        i.options.sortDirection = "desc";
                    }
                }
            });
        }
        return columnsDefault;
    };

    render() {
        const { error, isLoading, success } = this.props.utils;
        const { deal, deals } = this.props;

        return (
            <>
              {error && (
                    <CustomizedSnackbars 
                    open={Boolean(error)}
                    message="Deal records cannot be loaded from database now. Please try refreshing this page in 5 minutes." 
                    variant="error" />
                )}
                <Can resource="HubspotDeals_create">
                    <Button
                        component={Link}
                        to="/deal-hubspot/create"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 5, marginBottom: 15}}
                        onClick={() => (this.props.cleanDeal(), this.props.cleanCustomer())}
                    >
                        Create new deal
                    </Button>
                </Can>

                <div>
                    <ReactPlaceholder
                        type="text"
                        ready={!isLoading}
                        rows={20}
                        showLoadingAnimation={true}
                    >
                        <MUIDataTable
                            title={
                                <Grid container direction="row" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <AttachMoneyIcon />
                                    </Grid>
                                    <Grid item>
                                        <Typography>Deals</Typography>
                                    </Grid>
                                </Grid>
                            }
                            data={deals}
                            options={this.options()}
                            columns={this.handleStoredColsOptions(columnsDefault({ currency: deal?.basket?.currency || ",-" }))}
                        />
                    </ReactPlaceholder>
                </div>
            </>)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cleanDeal: () => dispatch({ type: 'deal/CLEAN_DEAL' }),
        cleanCustomer: () => dispatch({ type: 'customer/CLEAN_CUSTOMER' }),
    }
}

const mapStateToProps = state => ({
    user: state.user,
    deal: state.deal,
    deals: state.deal?.deals,
    utils: state.utils
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Table);