import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "react-placeholder/lib/reactPlaceholder.css";
import CustomizedSnackbars from "./CustomizedSnackbars.js";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import fetchClient from "../services/axios";
import GreyText from "../ui/Text/GreyText.js";

const styles = (theme) => ({
  tableContainer: {
    height: 320,
  },
  link: {
    color: theme.palette.secondary.main,
  },
});

class LicenceDetail extends React.Component {
  state = {
    licence: {},
    isLoading: false,
    error: false,
    status: false,
  };

  componentDidMount = () => {
    fetchClient()
      .get(process.env.REACT_APP_API_URL + process.env.REACT_APP_USER_TRIAL_DETAIL + "/" + this.props.match.params.id)
      .then((response) => {
        this.setState({
          licence: response.data,
          isLoading: false,
          status: response.status,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  render() {
    const { classes } = this.props;
    const { error, licence } = this.state;
    const { customerDetail = {} } = licence;

    const getSerial = () =>
      licence?.licences?.length ? (
        licence.licences.map((item, idx) => (
          <GreyText key={idx} disabled={item.disabled} style={{ marginRight: 7 }} gutterBottom={true}>
            {item.serial}
          </GreyText>
        ))
      ) : (
        <>
          {" "}
          <a href="https://hub.safetica.com" target="_blank">
            Safetica HUB
          </a>{" "}
          <br />
        </>
      );

    return (
      <div>
        {error && <CustomizedSnackbars message={error.message} variant="error" />}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card className={classes.card} style={{ marginBottom: "20px" }}>
              <CardContent>
                <Typography color="primary" variant="caption">
                  Product:
                </Typography>
                <Typography gutterBottom={true}>
                  {licence.title || "- "} ({licence.count || "- "} seats)
                </Typography>

                <Typography color="primary" variant="caption">
                  Validity:
                </Typography>
                <Typography gutterBottom={true}>
                  {moment(licence.valid_from).format("DD.MM.YYYY")} to {moment(licence.valid_to).format("DD.MM.YYYY")}
                </Typography>

                <Typography color="primary" variant="caption">
                  Licence number:
                </Typography>

                {getSerial()}

                <Typography color="primary" variant="caption">
                  Created:
                </Typography>
                <Typography gutterBottom={true}>{moment(licence.date_creation).format("DD.MM.YYYY")}</Typography>

                <Typography color="primary" variant="caption">
                  Status:
                </Typography>
                <Typography gutterBottom={true}>{licence.state}</Typography>

                <Typography color="primary" variant="caption">
                  Company:
                </Typography>
                <Typography gutterBottom={true}>
                  <Link to={`/customer/detail/${customerDetail && customerDetail.id}`} color="primary">
                    {customerDetail && customerDetail?.firm}
                  </Link>
                  <br />
                  {licence && licence.guid}
                </Typography>

                <br />
                <br />

                <Button component={Link} className={classes.button} to="/licences" variant="contained">
                  Back
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

LicenceDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LicenceDetail);
