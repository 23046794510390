import {
	PublicClientApplication
} from '@azure/msal-browser';
import { MsalContext, MsalProvider } from "@azure/msal-react";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { Component } from "react";
import { msalConfig } from '../../authConfig.js';
import './App.css';
import Layout from './Layout';
import RedirectPage from "./RedirectPage";
import SignIn from "./SignIn";
import { initAuth } from './initAuth';

// export const getGlobalStyles = () => ({
// 	MuiCssBaseline: {
// 		styleOverrides: () => [scrollbarStyles],
// 	},
// });

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#005b94'
		},
		secondary: {
			main: '#E33E7F'
		}

	},
});

export const msal = new PublicClientApplication(msalConfig);
initAuth(msal)

class AppAuth extends React.Component {
	static contextType = MsalContext;
	state = {
		isLoading: true,
	};

	componentDidMount = () => {
		if (window.location.pathname === "/oauth-redirect") {
			this.setState({ isLoading: true })
		} else {
			this.setState({ isLoading: false })
		}
	}

	render() {
		// const { accounts, inProgress } = this.context;
		const isAuthenticated = this.context.accounts.length > 0;
		// const idToken = accounts[0].idTokenClaims;
		if (isAuthenticated) {
			return <Layout />
		} else {
			return this.state.isLoading ? <RedirectPage /> : <SignIn />
		}
	}
}

class App extends Component {
	render() {
		return (
			<MsalProvider instance={msal}>
				<MuiThemeProvider theme={theme}>
					<AppAuth />
				</MuiThemeProvider>
			</MsalProvider>
		);
	}
}

export default App;