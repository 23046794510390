import { List, ListItemText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="text" onClick={handleClickOpen}
                style={{ textTransform: "none", color: "#a2cbf9" }}
            >
                I need help with signing in
            </Button>
            <Dialog
                disableScrollLock={true}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Safetica signing in help"}</DialogTitle>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Safetica Portal users are authenticated by Microsoft since April 11, 2024.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        Follow the steps below for a seamless login process:
                    </Typography>

                    <List>
                        <ListItemText primary="1) Click to 'Log in with Microsoft' button." />
                        <ListItemText primary="2) You will be redirected to the Microsoft validation page. Here, enter the email address that you have registered in the Safetica Portal." />
                        <ListItemText primary="3) Microsoft will typically send a verification code to the provided email address." />
                        <ListItemText primary="4) You will be automatically logged in to the Safetica Portal after successful account verification." />
                    </List>
                    <Typography variant="body1">
                        If you encounter any errors during this process, please reach out to us at <b>operations.systems@safetica.com</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}