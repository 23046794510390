import { SET_PROFILES } from "../actionTypes";

const profiles = (state = null, action) => {
    switch (action.type) {
        case "profiles/" + SET_PROFILES: {
            return action.profiles;
        }
        default: {
            return state;
        }
    }
};

export default profiles;
