import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import classNames from "classnames";
import React from "react";
import { styles } from "./styles";
import SideBarItems from "./SidebarItems";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

class SideBar extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.forceUpdate();
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.forceUpdate();
    }
  }
  render() {
    const { classes, isDrawerOpened, handleDrawerClose } = this.props;
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !isDrawerOpened && classes.drawerPaperClose),
        }}
        open={isDrawerOpened}
        style={{ position: "fixed" }}
      >
        <img
          src="https://cdn.safetica.com/web/safetica/img/logo.png"
          alt="Portal"
          style={{ position: "absolute", left: 15, top: 15, width: 120 }}
        />
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <SideBarItems />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  state: state,
});

export default compose(withStyles(styles), connect(mapStateToProps), withRouter)(SideBar);
