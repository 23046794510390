// export const ADD_TODO = "ADD_TODO";
// export const TOGGLE_TODO = "TOGGLE_TODO";
// export const SET_FILTER = "SET_FILTER";

// SETTINGS
export const FORM_CHANGE_PASSWORD = "FORM_CHANGE_PASSWORD";
export const FORM_CHANGE_MASTER_EMAIL = "FORM_CHANGE_MASTER_EMAIL";
export const FEEDBACK_ERROR = "FEEDBACK_ERROR";
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
export const FEEDBACK_RESET = "FEEDBACK_RESET";
export const FEEDBACK_RESET_MASTER_EMAIL = "FEEDBACK_RESET_MASTER_EMAIL";
export const SET_USER = "SET_USER";
export const SET_PROFILES = "SET_PROFILES";

//Orders
export const SET_BASKET = "SET_BASKET";
export const SET_SHOW_ASSOCIATED_DEALS_MSG = "SET_SHOW_ASSOCIATED_DEALS_MSG";
export const SET_SHOW_ASSOCIATED_DEALS_SELECT = "SET_SHOW_ASSOCIATED_DEALS_SELECT";
export const SET_ORDER_DEAL_TRESHOLD = "SET_ORDER_DEAL_TRESHOLD";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_ASSOCIATED_DEAL = "SET_ASSOCIATED_DEAL";
export const SET_ASSOCIATED_DEALS = "SET_ASSOCIATED_DEALS";
export const SET_TOTAL_PRICE = "SET_TOTAL_PRICE";
export const SET_NOTE = "SET_NOTE";
export const VALIDATE_BASKET = "VALIDATE_BASKET";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_RESELLER = "SET_RESELLER";
export const SET_ORDER_SETTINGS = "SET_ORDER_SETTINGS";
export const VALIDATE_ORDER_CUSTOMER = "VALIDATE_ORDER_CUSTOMER";
export const SET_IS_NOTE_REQUIRED = "SET_IS_NOTE_REQUIRED";
export const CLEAN = "CLEAN";

//New Customer
export const VALIDATE_CUSTOMER = "VALIDATE_CUSTOMER";
export const SET_NEW_CUSTOMER = "SET_NEW_CUSTOMER";
export const CLEAN_CUSTOMER = "CLEAN_CUSTOMER";

//Trials
export const SET_TRIAL_TRESHOLD = "SET_TRIAL_TRESHOLD";
export const SET_TRIAL_SETTINGS = "SET_TRIAL_SETTINGS";
export const SET_NOTE_TRIAL = "SET_NOTE";
export const VALIDATE_TRIAL_BASKET = "VALIDATE_TRIAL_BASKET";
export const SET_BASKET_TRIAL = "SET_BASKET_TRIAL";
export const SET_CUSTOMER_TRIAL = "SET_CUSTOMER_TRIAL";
export const SET_RESELLER_TRIAL = "SET_RESELLER_TRIAL";
export const VALIDATE_TRIAL_CUSTOMER = "VALIDATE_TRIAL_CUSTOMER";
export const SET_GENERATED_TRIAL = "SET_GENERATED_TRIAL";
export const CLEAN_TRIAL = "CLEAN_TRIAL";

//Deals
export const SET_DEAL_CURRENCY = "SET_DEAL_CURRENCY";
export const VALIDATE_DEAL_BASKET = "VALIDATE_DEAL_BASKET";
export const SET_BASKET_DEAL = "SET_BASKET_DEAL";
export const VALIDATE_DEAL_CUSTOMER = "VALIDATE_DEAL_CUSTOMER";
export const SET_CUSTOMER_DEAL = "SET_CUSTOMER_DEAL";
export const SET_RESELLER_DEAL = "SET_RESELLER_DEAL";
export const CLEAN_DEAL = "CLEAN_DEAL";
export const SET_DEALS = "SET_DEALS";

//Utils
export const SET_UTILS = "SET_UTILS";
